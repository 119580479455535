import { useAuth0 } from '@auth0/auth0-react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Chip } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { AddNewButton } from '../components/add-new-button'
import QuickMenu from '../components/quick-menu'
import { fetchPlayers, selectAllPlayers } from '../players/players.slice'
import { getWindowDimensions } from '../utils/utils'
import { allRules, fetchRule } from './rule.slice'
import RuleSubmit from './rules.submit'
import './style.css'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))
const editRule = (selectedRule) => {
  return <RuleSubmit rule={selectedRule}></RuleSubmit>
}

export default function CustomizedAccordions() {
  const { width } = getWindowDimensions()
  const { isAuthenticated, user } = useAuth0()
  const [isAdmin, setIsAdmin] = React.useState(false)
  const dispatch = useDispatch()
  const rulesFetchStatus = useSelector((state) => {
    return state?.rules?.status
  })
  const rules = useSelector(allRules)

  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)

  const [edit, setEdit] = React.useState(false)
  const [selectedRule, setSelectedRule] = React.useState({})

  useEffect(() => {
    console.log('useEffect ....', rulesFetchStatus)
    if (rulesFetchStatus === 'idle') {
      dispatch(fetchRule())
    }
  }, [rulesFetchStatus, dispatch])

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
    if (playersStatus === 'succeeded' && isAuthenticated) {
      const findP = allPlayers.find((p) => user?.email === p.playerId)
      setIsAdmin(findP?.admin ? true : false)
    }
  }, [playersStatus, dispatch])

  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleEdit = (rule) => {
    setSelectedRule(rule)
    setEdit(true)
  }
  const isOwner = (rule) => {
    if (isAuthenticated) {
      if (isAdmin) {
        return true
      } else if (
        rule?.createdBy === user?.email ||
        rule?.updatedBy === user?.email
      ) {
        return true
      }
    }
    return false
  }
  const getLoader = (
    <section class="dots-container">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </section>
  )
  const showRules = () => {
    const activeRules = rules?.filter((r) => r.active)
    console.log('active rule: ', activeRules)
    return (
      <>
        <Box width={width}>
          <QuickMenu />
          {rulesFetchStatus === 'succeeded' && activeRules?.length > 1 ? (
            <>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  mt: 1,
                  mb: 1,
                  bgcolor: '#673174',
                  color: 'warning.contrastText',
                  borderRadius: 1,
                }}
              >
                League Rules
                {isAdmin && (
                  <AddNewButton title="Add new rule" href="/rules/submit" />
                )}
              </Typography>
            </>
          ) : (
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                mt: 1,
                mb: 1,
                bgcolor: '#149e72',
                color: 'warning.contrastText',
                borderRadius: 1,
              }}
            >
              {activeRules?.length === 1
                ? 'Tournament rules, will be added soon'
                : getLoader}
            </Typography>
          )}
          {rulesFetchStatus === 'succeeded' &&
            activeRules.map((rule, i) => {
              return (
                <div key={rule.ruleId}>
                  <Accordion
                    expanded={expanded === rule.ruleId}
                    onChange={handleChange(rule.ruleId)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id={rule.title}
                      square="true"
                    >
                      <>
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {i + 1}{' '}
                        </Typography>
                        {rule.ruleTitle}
                        {isOwner(rule) && (
                          <Chip
                            variant="outlined"
                            size="small"
                            sx={{ ml: 1 }}
                            color="error"
                            icon={<EditIcon />}
                            label={'Edit'}
                            onClick={() => handleEdit(rule)}
                          />
                        )}
                      </>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant="subtitle2"
                        align="justify"
                        sx={{ color: '#b012ba', wordWrap: 'break-word' }}
                      >
                        {parse(rule.ruleShortDesc)}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        align="justify"
                        sx={{ color: '#006dff', wordWrap: 'break-word' }}
                      >
                        {parse(rule.ruleFullDesc)}
                        {rule.refTitle && (
                          <a
                            style={{ display: 'table-cell' }}
                            href={rule.refLink}
                            target="_blank"
                          >
                            {rule.refTitle}
                          </a>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )
            })}
        </Box>
      </>
    )
  }
  return edit ? editRule(selectedRule) : showRules()
}
