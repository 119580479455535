import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import store from './app/store'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
const config = {
  domain:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_AUTH_DOMAIN_PROD
      : process.env.REACT_APP_AUTH_DOMAIN_DEV,

  clientId:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_AUTH_CLIENT_ID_PROD
      : process.env.REACT_APP_AUTH_CLIENT_ID_DEV,
  audience: process?.env?.REACT_APP_AUTH_AUDIENCE,
  redirectUri: window.location.origin,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
}
console.log(process.env.NODE_ENV, JSON.stringify(config))
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider {...config}>
        <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
