import { useAuth0 } from '@auth0/auth0-react'
import { Login } from '@mui/icons-material'
import axios from 'axios'
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl'
import {
  Alert,
  AlertTitle,
  Autocomplete,
  FormHelperText,
  IconButton,
  Link,
  Paper,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { makePostCall } from '../../api/client'
import MatchDaysSelection, {
  daysOfWeek,
} from '../../components/common/MatchDaysSelection'
import { fetchPlayers, selectAllPlayers } from '../../players/players.slice'
import { BasicModal } from '../../utils/common.element'

const defaultTheme = createTheme()

export default function RegistrationForChallengeMatches() {
  const { user, loginWithPopup } = useAuth0()
  const [loading, setLoading] = React.useState(true)
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const [players, setPlayers] = React.useState(null)
  const playersStatus = useSelector((state) => state.players.status)
  const [challengeMatches, setChallengeMatches] = useState([])
  const [isRegistered, setIsRegistered] = useState(false)
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [playerData, setPlayerData] = useState({
    playerPreference: 'onceAMonth',
    playingPreference: 'double',
    pairupPreference: 'okayWithDraw',
    matchDays: daysOfWeek.reduce(
      (acc, day) => ({ ...acc, [day.toLowerCase()]: false }),
      {},
    ),
    agreeToTerms: false,
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
  }, [playersStatus, dispatch])

  useEffect(() => {
    if (loading) return
    setPlayers(allPlayers)
  }, [loading])

  useEffect(() => {
    if (playersStatus === 'succeeded') {
      const findP = allPlayers.find((p) => user?.email === p.playerId)
      setIsAdmin(findP?.admin ? true : true)
      setLoading(false)
    }
  }, [playersStatus])

  useEffect(() => {
    if (!!challengeMatches) {
      const isPlayerInChallengeMatches = challengeMatches.some(
        (match) => match.playerId === playerData.playerId,
      )
      setIsRegistered(isPlayerInChallengeMatches)
    }
  }, [playerData.playerId])

  useEffect(async () => {
    // Fetch challenge matches data when the component mounts
    const response = await axios.get('http://localhost:3000/dev/v1/challenge')
    setChallengeMatches(response.data)
  }, [])

  const [submitButton, setSubmitButton] = React.useState({
    message: 'Submit Registration',
    severity: null,
  })
  const [openModel, setOpenModel] = React.useState(false)

  const handleFormSubmit = async (event) => {
    event.preventDefault()
    makePostCall(playerData, 'challenge', null, setSubmitButton, true)
    setOpenModel(true)
  }
  // Determine if the submit button should be disabled
  const isDayChecked = Object.values(playerData.matchDays).some(
    (isChecked) => isChecked,
  )

  const isSubmitDisabled =
    !playerData.agreeToTerms ||
    !isDayChecked ||
    !playerData.playerId ||
    isRegistered
  const comingSoon = (
    <Paper elevation={24}>
      <Alert severity="info">
        <AlertTitle>RRTL Spring 2024</AlertTitle>
        For challenge match details, stay connected with us! —{' '}
        <strong>Updates are on the way.</strong>
      </Alert>
    </Paper>
  )
  const challengeOptions = (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <FormatListNumberedRtlIcon />
      </Avatar>

      <Typography component="h6" variant="h5" sx={{ fontSize: '1.3rem' }}>
        Registration for Challenge Matches
      </Typography>
      {isAdmin && (
        <Button
          href="/challenge-matches/list"
          size="small"
          color="secondary"
          variant="contained"
          fullWidth
        >
          Click for Registered players
        </Button>
      )}
      <Box
        component="form"
        noValidate
        onSubmit={handleFormSubmit}
        sx={{ mt: 3 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {players?.length > 0 && (
              <>
                <Autocomplete
                  id="player-autocomplete"
                  options={players}
                  getOptionLabel={(player) => player.fullName}
                  // value={players[0]} // Set the selected player here
                  onChange={(event, newValue) => {
                    if (newValue) {
                      // Handle the selected player
                      setPlayerData({
                        ...playerData,
                        playerFullName: newValue.fullName,
                        playerId: newValue.id,
                      })
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="given-name"
                      name="fullName"
                      required
                      fullWidth
                      id="fullName"
                      label="Full Name"
                      autoFocus
                    />
                  )}
                />
                {isRegistered ? (
                  <Typography
                    variant="button"
                    display="block"
                    style={{ color: 'red' }}
                    gutterBottom
                  >
                    Player has already been registered.
                  </Typography>
                ) : (
                  <FormHelperText id="component-helper-text">
                    If name is not found, please register.
                    {user ? (
                      <Link href="/players/sign-up">
                        <IconButton
                          color="secondary"
                          aria-label="sign up"
                          sx={{ fontSize: '0.8rem' }}
                        >
                          {' '}
                          Register
                          <Login />
                        </IconButton>
                      </Link>
                    ) : (
                      <IconButton
                        color="secondary"
                        onClick={loginWithPopup}
                        aria-label="sign up"
                        sx={{ fontSize: '0.8rem' }}
                      >
                        {' '}
                        Register
                        <Login />
                      </IconButton>
                    )}
                  </FormHelperText>
                )}
              </>
            )}
          </Grid>
          {/* <Grid item xs={12}>
                <Button
                  variant="contained"
                  href="#outlined-buttons"
                  color="warning"
                  fullWidth
                  onClick={loginWithPopup}
                  sx={{
                    backgroundColor: '#a76c08',
                    paddingLeft: '6px',
                    paddingRight: '6px',
                  }}
                  endIcon={<Login />}
                >
                  If name is not found, please register.
                  <strong>Register</strong>
                </Button>
              </Grid> */}
          <Grid item xs={12}>
            <MatchDaysSelection
              selections={playerData}
              setSelections={setPlayerData}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              sx={{ textAlign: 'left' }}
              control={
                <Checkbox
                  value="agreeToTerms"
                  color="primary"
                  onChange={(e) =>
                    setPlayerData({
                      ...playerData,
                      agreeToTerms: e.target.checked,
                    })
                  }
                />
              }
              label="I agree to terms and conditions and will honor league committee instructions and decisions."
            />
          </Grid>
        </Grid>
        {BasicModal(
          openModel,
          {
            severity: submitButton.severity,
            message: submitButton.message,
          },
          'Thank you for registering for the challenge matches!',
          setOpenModel,
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={isSubmitDisabled}
        >
          {isRegistered
            ? 'The player is already registered.'
            : 'Enroll for challenge matches'}
        </Button>
      </Box>
    </Box>
  )
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        {challengeOptions}
      </Container>
    </ThemeProvider>
  )
}
