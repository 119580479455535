import { get, isEmpty } from 'lodash'
import moment from 'moment'

export const getNameInitials = (name) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
  let initials = [...name.matchAll(rgx)] || []
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase()
  return initials
}

export const getNameInitialsFromStr = (playersNameStr) => {
  if (!playersNameStr) return
  const splitNames = playersNameStr.split(' - ')
  return `${getNameInitials(splitNames[0])}-${getNameInitials(splitNames[1])}`
}

export const getPlayerFirstNames = (playersNameStr) => {
  if (isEmpty(playersNameStr)) return
  const playerNames = playersNameStr.replace(/\s\s+/g, ' ')
  const players = playerNames.split('-')
  const firstPlayerStrArray = players[0].trim().split(' ')
  const secondPlayerStrArray = players[1]?.trim().split(' ') || []
  const fPlayerIndex = firstPlayerStrArray.length > 2 ? 1 : 0
  const sPlayerIndex = secondPlayerStrArray.length > 2 ? 1 : 0
  let firstPlayerName = firstPlayerStrArray[fPlayerIndex]
  if (firstPlayerName.toUpperCase() === 'MUHAMMAD' && fPlayerIndex === 0) {
    firstPlayerName = firstPlayerStrArray[1]
  }
  let secondPlayerName = secondPlayerStrArray[sPlayerIndex]
  if (secondPlayerName?.toUpperCase() === 'MUHAMMAD' && sPlayerIndex === 0) {
    secondPlayerName = secondPlayerStrArray[1]
  }
  return `${firstPlayerName}/${secondPlayerName}`
}

export const dayAndDate = (_day) => {
  const today = _day || new Date()
  const locale = 'en'
  const day = today.toLocaleDateString(locale, { weekday: 'long' })
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: 'long',
  })}`
  return date
}

export const getDispDateTime = (date) => {
  return moment(date || new Date()).format('MMM Do (ddd) hh:mm A')
}
export const getDispDate = (date) => {
  return moment(date || new Date()).format('MMM Do')
}
export const getDispDateYear2 = (date) => {
  return moment(date || new Date()).format('M-DD-YYYY')
}
export const getDispDateYear = (date) => {
  return moment(date || new Date()).format('DD/mm/YYYY')
}
export const getMMDDYY = (timestamp) => {
  const date = new Date(timestamp) // create a new date object
  // extract the month, day and year from the date object
  const month = date.getMonth() + 1 // add 1 because months are 0-indexed
  const day = date.getDate()
  const year = date.getFullYear().toString() // extract the last two digits of the year
  // format the date string
  const formattedDate = `${month}/${day}/${year}`
  return formattedDate
}

export const getPlayersId = (allPlayers, selectedPlayers) => {
  return allPlayers
    .filter((player) => {
      return (
        player.fullName === selectedPlayers[0] ||
        player.fullName === selectedPlayers[1]
      )
    })
    .map((p) => p.playerId)
}
const PROD = 'https://prod.roundrocktennis.com/v1/'
const LOCAL = 'http://localhost:3000/dev/v1/'
export const getBaseUrl = () => {
  let API_ENDPOINT = 'http://localhost:3000/dev/v1/'
  if (process.env.NODE_ENV === 'production') {
    API_ENDPOINT = PROD
  } else {
    API_ENDPOINT = LOCAL
  }
  console.log('Base URl, API_ENDPOINT: ', API_ENDPOINT)
  return API_ENDPOINT
}

export const getDisabledFlag = (resultDetails, fieldName, teamId) => {
  const opponentTeam = fieldName === 'guest' ? 'host' : 0
  const opponentTeamId = get(resultDetails, `${opponentTeam}Team.teamId`)
  if (fieldName === 'guest' || fieldName === 'host') {
    return teamId === opponentTeamId ? true : false
  }
  if (fieldName === 'winning') {
    return teamId === get(resultDetails, 'hostTeam.teamId') ||
      teamId === get(resultDetails, 'guestTeam.teamId')
      ? false
      : true
  }
  return false
}

export const MATCH_STATUSES = [
  { id: 10, label: 'completed', title: 'Completed' },
  { id: 20, label: 'pending', title: 'Pending' },
  { id: 30, label: 'draw', title: 'Draw' },
  { id: 40, label: 'postponed', title: 'Postponed' },
  { id: 50, label: 'forfeited', title: 'Forfeited' },
  { id: 60, label: 'cancelled', title: 'Cancelled' },
  { id: 70, label: 'in-progress', title: 'In Progress' },
  { id: 80, label: 'scheduled', title: 'Scheduled' },
  { id: 90, label: 'rain-out', title: 'Rained Out' },
]

export const mapNumToWord = [
  'None',
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'Ten',
]

export const isMatchPlayed = (resultType) =>
  resultType === 'completed' ||
  resultType === 'draw' ||
  resultType === 'in-progress'
    ? true
    : false

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const getCamelCaseString = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getFullName = (firstName, lastName) => {
  return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
    lastName,
  )}`
}

export const calculatePoints = (player) => {
  const finalWinnerPoints = (player?.finalWinnerCount || 0) * 50
  const runnerUpPoints = (player?.runnerUpCount || 0) * 25

  // total matches points
  const totalMatchesPlayed = player?.totalMatchesPlayed || 0
  let totalMatchesPlayedPoints
  if (totalMatchesPlayed >= 0 && totalMatchesPlayed <= 25) {
    totalMatchesPlayedPoints = totalMatchesPlayed * 5
  } else if (totalMatchesPlayed >= 26 && totalMatchesPlayed <= 50) {
    totalMatchesPlayedPoints = totalMatchesPlayed * 3
  } else if (totalMatchesPlayed >= 51 && totalMatchesPlayed <= 75) {
    totalMatchesPlayedPoints = totalMatchesPlayed * 2
  } else {
    totalMatchesPlayedPoints = totalMatchesPlayed
  }

  const matchesWonPoints = (player.matchesWon || 0) * 5
  const matchesLostPoints = (player.matchesLost || 0) * 2

  const matchGamesWonPoints = (player?.netGamesWon || 0) * 2
  const matchGamesLostPoints = (player?.netGamesLost || 0) * 0.5
  let playerCreditPoints = 0
  if (player.fullName === 'Haroon Barlas') {
    playerCreditPoints = 1500
  }
  let percentageWonPoints = 0
  if (!(matchesWonPoints === 0 && matchesLostPoints === 0)) {
    percentageWonPoints =
      (100 * matchesWonPoints) / (matchesWonPoints + matchesLostPoints)
  }

  const totalPoints =
    playerCreditPoints +
    finalWinnerPoints +
    runnerUpPoints +
    totalMatchesPlayedPoints +
    matchesWonPoints +
    percentageWonPoints +
    matchGamesWonPoints -
    matchesLostPoints -
    matchGamesLostPoints

  return {
    totalPoints,
    finalWinnerPoints,
    runnerUpPoints,
    totalMatchesPlayedPoints,
    matchesWonPoints,
    percentageWonPoints,
    matchGamesWonPoints,
    matchesLostPoints,
    matchGamesLostPoints,
  }
}

export const calculateRatings = (points) => {
  // console.log('POINTS: ', points)
  switch (true) {
    case points > 6000:
      return 6
    case points > 4000:
      return 5.5
    case points > 2500:
      return 5
    case points > 2000:
      return 4.75
    case points > 1500:
      return 4.5
    case points > 1300:
      return 4.0
    case points > 1200:
      return 3.75
    case points > 1000:
      return 3.5
    case points > 500:
      return 3.0
    case points > 280:
      return 2.75
    case points > 210:
      return 2.5
    case points > 150:
      return 2.25
    case points > 110:
      return 2.0
    case points > 70:
      return 1.75
    case points > 40:
      return 1.5
    case points > 20:
      return 1.25
    case points > 10:
      return 1
    default:
      return 0
  }
}

export const calculateSum = (items, prop) => {
  return items.reduce((a, b) => {
    return a + b[prop]
  }, 0)
}

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

export function numberToOrdinal(number) {
  if (typeof number !== 'number') {
    throw new Error('Input should be a number.')
  }

  if (number % 100 >= 11 && number % 100 <= 13) {
    return number + 'th'
  }

  switch (number % 10) {
    case 1:
      return number + 'st'
    case 2:
      return number + 'nd'
    case 3:
      return number + 'rd'
    default:
      return number + 'th'
  }
}

export function getCurrentMonthDates() {
  const currentDate = new Date()
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  )
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  )

  const formatDate = (date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0') // Add leading zero if needed
    return `${month}-${day}-${year}`
  }

  return {
    start: formatDate(firstDayOfMonth),
    end: formatDate(lastDayOfMonth),
  }
}
export function formatCleanName(name) {
  if (!name) {
    return null
  } else {
    return name
      .replace(/\s+/g, ' ') // Replace multiple white spaces with a single white space
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-zA-Z\s]/g, '') // Remove non-alphabetic characters
      .split(' ') // Split by white space
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' ') // Join the words back together with space
      .trim() // Remove white space at the start and end
  }
}
