// import * as React from "react";
import React, { useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getBaseUrl } from '../utils/utils'
import FormHelperText from '@mui/material/FormHelperText'

import { selectAllPlayers, fetchPlayers } from '../players/players.slice'
import { allTeams, fetchTeams } from './teams.slice'

import { Spinner } from '../components/Spinner'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import GroupIcon from '@mui/icons-material/Group';
import { getNameInitials } from '../utils/utils.js'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useAuth0 } from '@auth0/auth0-react'
import LinearProgress from '@mui/material/LinearProgress'
import Login from '@mui/icons-material/Login'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Copyright, alertMessage } from '../utils/common.element'
import { Fab, IconButton } from '@mui/material'
import { MATCHES_TYPES, TOURNAMENTS } from '../utils/constants'

const theme = createTheme()

export default function SignUpTeam({ firstPlayer, secondPlayer }) {
  const {
    isAuthenticated,
    user,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0()
  const [token, setToken] = React.useState(null)
  const navigate = useNavigate()
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)
  const error = useSelector((state) => state.players.error)

  // players selection
  const [defValP1, setDefValP1] = React.useState(firstPlayer)
  const [defValP2, setDefValP2] = React.useState({})
  const [valCaptain, setValCaptain] = React.useState({})
  const [selectedPlayers, setSelectedPlayers] = React.useState([])
  const [captain, setCaptain] = React.useState(selectedPlayers[0])
  const [teamName, setTeamName] = React.useState('')
  const [isTeamExist, setIsTeamExist] = React.useState(false)
  const [leaguesSelection, setLeaguesSelection] = React.useState(TOURNAMENTS)
  const [submitButton, setSubmitButton] = React.useState({
    message: 'Submit your team',
    severity: null,
  })
  const [selectedPlayersInitials, setSelectedPlayersInitials] = React.useState(
    [],
  )
  const dispatch = useDispatch()
  const teamsFetchStatus = useSelector((state) => state?.teams?.status)
  const teams = useSelector(allTeams)

  const handleTeamName = (event) => {
    const teamName = event?.target?.value
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
    setTeamName(teamName)
  }

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
  }, [playersStatus, dispatch])

  useEffect(() => {
    if (teamsFetchStatus === 'idle') {
      dispatch(fetchTeams())
    }
  }, [teamsFetchStatus, dispatch])

  useEffect(() => {
    if (firstPlayer) {
      setDefValP1(firstPlayer)
      setValCaptain(firstPlayer)
      handleSelection('', firstPlayer.playerName, 'firstPlayer')
    }
  }, [firstPlayer])

  useEffect(() => {
    if (secondPlayer) {
      setDefValP2(secondPlayer)
      handleSelection('', secondPlayer.playerName, 'secondPlayer')
    }
  }, [secondPlayer])

  useEffect(async () => {
    setToken(await getAccessTokenSilently())
  }, [isAuthenticated])

  useEffect(async () => {
    if (selectedPlayers.length > 1) {
      const teamsWithPlayer = teams.find((team) => {
        return selectedPlayers.every((player) =>
          team.teamPlayersFullNames.includes(player),
        )
      })
      if (teamsWithPlayer) {
        setIsTeamExist(true)
        setTeamName(teamsWithPlayer.teamName)
        setCaptain(teamName.teamCaptainName)
      } else {
        setIsTeamExist(false)
        setTeamName('')
      }
    }
  }, [selectedPlayers])

  const handleChangeVale = (value, playerType) => {
    if (playerType === 'firstPlayer') {
      setDefValP1(value)
    } else if (playerType === 'secondPlayer') {
      setDefValP2(value)
    } else if (playerType === 'captain') {
      setValCaptain(value)
    }
  }
  const handleSelection = (e, value, playerType) => {
    if (playerType === 'firstPlayer') {
      selectedPlayers[0] = value
      setSelectedPlayers([...selectedPlayers])
    } else if (playerType === 'secondPlayer') {
      selectedPlayers[1] = value
      setSelectedPlayers([...selectedPlayers])
    } else if (playerType === 'captain') {
      setCaptain(value)
      setValCaptain(value)
      // set initials
      if (selectedPlayers.length === 2) {
        const initials = `${getNameInitials(
          selectedPlayers[0],
        )}-${getNameInitials(selectedPlayers[1])}`
        setSelectedPlayersInitials(initials)
      }
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const teamPlayers = allPlayers.filter((p) =>
      selectedPlayers.includes(p.fullName),
    )
    const teamCaptain = allPlayers.find((p) => p.fullName === captain)
    const body = {
      teamPlayers,
      leagues: leaguesSelection,
      teamCaptainId: teamCaptain?.playerId,
      teamCaptainName: teamCaptain?.fullName.trim(),
      teamPlayersFullNames: [
        teamPlayers[0]?.fullName.trim(),
        teamPlayers[1]?.fullName.trim(),
      ],
      teamPlayersIds: [teamPlayers[0]?.playerId, teamPlayers[1]?.playerId],
      teamName: teamName || selectedPlayersInitials,
      createdBy: user?.email,
      createdAt: new Date().toISOString(),
    }
    const baseUrl = getBaseUrl()

    fetch(`${baseUrl}teams`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('Response from backend: ', response)
        if (response['teamCaptainId']) {
          setSubmitButton({
            message: 'Team is successfully registered.',
            severity: 'success',
          })
        }
        if (response === 409) {
          setSubmitButton({
            message: 'Team name is already in used, use different name',
            severity: 'error',
          })
        }
      })
      .catch((error) => {
        console.error('Registration Error:', error)
        setSubmitButton({
          message: 'oops ... something went wrong, please try later.',
          severity: 'error',
        })
      })
  }

  const playerInputElement = (label, inputType) => {
    const labelTite = !allPlayers?.length > 0 ? 'No player is available' : label
    const defaultValue =
      inputType === 'firstPlayer'
        ? defValP1
        : inputType === 'secondPlayer'
        ? defValP2
        : valCaptain
    const finalDefaultValue = defaultValue ?? {}
    return (
      <Grid item xs={12}>
        {
          <Autocomplete
            fullWidth={true}
            size="large"
            disabled={!allPlayers?.length > 0}
            options={allPlayers}
            getOptionLabel={(option) => option?.fullName || ''}
            // value={finalDefaultValue}
            onChange={(e, value) => handleChangeVale(value, inputType)}
            onInputChange={(e, value) => handleSelection(e, value, inputType)}
            renderInput={(params) => (
              <TextField {...params} label={labelTite} />
            )}
            getOptionDisabled={(option) => {
              if (inputType === 'firstPlayer') {
                return option.fullName === selectedPlayers[1]
              } else if (inputType === 'secondPlayer') {
                return option.fullName === selectedPlayers[0]
              } else if (inputType === 'captain') {
                return (
                  !(option.fullName === selectedPlayers[0]) &&
                  !(option.fullName === selectedPlayers[1])
                )
              }
              return false
            }}
          />
        }
        {/* <Autocomplete
          required
          disabled={!allPlayers?.length > 0}
          disablePortal
          // blurOnSelect={`${label}`}
          id={`${label}Id`}
          fullWidth={true}
          size="large"
          onInputChange={(e, value) => handleSelection(e, value, inputType)}
          options={allPlayers}
          getOptionLabel={(option) => option?.fullName}
          getOptionDisabled={(option) => {
            // console.log({ option });
            if (inputType === 'firstPlayer') {
              return option.fullName === selectedPlayers[1]
            } else if (inputType === 'secondPlayer') {
              return option.fullName === selectedPlayers[0]
            } else if (inputType === 'captain') {
              return !(
                (option.fullName === selectedPlayers[0]) |
                (option.fullName === selectedPlayers[1])
              )
            }
            return false
          }}
          renderInput={(params) => <TextField {...params} label={labelTite} />}
        /> */}
      </Grid>
    )
  }
  const leaguesSelectionInputElement = () => {
    const activeLeauges = MATCHES_TYPES.filter((t) => t.active === true).map(
      (activeType) => activeType.value,
    )
    return (
      <>
        <Autocomplete
          fullWidth
          multiple
          id="multiple-limit-tags"
          options={activeLeauges}
          getOptionLabel={(option) => option || ''}
          defaultValue={[activeLeauges[0]]}
          onChange={(e, v) => setLeaguesSelection(v)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Interested in joining"
              name="tournamentName"
              placeholder="Tournament Name"
            />
          )}
          // sx={{ width: "500px" }}
        />
        <FormHelperText id="matches-options-helper-text">
          Please select all the options you wish to join.
        </FormHelperText>
      </>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      {playersStatus === 'loading' && (
        <>
          <Spinner text="Loading players data for teams..." />
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </>
      )}
      {playersStatus === 'error' && (
        <Spinner text={`something went wrong ...${error}`} />
      )}
      {playersStatus === 'succeeded' && (
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <GroupIcon />
            </Avatar>
            <>
              {isAuthenticated && (
                <Typography component="h1" variant="h5">
                  Register Team
                </Typography>
              )}
              {!isAuthenticated && (
                <>
                  <Typography component="h1" variant="h5">
                    login to register your team
                  </Typography>
                  <Fab
                    variant="extended"
                    onClick={loginWithPopup}
                    color="success"
                  >
                    <Login sx={{ mr: 1 }} />
                    Log in
                  </Fab>
                </>
              )}
            </>
          </Box>
          {isAuthenticated && allPlayers && (
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                {playerInputElement('First Player', 'firstPlayer')}

                <Grid item xs={12}>
                  {playerInputElement('Second Player', 'secondPlayer')}
                  <FormHelperText id="component-helper-text">
                    If player name is not found, please
                    <IconButton
                      color="secondary"
                      href="/players/sign-up"
                      aria-label="sign up"
                      sx={{ fontSize: '0.8rem' }}
                    >
                      {' '}
                      Register
                      <Login  href="/players/sign-up" />
                    </IconButton>
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  {playerInputElement('Team Captain', 'captain')}
                  <FormHelperText id="captain-helper-text">
                    Only the captain will be responsible for decision-making.
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      {submitButton.severity === 'success'
                        ? selectedPlayersInitials
                        : 'Team Name'}
                    </InputLabel>
                    <OutlinedInput
                      error={!teamName && captain ? true : false}
                      id={teamName}
                      color={teamName.length < 4 ? 'secondary' : 'success'}
                      label="Team Name"
                      disabled={!captain || isTeamExist}
                      name={teamName}
                      value={teamName}
                      onChange={handleTeamName}
                    />
                    {!!selectedPlayersInitials.length > 0 &&
                      submitButton.severity !== 'success' && (
                        <FormHelperText id="component-helper-text">
                          if not provided, Default name will be used i.e..{' '}
                          {selectedPlayersInitials}
                        </FormHelperText>
                      )}
                  </FormControl>
                  <FormHelperText id="team-name-helper-text">
                    Please avoid lengthy, offensive, or racist team names. and
                    do not use special characters in the team name. Use existing
                    team names if available.
                  </FormHelperText>
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="tournamentName"
                    label="Tournament Name"
                    type="text"
                    value={tournamentName}
                    id="tournamentName"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  {leaguesSelectionInputElement()}
                </Grid>
              </Grid>
              {submitButton.severity &&
                alertMessage(
                  submitButton.severity,
                  submitButton.message,
                  navigate,
                )}
              {submitButton.severity !== 'success' && (
                <Button
                  onClick={handleSubmit}
                  fullWidth
                  disabled={
                    selectedPlayers.length === 0 ||
                    !captain ||
                    submitButton.severity === 'success'
                  }
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isTeamExist
                    ? 'Existing Team!! Can only be Edited'
                    : 'Submit your team'}
                  <GroupWorkIcon
                    mr="3"
                    color={
                      selectedPlayers.length === 0 ||
                      !captain ||
                      submitButton.severity === 'success'
                        ? 'default'
                        : 'warning'
                    }
                  />
                </Button>
              )}
            </Box>
          )}
          <Copyright sx={{ mt: 5 }} />
        </Container>
      )}
    </ThemeProvider>
  )
}
