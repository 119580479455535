// @mui
import { Tooltip } from '@material-ui/core'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import { Box, Chip, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Scrollbar from '../components/common/Scrollbar'
import championsList from './champions.json'
import VerticalDividerText from './champs'
import { fetchPlayers, selectAllPlayers } from './players.slice'
// ----------------------------------------------------------------------

AppTopRelated.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array,
}

export default function AppTopRelated({
  title = 'best players',
  subheader,
  list,
  ...other
}) {
  const [playerRankingData, setPlayerRankingData] = useState(null)
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)
  const dispatch = useDispatch()

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
    if (playersStatus === 'succeeded') {
      const response = championsList
      setPlayerRankingData(response)
    }
  }, [playersStatus, dispatch])

  const rankingView = (mappedPlayersInfo) => (
    <>
      {/* <Box {...other} sx={{ maxHeight: '251px' }}> */}
      <Typography variant="subtitle2" component="h2">
        <Tooltip
          title="Matches since summer 2022"
          placement="top-end"
          followCursor
          arrow
        >
          <Chip
            icon={<EmojiEventsIcon />}
            label="Round Rock Tennis Leagues - Championships!"
            color="primary"
            elevation={24}
            variant="outlined"
            sx={{
              border: 1,
              fontWeight: 'bolder',
              borderLeft: 0,
              borderRight: 0,
              borderRight: 0,
              borderColor: 'blue',
            }}
          />
        </Tooltip>
      </Typography>

      <Stack
        spacing={1}
        style={{
          overflowY: 'auto',
          maxHeight: '250px',
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          paddingTop: '4px',
        }}
      >
        {mappedPlayersInfo.map((player, i) => (
          <PlayersRatings key={player.id} player={player} position={i + 1} />
        ))}
      </Stack>

      {/* </Box> */}
    </>
  )

  return playerRankingData ? rankingView(playerRankingData) : ''
}

function PlayersRatings({ player, position }) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          sx={{
            flexShrink: 0,
            display: 'flex',
            borderRadius: 1.5,
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.neutral',
          }}
        ></Box>

        <Box
          sx={{
            flexGrow: 1,
            alignItems: 'left',
            textAlign: 'left',
          }}
          onClick={handleOpen}
        >
          {/* <Typography variant="caption" sx={{ ml: 1 }}>
            <strong style={{ color: 'black' }}>{championshipName}</strong>
          </Typography> */}
          {player && (
            <Stack
              direction="column"
              alignItems="left"
              sx={{ mt: 0.1, color: 'text.secondary' }}
            >
              <VerticalDividerText champs={player}></VerticalDividerText>
            </Stack>
          )}
        </Box>
      </Stack>
    </>
  )
}
