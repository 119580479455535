import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { client } from '../../api/client'
import { getBaseUrl } from '../../utils/utils'

const initialState = {
  resultData: [],
  status: 'idle',
  error: null,
}

const baseUrl = getBaseUrl()

const resultSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    async addResult(state, action) {
      console.log('state: ', state)
      console.log('action payload: ', action.payload)
      const response = await client.post(`${baseUrl}results`, action.payload)
      console.log('Response from backend: ', response.data)
      return response.data
    },
  },
  extraReducers(builder) {
    if (!fetchResult) return
    builder
      .addCase(fetchResult.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchResult.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.resultData = state.resultData?.concat(action.payload)
      })
      .addCase(fetchResult.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const fetchResult = createAsyncThunk('results/fetchResult', async () => {
  const response = await client.get(`${baseUrl}results`)
  return response.data
})

export default resultSlice.reducer

export const { addResult } = resultSlice.actions

export const allResults = (state) => state.results?.resultData
