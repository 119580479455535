import CloseIcon from '@mui/icons-material/Close'
import { Alert } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import * as React from 'react'

export default function SimpleSnackbar(props) {
	const [open, setOpen] = React.useState(false)

	React.useEffect(() => {
		setOpen(true)
	}, [props])

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpen(false)
	}

	const action = (
		<React.Fragment>
			{/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	)

	return (
		<div>
			{/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message={props.msg} action={action}>
				<Alert onClose={handleClose} severity={props.variant} sx={{ width: '100%' }}>
					{props.msg}
				</Alert>
			</Snackbar>
		</div>
	)
}
