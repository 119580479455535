import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Skeleton } from '@mui/material'
import { client } from '../api/client'
import { allResults, fetchResult } from '../matches/results/result.slice'
import PlayerCard from '../profiles/PlayerCard'
import { getResultsByPlayers } from '../utils/players'
import { calculatePoints, calculateRatings, getBaseUrl } from '../utils/utils'

const PlayerCardWrapper = () => {
  const { playerId } = useParams()
  const dispatch = useDispatch()
  const results = useSelector(allResults)
  const resultsFetchStatus = useSelector((state) => state.results?.status)
  const [player, setPlayer] = useState(null)
  const [loading, setLoading] = useState(true)
  const [resultsByPlayers, setResultsByPlayers] = useState({})

  const fetchPlayerDetails = async () => {
    if (!player) {
      const response = await client.get(`${getBaseUrl()}players/${playerId}`)
      if (response.data) {
        const calculatedInfo = calculatePoints(response.data) || 0
        const playerRating = calculateRatings(calculatedInfo?.totalPoints)
        const mappedPlayer = {
          ...response.data,
          ...{ points: calculatedInfo, playerRating },
        }
        setPlayer(mappedPlayer)
      }
    }
  }

  useEffect(() => {
    fetchPlayerDetails()
  }, [playerId])

  useEffect(() => {
    if (resultsFetchStatus === 'idle') {
      setLoading(true)
      dispatch(fetchResult())
    } else if (resultsFetchStatus === 'succeeded') {
      setResultsByPlayers(getResultsByPlayers(results))
      setLoading(false)
    }
  }, [resultsFetchStatus, dispatch, results])

  const renderContent = () => {
    if (loading) {
      return (
        <Box key="loading" sx={{ width: '100%' }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      )
    } else if (player) {
      return (
        <PlayerCard
          key={playerId}
          player={player}
          results={resultsByPlayers[playerId]}
          loading={loading}
        />
      )
    }
    return null
  }

  return renderContent()
}

export default PlayerCardWrapper
