import * as React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import SvgIcon from '@mui/material/SvgIcon'
import PublishIcon from '@mui/icons-material/Publish'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

export const wrapBoxItem = (element) => {
  return (
    <Box sx={{ justifyContent: 'center', width: 1 }}>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        <Box gridColumn="span 12">
          <Item>{element}</Item>
        </Box>
      </Box>
    </Box>
  )
}

export const alertMessage = (severity, message, navigate) => {
  return (
    <>
      <Alert severity={severity}>{message}</Alert>
      {severity === 'success' && (
        <>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => navigate(-1)}
            fullWidth
          >
            Previous Page
          </Button>
          <Button
            href="/"
            size="small"
            color="info"
            variant="contained"
            fullWidth
          >
            Home Page
          </Button>

          {/* <Typography variant="3" align="right" onClick={() => navigate(-1)}>
            Previous Page
          </Typography>
          <Typography variant="3" href="/" align="left">
            Home Page
          </Typography> */}
        </>
      )}
    </>
  )
}

export const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="">
        Round Rock Tennis League
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export const HomeIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  )
}

const BasicModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export function BasicModal(isOpened, ele, label, callBackFunc) {
  return (
    <div>
      {ele.severity && (
        <Modal
          open={isOpened}
          onClose={() => callBackFunc(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={BasicModalStyle}>
            <>
              <Stack sx={{ width: '100%' }} spacing={3}>
                <Alert severity={ele.severity}>{ele.message}</Alert>
                {ele.severity === 'success' && (
                  <>
                      <Link href={''}>{label}</Link>
                    <Button
                      href="/"
                      color="warning"
                      variant="contained"
                      startIcon={
                        // <HomeIcon color="success" sx={{ fontSize: 50 }} />
                        <PublishIcon>
                          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                        </PublishIcon>
                      }
                    >
                      Home
                    </Button>
                    <Paper
                      size="large"
                      color="warning"
                      variant="contained"
                      startIcon={
                        <HomeIcon color="success" sx={{ fontSize: 50 }} />
                      }
                    >
                    </Paper>
                  </>
                )}
              </Stack>
            </>
          </Box>
        </Modal>
      )}
    </div>
  )
}

export function ZeroIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
        fill="none"
      />
      <path d="M16.14 12.5c0 1-.1 1.85-.3 2.55-.2.7-.48 1.27-.83 1.7-.36.44-.79.75-1.3.95-.51.2-1.07.3-1.7.3-.62 0-1.18-.1-1.69-.3-.51-.2-.95-.51-1.31-.95-.36-.44-.65-1.01-.85-1.7-.2-.7-.3-1.55-.3-2.55v-2.04c0-1 .1-1.85.3-2.55.2-.7.48-1.26.84-1.69.36-.43.8-.74 1.31-.93C10.81 5.1 11.38 5 12 5c.63 0 1.19.1 1.7.29.51.19.95.5 1.31.93.36.43.64.99.84 1.69.2.7.3 1.54.3 2.55v2.04zm-2.11-2.36c0-.64-.05-1.18-.13-1.62-.09-.44-.22-.79-.4-1.06-.17-.27-.39-.46-.64-.58-.25-.13-.54-.19-.86-.19-.32 0-.61.06-.86.18s-.47.31-.64.58c-.17.27-.31.62-.4 1.06s-.13.98-.13 1.62v2.67c0 .64.05 1.18.14 1.62.09.45.23.81.4 1.09s.39.48.64.61.54.19.87.19c.33 0 .62-.06.87-.19s.46-.33.63-.61c.17-.28.3-.64.39-1.09.09-.45.13-.99.13-1.62v-2.66z" />
    </svg>
  )
}

export function zeroIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 0 24 24"
      width="20"
    >
      <path
        d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
        fill="none"
      />
      <path d="M16.14 12.5c0 1-.1 1.85-.3 2.55-.2.7-.48 1.27-.83 1.7-.36.44-.79.75-1.3.95-.51.2-1.07.3-1.7.3-.62 0-1.18-.1-1.69-.3-.51-.2-.95-.51-1.31-.95-.36-.44-.65-1.01-.85-1.7-.2-.7-.3-1.55-.3-2.55v-2.04c0-1 .1-1.85.3-2.55.2-.7.48-1.26.84-1.69.36-.43.8-.74 1.31-.93C10.81 5.1 11.38 5 12 5c.63 0 1.19.1 1.7.29.51.19.95.5 1.31.93.36.43.64.99.84 1.69.2.7.3 1.54.3 2.55v2.04zm-2.11-2.36c0-.64-.05-1.18-.13-1.62-.09-.44-.22-.79-.4-1.06-.17-.27-.39-.46-.64-.58-.25-.13-.54-.19-.86-.19-.32 0-.61.06-.86.18s-.47.31-.64.58c-.17.27-.31.62-.4 1.06s-.13.98-.13 1.62v2.67c0 .64.05 1.18.14 1.62.09.45.23.81.4 1.09s.39.48.64.61.54.19.87.19c.33 0 .62-.06.87-.19s.46-.33.63-.61c.17-.28.3-.64.39-1.09.09-.45.13-.99.13-1.62v-2.66z" />
    </svg>
  )
}

export const supGames = (set, games) => {
  return (
    <>
      {set}
      <sup>{games}</sup>{' '}
    </>
  )
}
