import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    mode: "dark", // Change to 'light' for a light theme
  },
});

const event = {
  name: "fall-2024-championship",
  title: "Register: RRTL Fall 2024 Championship",
  id: 1,
  link: "players/tournament/registration",
};

const HomePageNews = () => {
  return (
    <Grid container spacing={1}>
      {/* Mapping over a single theme */}
      <Grid item xs={12}>
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              p: 1,
              bgcolor: "background.default",
              display: "grid",
              gap: 1,
            }}
          >
            <PaperItem
              key={event.id}
              elevation={24}
              sx={{
                color: "#07ff00",
                fontSize: "1.0rem",
                fontWeight: "bolder",
                backgroundColor: "#f3f2e7",
              }}
            >
              <Link to={event.link}>{event.title}</Link>
            </PaperItem>
          </Box>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
};

const PaperItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

export default HomePageNews;
