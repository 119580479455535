import * as React from 'react'
import { Button } from '@mui/material'
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle'

export const AddNewButton = ({ title, href }) => {
  return (
    <Button
      sx={{ ml: 1 }}
      color={'success'}
      variant="contained"
      href={href}
      endIcon={<PlaylistAddCircleIcon />}
    >
      {title}
    </Button>
  )
}
