// @mui
import { Tooltip } from '@material-ui/core'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Modal,
  Rating,
  Stack,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PlayerRankingDetails from './player.ranking.details'
import { fetchPlayers, selectAllPlayers } from './players.slice'
// utils
import { fPercent, fShortenNumber } from '../utils/formatNumber'
import { calculatePoints, calculateRatings, stringAvatar } from '../utils/utils'
import Scrollbar from '../components/common/Scrollbar'

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

AppTopRelated.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array,
}

export default function AppTopRelated({
  title = 'best players',
  subheader,
  list,
  setShowVideo,
  ...other
}) {
  const [playerRankingData, setPlayerRankingData] = useState(null)
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)
  const dispatch = useDispatch()

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
    if (playersStatus === 'succeeded') {
      const response = getPlayerRankingInputs(allPlayers)
      setPlayerRankingData(response)
    }
  }, [playersStatus, dispatch])

  const getPlayerRankingInputs = (players) => {
    const playerInfo = players.map((p) => {
      const calculatedInfo = calculatePoints(p) || 0
      const playerRatings = calculateRatings(calculatedInfo?.totalPoints)
      setShowVideo(true)
      return {
        teamName: p.teamName,
        matchesWon: p.matchesWon,
        matchesLost: p.matchesLost,
        netGamesLost: p.netGamesLost,
        netGamesWon: p.netGamesWon,
        totalMatchesPlayed: p.totalMatchesPlayed,
        fullName: p.fullName,
        playerPoints: calculatedInfo?.totalPoints,
        playerRatings,
        playerId: p.playerId,
        avatarImgUrl:
          p.avatarImgUrl ||
          'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/avatars/general.jpeg',
      }
    })
    return orderBy(playerInfo, ['playerPoints'], ['desc'])
  }

  const rankingView = (mappedPlayersInfo) => (
    <>
        <Typography variant="subtitle2" component="h2">
          <Tooltip
            title="Matches since summer 2022"
            placement="top-end"
            followCursor
            arrow
          >
            <Chip
              icon={<TrendingUpIcon />}
              label="Round Rock Tennis Leagues - Players Ranking"
              color="primary"
              elevation={24}
              variant="outlined"
              sx={{
                border: 1,
                fontWeight: 'bolder',
                borderLeft: 0,
                borderRight: 0,
                borderRight: 0,
                borderColor: 'blue',
              }}
            />
          </Tooltip>
        </Typography>

 
          <Stack
            spacing={1}
            style={{
              overflowY: 'auto',
              maxHeight: '250px',
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              paddingTop: '4px',
            }}
          >
            {mappedPlayersInfo.map((player, i) => (
              <PlayersRatings
                key={player.playerId}
                player={player}
                position={i + 1}
              />
            ))}
          </Stack>
    </>
  )

  return playerRankingData ? rankingView(playerRankingData) : ''
}

// ----------------------------------------------------------------------

PlayersRatings.propTypes = {
  player: PropTypes.shape({
    fullName: PropTypes.string,
    teamName: PropTypes.string,
    matchesWon: PropTypes.number,
    matchesLost: PropTypes.number,
    totalMatchesPlayed: PropTypes.number,
    avatarImgUrl: PropTypes.string,
  }),
}

function PlayersRatings({ player, position }) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const theme = useTheme()
  const {
    teamName,
    matchesWon,
    matchesLost,
    totalMatchesPlayed,
    fullName,
    playerPoints,
    netGamesWon,
    netGamesLost,
    playerRatings,
    playerId,
    avatarImgUrl = null,
  } = player

  const getPercentage = () => {
    // if (matchesWon === 0) {
    //   return <SentimentVeryDissatisfiedIcon color={'info'} />
    // }
    return `(${fPercent((matchesWon * 100) / (matchesLost + matchesWon))})`
  }
  const handlePlayerClick = () => {}

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <PlayerRankingDetails
            player={player}
            position={position}
            setOpen={setOpen}
          ></PlayerRankingDetails>
        </Box>
      </Modal>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          sx={{
            flexShrink: 0,
            display: 'flex',
            borderRadius: 1.5,
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.neutral',
          }}
        >
          {avatarImgUrl ? (
            <Avatar src={avatarImgUrl} />
          ) : (
            <Avatar {...stringAvatar(fullName)} />
          )}
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            alignItems: 'left',
            textAlign: 'left',
          }}
          onClick={handleOpen}
        >
          <Typography variant="caption" sx={{ ml: 1 }}>
            <strong style={{ color: 'black' }}>{fullName}</strong>
          </Typography>

          <Stack
            direction="column"
            alignItems="left"
            sx={{ mt: 0.1, color: 'text.secondary' }}
          >
            <Divider
              textAlign="left"
              sx={{
                fontSize: '10px',
              }}
            >
              {!matchesWon && !matchesLost ? (
                <>
                  {' '}
                  <SentimentVeryDissatisfiedIcon color={'info'} />
                </>
              ) : (
                `Match W/L:${matchesWon}/${matchesLost} 
                ${getPercentage()}`
              )}
            </Divider>
          </Stack>
        </Box>

        <Stack alignItems="flex-end" sx={{ pr: 3 }}>
          <Rating
            readOnly
            color={'success'}
            name="size-medium"
            sx={{ borderTopColor: 'red' }}
            precision={0.1}
            name="reviews"
            value={playerRatings}
          />
          <Typography
            variant="caption"
            sx={{ mt: 0.5, color: 'text.secondary' }}
          >
            {fShortenNumber(playerPoints)}&nbsp;Points{' '}
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}
