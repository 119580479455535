import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { Chip, Link, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import * as React from 'react'
import {
  getMMDDYY,
  getPlayerFirstNames
} from '../../utils/utils'


export default function ResultsFilterGrid({
  results = [],
  setDetailedView,
  isAdmin,
}) {
  const columns = [
    {
      field: 'id',
      headerName: 'Result Id',
      width: 70,
      type: 'number',
      hide: true,
    },
    { field: 'date', headerName: 'Date', width: 100 },

    { field: 'players', headerName: 'Players', width: 200 },
    { field: 'games', headerName: 'Games', width: 110 },
    {
      field: 'winningTeam',
      headerName: 'Winning Team',
      width: 110,
      hide: false,
    },
    { field: 'teams', headerName: 'Teams', width: 200 },
    { field: 'guestTeam', headerName: 'Guest Team', width: 100, hide: true },

    { field: 'hostTeamPoints', headerName: 'Points', width: 40, hide: true },
    { field: 'guestTeamPoints', headerName: 'Points', width: 40, hide: true },

    { field: 'hostTeamPlayers', headerName: 'Host Players', width: 120 },
    { field: 'guestTeamPlayers', headerName: 'Guest Players', width: 120 },
    {
      field: 'matchSetsSize',
      headerName: '#Sets',
      width: 50,
      type: 'number',
    },
    { field: 'matchStatus', headerName: 'Status', width: 90 },
    {
      field: 'matchPoints',
      headerName: 'Points',
      width: 60,
      type: 'number',
    },
    { field: 'tournamentName', headerName: 'League Type', width: 95 },
    { field: 'comments', headerName: 'Comments', width: 90 },

    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ]
  const getGames = (team) => {
    let gamesStr = team?.setOnePoints
    if (team?.setTwoPoints !== undefined) {
      gamesStr = gamesStr + '|' + team?.setTwoPoints
    }
    if (team?.setThreePoints !== undefined) {
      gamesStr = gamesStr + '|' + team?.setThreePoints
    }
    return gamesStr
  }
  const mappedResults = results.map((e) => {
    return {
      id: e.resultId,
      date: getMMDDYY(e.matchTimeAndDate),
      matchStatus: e.matchStatus,
      comments: e.resultComments,
      matchSetsSize: e.matchSetsSize,
      hostTeam: e.hostTeam.teamName,
      teams: `${e.hostTeam.teamName} VS ${e.guestTeam.teamName}`,
      games: `${getGames(e.hostTeam)} - ${getGames(e.guestTeam)}`,
      guestTeam: e.guestTeam.teamName,
      winningTeam: e.winningTeam.teamName,
      players: `${getPlayerFirstNames(
        e.hostTeamPlayers,
      )} - ${getPlayerFirstNames(e.guestTeamPlayers)}`,
      hostTeamPlayers: getPlayerFirstNames(e.hostTeamPlayers),
      guestTeamPlayers: getPlayerFirstNames(e.guestTeamPlayers),
      hostTeamPoints: e.hostTeam.setOnePoints,
      guestTeamPoints: e.guestTeam.setOnePoints,
      tournamentName: e.tournamentName,
      matchPoints: e.matchPoints,
    }
  })

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          m: 1,
          bgcolor: '#673174',
          color: 'warning.contrastText',
          borderRadius: 1,
        }}
      >
        Results
        <Chip
          size="small"
          variant="outlined"
          sx={{ marginLeft: '6px', marginTop: '4px' }}
          onClick={() => setDetailedView(false)}
          label="Detailed view"
          icon={<KeyboardReturnIcon size="small" />}
          color="warning"
        ></Chip>
        {/* {isAdmin && <AddNewButton title="Add" href="/result/submit" />} */}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        {isAdmin && (
          <Link variant="text" href="/result/submit">
            <Chip label={'Add New Result'} color="primary" />
          </Link>
        )}
        {/* <Chip
          label="Click for simple results view"
          color="success"
          onClick={() => setDetailedView(false)}
        /> */}
      </Stack>
      <Box
        sx={{
          height: 540,
          width: 1,
          '& .winningTeam': {
            // backgroundColor: 'rgb(224 224 224)',
            color: '#00008B',
          },
          '& .games': {
            color: 'red',
            fontSize: "medium"
          },
        }}
      >
        <DataGrid
          rows={mappedResults}
          columns={columns}
          getCellClassName={(params) => {
            if (params.field === 'games') return 'games'
            if (params.field === 'winningTeam' || params.field === 'players')
              return 'winningTeam'
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </>
  )
}
