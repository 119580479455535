import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import { Alert, Chip, Typography, Link } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import FaceIcon from "@mui/icons-material/Face";
import Elevation from "./elevation";
import { registHeader } from "./league-players-registration-form";

const TournamentRegistration = () => {
  const [player1FirstName, setPlayer1FirstName] = useState("");
  const [player1LastName, setPlayer1LastName] = useState("");
  const [player1Email, setPlayer1Email] = useState("");
  const [player1Phone, setPlayer1Phone] = useState("");
  const [player2FirstName, setPlayer2FirstName] = useState("");
  const [player2LastName, setPlayer2LastName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [willProvidePlayer2, setWillProvidePlayer2] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (
      !player1FirstName ||
      player1FirstName.length < 3 ||
      player1FirstName.length > 45
    ) {
      errors.player1FirstName =
        "First name must be between 3 and 45 characters";
    }
    if (
      !player1LastName ||
      player1LastName.length < 3 ||
      player1LastName.length > 45
    ) {
      errors.player1LastName = "Last name must be between 3 and 45 characters";
    }
    if (
      player1Email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(player1Email)
    ) {
      errors.player1Email = "Invalid email address";
    }
    if (player1Phone && (player1Phone.length < 9 || player1Phone.length > 11)) {
      errors.player1Phone = "Phone number must be between 9 and 11 digits";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const data = {
      player1: {
        firstName: player1FirstName,
        lastName: player1LastName,
        email: player1Email,
        phone: player1Phone,
      },
      player2: willProvidePlayer2
        ? null
        : {
            firstName: player2FirstName,
            lastName: player2LastName,
          },
      teamName,
    };
    fetch("https://prod.roundrocktennis.com/v1/challenge ", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setAlertMessage(
          "Registration successful! RRTL management will be in touch with you soon."
        );
      })
      .catch((error) => {
        console.error(error);
        setAlertMessage("Error registering team. Please try again.");
      });
  };

  const handleSwitchChange = (event) => {
    setWillProvidePlayer2(event.target.checked);
  };

  return (
    <Card>
      <CardHeader title={<Elevation contentFun={registHeader} />} />
      <CardContent>
        {alertMessage && <Alert severity="warning">{alertMessage}</Alert>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} padding={0}>
              <Grid container justifyContent="center">
                <Chip
                  color="info"
                  icon={<FaceIcon />}
                  label="First Player (Captain) Info"
                  variant="outlined"
                />
              </Grid>
              <FormHelperText id="captain-role">
                {
                  "RRTL and other teams will communicate directly with the captain only"
                }
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="First Name"
                value={player1FirstName}
                onChange={(event) => setPlayer1FirstName(event.target.value)}
                required
                fullWidth
                error={!!errors.player1FirstName}
                helperText={errors.player1FirstName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Name"
                value={player1LastName}
                onChange={(event) => setPlayer1LastName(event.target.value)}
                required
                fullWidth
                error={!!errors.player1LastName}
                helperText={errors.player1LastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                value={player1Email}
                onChange={(event) => setPlayer1Email(event.target.value)}
                fullWidth
                error={!!errors.player1Email}
                helperText={errors.player1Email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                value={player1Phone}
                onChange={(event) => setPlayer1Phone(event.target.value)}
                fullWidth
                error={!!errors.player1Phone}
                helperText={errors.player1Phone}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={willProvidePlayer2}
                    onChange={handleSwitchChange}
                  />
                }
                label="I'll provide the second player's name later by August 15, 2024."
              />
            </Grid>
            {!willProvidePlayer2 && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Chip
                      color="info"
                      icon={<FaceIcon />}
                      label="Second Player Info"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="First Name"
                    value={player2FirstName}
                    onChange={(event) =>
                      setPlayer2FirstName(event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Last Name"
                    value={player2LastName}
                    onChange={(event) => setPlayer2LastName(event.target.value)}
                    fullWidth
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                label="Team Name **"
                value={teamName}
                onChange={(event) => setTeamName(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                ** Existing team name will be used if players are already on a
                team.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={8}>
                  <Typography
                    color="secondary"
                    variant="h6"
                    gutterBottom
                    align="center"
                  >
                    Important Notes:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="subtitle1" gutterBottom>
                        Please review the official rules at{" "}
                        <Link href="/rules">
                          RRTL Rules
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle1" gutterBottom>
                        Teams are expected to play a minimum of 2 matches per
                        week.
                      </Typography>
                    </li>
                    {/* <li>
                      <Typography variant="subtitle1" gutterBottom>
                        League matches and knockout matches will be played on the announced dates.
                      </Typography>
                    </li> */}
                    <li>
                      <Typography variant="subtitle1" gutterBottom>
                        Registration does not guarantee team selection. RRTL
                        reserves the right to accept or decline teams and
                        players for the coming season.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle1" gutterBottom>
                        RRTL reserves the right to adjust teams in groups as
                        needed.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle1" gutterBottom>
                        After team registration, RRTL will publish team
                        groupings and match schedules.
                      </Typography>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  Register Team
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default TournamentRegistration;
