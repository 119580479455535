import {
  Chip,
  Divider,
  FormHelperText,
  Input,
  Paper,
  Snackbar,
} from '@material-ui/core'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import { Alert } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'
import { makePostCall } from '../../api/client'
import { TextMaskCustom } from '../../components/common/TextMaskCustom'
import { formatCleanName } from '../../utils/utils'

const filter = createFilterOptions()

export default function FreeSoloCreatePlayer({
  players,
  playerNameInfo,
  setPlayerNameInfo,
  self,
}) {
  const [open, setOpen] = useState(false)
  const [toastOpen, setToastOpen] = useState(false)
  const [savedPlayer, setSavedPlayer] = useState(null)

  const [submitButton, setSubmitButton] = useState({
    message: null,
    severity: null,
  })
  const [dialogValue, setDialogValue] = useState({
    fullName: '',
    firstName: '',
    lastName: '',
    contactNumber: '',
  })

  const handleClose = () => {
    setDialogValue({
      fullName: '',
      firstName: '',
      lastName: '',
      contactNumber: '',
    })
    setOpen(false)
  }

  useEffect(() => {
    if (submitButton.message) {
      setToastOpen(true)
    }
  }, [submitButton])

  const handleSubmit = async (event) => {
    event.preventDefault()
    const firstName = formatCleanName(dialogValue.firstName)
    const lastName = formatCleanName(dialogValue.lastName)
    setPlayerNameInfo({
      fullName: `${firstName} ${lastName}`,
      firstName: dialogValue.firstName,
      lastName: dialogValue.lastName,
      contactNumber: dialogValue.contactNumber,
    })
    setSavedPlayer({
      fullName: `${firstName} ${lastName}`,
      firstName: dialogValue.firstName,
      lastName: dialogValue.lastName,
      contactNumber: dialogValue.contactNumber,
    })

    if (self && !dialogValue.contactNumber) {
      setSubmitButton({
        message: 'Please provide your contact details',
        severity: 'error',
      })
      return
    }
    if (
      firstName.length < 3 ||
      firstName.length > 30 ||
      lastName.length < 3 ||
      lastName.length > 30
    ) {
      setSubmitButton({
        message:
          'Invalid name, Please provide a name between 3 & 30 characters.',
        severity: 'error',
      })
      return
    }

    const playerDetails = {
      firstName,
      lastName,
      contactNumber: dialogValue?.contactNumber,
      playerId: `${firstName
        .replace(/\s+/g, '_')
        .toLowerCase()}-${lastName.replace(/\s+/g, '_').toLowerCase()}`,
      createdBy: 'self',
      createdAt: new Date().toISOString(),
      email: 'null',
      phone: dialogValue?.contactNumber,
      description: 'spring 2024',
      allowMessage: false,
    }
    console.log('playerDetails  ', playerDetails)
    makePostCall(playerDetails, 'player', null, setSubmitButton)
    handleClose()
  }

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToastOpen(false)
  }

  return (
    <>
      <Autocomplete
        value={playerNameInfo || {}}
        key={`${self ? 'self-create-players' : 'partner-create-players'}`}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              setOpen(true)
              setDialogValue({
                fullName: newValue,
                firstName: '',
              })
            })
          } else if (newValue && newValue.inputValue) {
            setOpen(true)
            setDialogValue({
              fullName: newValue.inputValue,
              lastName: '',
            })
          } else {
            setPlayerNameInfo(
              !newValue
                ? {
                    playerId: null,
                    firstName: '',
                    lastName: '',
                    fullName: '',
                  }
                : newValue,
            )
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              fullName: (
                <Chip
                  color="default"
                  id={`${self ? 'self-register' : 'partner-register'}`}
                  variant="default"
                  label={
                    <h5 style={{ display: 'flex', alignItems: 'center' }}>
                      <SentimentVeryDissatisfiedIcon color="warning" />{' '}
                      &nbsp;Not Found! &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <span></span>
                      Click here to register! &nbsp; &nbsp;
                      <PersonAddAltIcon
                        color="success"
                        onClick={() => setOpen(true)}
                      />
                    </h5>
                  }
                />
              ),
            })
          }

          return filtered
        }}
        id={`${self ? 'self-dialog' : 'partner-dialog'}`}
        options={players}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option?.inputValue) {
            return option.inputValue
          }
          return option.fullName
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.fullName}</li>}
        sx={{ width: '100%' }}
        freeSolo
        renderInput={(params) => (
          <TextField
            required
            error={!playerNameInfo.fullName}
            {...params}
            label={
              self
                ? 'Enter your name, please'
                : 'Enter your partner name, please'
            }
          />
        )}
      />
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity={submitButton.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {submitButton.message}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{ fontWeight: 'bold' }}>
            Register Player!
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              id={`${self ? 'self-first-name' : 'partner-first-name'}`}
              value={dialogValue.firstName || ''}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  firstName: event.target.value,
                })
              }
              label={`${self ? 'Your First Name' : 'Partner First Name'}`}
              type="string"
              error={dialogValue?.firstName?.length < 2}
              variant="standard"
            />
            <TextField
              id={`${self ? 'self-last-name' : 'partner-last-name'}`}
              fullWidth
              value={dialogValue.lastName || ''}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  lastName: event.target.value,
                })
              }
              label={`${self ? 'Your Last Name' : 'Partner Last Name'}`}
              type="string"
              error={dialogValue?.lastName?.length < 2}
              variant="standard"
            />
            {self && (
              <div>
                <Input
                  error={dialogValue?.contactNumber?.length < 13}
                  value={dialogValue.contactNumber || ''}
                  fullWidth
                  onChange={(event) =>
                    setDialogValue({
                      ...dialogValue,
                      contactNumber: event.target.value,
                    })
                  }
                  name="player-contact"
                  id="formatted-player-contact-input"
                  inputComponent={TextMaskCustom}
                />
                <FormHelperText id="component-helper-text">
                  {'Your contact Number'}
                </FormHelperText>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              disabled={
                !dialogValue.firstName ||
                !dialogValue.lastName ||
                (self && !dialogValue?.contactNumber) ||
                dialogValue?.contactNumber?.length < 13
              }
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
