import { Paper } from '@material-ui/core'
import { Box, Button, Typography } from '@mui/material'
// @mui
import { alpha, styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' //
import Slider from 'react-slick'
import { CarouselArrowIndex } from '../common/carousel'
//
import Image from '../common/Image'

// ----------------------------------------------------------------------

const THUMB_SIZE = 64

const RootStyle = styled('div')(({ theme }) => ({
  '& .slick-slide': {
    float: theme.direction === 'rtl' ? 'right' : 'left',
    '&:focus': { outline: 'none' },
  },
}))

// ----------------------------------------------------------------------

ProductDetailsCarousel.propTypes = {
  product: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default function ProductDetailsCarousel({ images }) {
  const [openLightbox, setOpenLightbox] = useState(false)

  const [selectedImage, setSelectedImage] = useState(0)

  const [currentIndex, setCurrentIndex] = useState(0)

  const [nav1, setNav1] = useState()

  const [nav2, setNav2] = useState()

  const slider1 = useRef(null)

  const slider2 = useRef(null)

  const imagesLightbox = images.map((_image) => _image.imgUrl)

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url)
    setOpenLightbox(true)
    setSelectedImage(selectedImage)
  }

  const settings1 = {
    speed: 320,
    dots: false,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => setCurrentIndex(next),
  }

  const settings2 = {
    speed: 800,
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    autoplay: true,
    centerPadding: '0px',
    slidesToShow: images.length > 3 ? 3 : images.length,
  }

  useEffect(() => {
    if (slider1.current) {
      setNav1(slider1.current)
    }
    if (slider2.current) {
      setNav2(slider2.current)
    }
  }, [])

  const handlePrevious = () => {
    return slider2.current?.slickPrev()
  }

  const handleNext = () => {
    return slider2.current?.slickNext()
  }

  return (
    <RootStyle>
      <Box sx={{ pl: 1 , p2: 1, pb: 1}}>
        <Box
          sx={{
            zIndex: 0,
            borderRadius: 2,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Slider {...settings1} asNavFor={nav2} ref={slider1}>
            {images.map((img, i) => (
              <>
                <Button color="primary">{img.title}</Button>
              
              <Image
                key={`${img.title}-${i}`}
                alt="large image"
                src={img.imgUrl}
                ratio="1/1"
                onClick={() => handleOpenLightbox(img.imgUrl)}
                sx={{ cursor: 'zoom-in' }}
              />
                </>
            ))}
          </Slider>
          <CarouselArrowIndex
            index={currentIndex}
            total={images.length}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        </Box>
      </Box>
      <Box
        sx={{
          my: 3,
          mx: 'auto',
          '& .slick-current .isActive': { opacity: 1 },
          ...(images.length === 1 && { maxWidth: THUMB_SIZE * 1 + 16 }),
          ...(images.length === 2 && { maxWidth: THUMB_SIZE * 2 + 32 }),
          ...(images.length === 3 && { maxWidth: THUMB_SIZE * 3 + 48 }),
          ...(images.length === 4 && { maxWidth: THUMB_SIZE * 3 + 48 }),
          ...(images.length >= 5 && { maxWidth: THUMB_SIZE * 6 }),
          ...(images.length > 2 && {
            position: 'relative',
            '&:before, &:after': {
              top: 0,
              zIndex: 9,
              content: "''",
              height: '100%',
              position: 'absolute',
              width: (THUMB_SIZE * 2) / 3,
              backgroundImage: (theme) =>
                `linear-gradient(to left, ${alpha(
                  theme.palette.background.paper,
                  0,
                )} 0%, ${theme.palette.background.paper} 100%)`,
            },
            '&:after': { right: 0, transform: 'scaleX(-1)' },
          }),
        }}
      >
      </Box>
      {openLightbox && (
        <Lightbox
          mainSrc={imagesLightbox[selectedImage]}
          nextSrc={images[(0 + 1) % images.length]}
          prevSrc={images[(selectedImage + images.length - 1) % images.length]}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() => {
            handlePrevious()
            setSelectedImage(
              (selectedImage + imagesLightbox.length - 1) %
                imagesLightbox.length,
            )
          }}
          onMoveNextRequest={() => {
            handleNext()
            setSelectedImage((selectedImage + 1) % imagesLightbox.length)
          }}
        />
      )}
    </RootStyle>
  )
}
