import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { client } from '../../api/client'
import { getBaseUrl } from '../../utils/utils'

const initialState = {
  scheduleData: [],
  status: 'idle',
  error: null,
}

const baseUrl = getBaseUrl()

const scheduleSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    async addSchedule(state, action) {
      console.log('state: ', state)
      console.log('action payload: ', action.payload)
      const response = await client.post(`${baseUrl}schedules`, action.payload)
      console.log('Response from backend: ', response.data)
      return response.data
    },
  },
  extraReducers(builder) {
    if (!fetchSchedule) return
    builder
      .addCase(fetchSchedule.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchSchedule.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.scheduleData = state.scheduleData?.concat(action.payload)
      })
      .addCase(fetchSchedule.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const fetchSchedule = createAsyncThunk(
  'schedules/fetchSchedule',
  async () => {
    const response = await client.get(`${baseUrl}schedules`)
    return response.data
  },
)

export default scheduleSlice.reducer

export const { addSchedule } = scheduleSlice.actions

export const allSchedules = (state) => state.schedules?.scheduleData
