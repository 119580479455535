import { AccessTime } from '@mui/icons-material'
import InputIcon from '@mui/icons-material/Input'
import {
  Box,
  Card,
  CardActions,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'

const EventCountdown = () => {
  // Set the target date for the event
  const targetDate = new Date('2024-04-20T00:00:00')

  // Calculate the remaining time
  const [remainingTime, setRemainingTime] = useState(() =>
    calculateRemainingTime(),
  )

  function calculateRemainingTime() {
    const now = new Date()
    const timeDifference = targetDate - now
    if (timeDifference <= 0) {
      return 'Event has started!'
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    )
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
    )
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)

    return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds left`
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const timeRemaining = calculateRemainingTime()
      if (timeRemaining === 'Event has started!') {
        clearInterval(interval)
      }
      setRemainingTime(timeRemaining)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Container maxWidth="lg" sx={{ p: '0px' }}>
      <Card
        style={{
          border: '1px dotted blue', // Change the border color to blue
        }}
      >
        <Box
          textAlign="center"
          p={1}
          bgcolor="#2e7d32"
          color="primary.contrastText"
        >
          <Typography variant="h4" gutterBottom>
            <strong>RRTL</strong>
          </Typography>
          <Typography variant="h5" gutterBottom>
            <strong>Spring 2024 Championships</strong>
          </Typography>
          <Typography variant="h6" sx={{ color: 'black' }} gutterBottom>
            <b>
              Starting in April 20th<sup>*</sup>
            </b>
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="center">
            <AccessTime fontSize="large" />
            <Typography
              variant="h6"
              sx={{ color: 'rgb(241 241 8)' }}
              component="span"
              ml={1}
            >
              {remainingTime}
            </Typography>
          </Box>
          {/* <Typography variant="h6" sx={{ color: 'black' }} gutterBottom> */}
          <Grid container spacing={2}>
            <Grid
              item
              lg={10}
              sx={{ fontSize: '1.1rem', white: 'INDIGO', fontWeight: 'bold' }}
            >
              Please feel free to register your team in advance.
              <sup>**</sup>
              <strong>
                <IconButton
                  color="secondary"
                  href="/players/leauge/registration"
                  aria-label="sign up"
                  sx={{ fontSize: '1rem', color: 'INDIGO' }}
                >
                  {' '}
                  Register
                  <InputIcon href="/teams/sign-up" />
                </IconButton>
              </strong>
            </Grid>
          </Grid>
          {/* </Typography> */}
          <FormHelperText
            id="friend-first-helper-text"
            sx={{ textAlign: 'left' }}
          >
            <sup style={{ color: 'INDIGO' }}>*</sup>The final dates will be
            announced at a later time.<br></br>
            <sup style={{ color: 'INDIGO' }}>**</sup>It's important to note that
            team player acceptance is solely at the discretion of the RRTL
            committee.
          </FormHelperText>
        </Box>

        <CardActions>
          <Typography
            variant="h6"
            component="span"
            style={{ width: '100%', textAlign: 'center' }}
          >
            Please Contact: 1 708 515 0998
          </Typography>
        </CardActions>
      </Card>
    </Container>
  )
}

export default EventCountdown
