import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { LinearProgress } from '@mui/material'
import { BarChart } from '@mui/x-charts/BarChart'
import { orderBy } from 'lodash'
import {
  calculatePoints,
  calculateRatings,
  getWindowDimensions,
} from '../utils/utils'
import { fetchPlayers, selectAllPlayers } from './players.slice'

const AppTopRelated = ({
  title = 'best players',
  subheader,
  list,
  ...other
}) => {
  const dispatch = useDispatch()
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)
  const [playerRankingData, setPlayerRankingData] = useState([])
  const [playerNames, setPlayerNames] = useState([])
  const [wonMatches, setWonMatches] = useState([])
  const [lostMatches, setLostMatches] = useState([])
  const [points, setPoints] = useState([])
  const [gamesWon, setGamesWon] = useState([])
  const [gamesLost, setGamesLost] = useState([])

  const getPlayerRankingInputs = (players) => {
    return orderBy(
      players.map((p) => {
        const calculatedInfo = calculatePoints(p) || 0
        const playerRatings = calculateRatings(calculatedInfo?.totalPoints)

        return {
          teamName: p.teamName,
          matchesWon: p.matchesWon,
          matchesLost: p.matchesLost,
          netGamesLost: p.netGamesLost,
          netGamesWon: p.netGamesWon,
          totalMatchesPlayed: p.totalMatchesPlayed,
          fullName: p.fullName,
          playerPoints: calculatedInfo?.totalPoints,
          playerRatings,
          playerId: p.playerId,
          avatarImgUrl:
            p.avatarImgUrl ||
            'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/avatars/general.jpeg',
        }
      }),
      ['playerPoints'],
      ['desc'],
    )
  }
  const batchSize = 5
  let startIndex = 0

  function updatePlayerRankingData(records) {
    const endIndex = Math.min(startIndex + batchSize, records.length)
    const batchToUpdate = records.slice(startIndex, endIndex)

    setPlayerRankingData(batchToUpdate)

    startIndex = endIndex
    if (startIndex >= 21) {
      // Reset to the beginning of the records when reaching the end.
      startIndex = 0
    }
  }

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
    if (playersStatus === 'succeeded') {
      const response = getPlayerRankingInputs(allPlayers)
      const firstThreeRecords = response.slice(1, 7) // Changed from 1 to 0 for first ten records
      setPlayerRankingData(firstThreeRecords)
      setInterval(() => updatePlayerRankingData(response), 5000)
    }
  }, [playersStatus, dispatch])

  useEffect(() => {
    const names = []
    const won = []
    const lost = []
    const playerPoints = []
    const gamesWonData = []
    const gamesLostData = []

    playerRankingData.forEach((player) => {
      names.push(player.fullName?.split(' ')[0])
      won.push(player.matchesWon)
      lost.push(player.matchesLost)
      playerPoints.push(player.playerPoints)
      gamesWonData.push(player.netGamesWon)
      gamesLostData.push(player.netGamesLost)
    })

    // Update state variables with the extracted data
    setPlayerNames(names)
    setWonMatches(won)
    setLostMatches(lost)
    setPoints(playerPoints)
    setGamesWon(gamesWonData)
    setGamesLost(gamesLostData)
  }, [playerRankingData])

  const { width } = getWindowDimensions()

  return playerNames.length > 0 ? (
    <BarChart
      //   width={width}
      height={300}
      legend={{
        direction: 'column',
      }}
      sx={{
        border: '1px solid #000',
        boxShadow: 24,
        width: '100%',
        margin: 0,
        padding: 0,
        '--ChartsLegend-itemWidth': '100px',
        '--ChartsLegend-itemMarkSize': '11px',
        '--ChartsLegend-rootSpacing': '6px',
        '--ChartsLegend-labelSpacing': '5px',
        '--ChartsLegend-rootOffsetY': '-15px',
      }}
      series={[
        {
          data: wonMatches,
          label: 'Matches Won',
          stack: 'matches',
          yAxisKey: 'leftAxisId',
        },
        {
          data: lostMatches,
          label: 'Matches Lost',
          stack: 'matches',
          yAxisKey: 'leftAxisId',
        },
        {
          data: gamesWon,
          label: 'Games Won',
          id: 'uvId',
          stack: 'games',
          yAxisKey: 'rightAxisId',
        },
        {
          data: gamesLost,
          label: 'Games Lost',
          id: 'uvId2',
          stack: 'games',
          yAxisKey: 'rightAxisId',
        },
      ]}
      xAxis={[
        {
          data: playerNames,
          scaleType: 'band',
          categoryGapRatio: 0.5,
          barGapRatio: 0.4,
        },
      ]}
      yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
      rightAxis="rightAxisId"
    />
  ) : (
    <LinearProgress color="success" />
  )
}

AppTopRelated.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array,
}

export default AppTopRelated
