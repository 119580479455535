import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import PlayerHomeProfile from './player.profile'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Carousel from 'react-material-ui-carousel'
import { selectAllPlayers, fetchPlayers } from './players.slice'
import { useSelector, useDispatch } from 'react-redux'
import SignUpPlayer from './player.submit'
import { useAuth0 } from '@auth0/auth0-react'
import MediaControlCard from "./player.card.profile"

let selectedPlayer
export default function PlayersList({ viewPage }) {
  const { isAuthenticated, user } = useAuth0()
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)
  const dispatch = useDispatch()

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }))

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
  }, [playersStatus, dispatch])

  const [edit, setEdit] = React.useState(false)

  const handleEdit = (data) => {
    selectedPlayer = data
    if (isAuthenticated && data.email === user.email) {
      setEdit(true)
    }
  }

  const editPlayer = () => {
    return <SignUpPlayer player={selectedPlayer}></SignUpPlayer>
  }
  const getPlayersPageView = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {allPlayers.map((player, index) => (
          <Grid item xs={12} md={6} lg={4} key={player.playerId}>
            <Item key={player.playerId} onClick={() => handleEdit(player)}>
              <MediaControlCard playerInfo={player} />
            </Item>
            {/* <Item align="right">
              <Fab
                size="small"
                color="secondary"
                aria-label="edit"
                onClick={(e) => handleEdit(e, player)}
              >
                <EditIcon />
              </Fab>
            </Item> */}
          </Grid>
        ))}
      </Grid>
    )
  }
  const getHomePageView = () => {
    return (
      <Carousel>
        {allPlayers.map((player, index) => (
          <Grid key={index}>
            <Item key={player.playerId} onClick={() => handleEdit(player)}>
              <PlayerHomeProfile playerInfo={player} />
            </Item>
          </Grid>
        ))}
        {/* </Grid> */}
      </Carousel>
    )
  }

  const playerList = () => {
    console.log('menfesto ...', viewPage)
    return viewPage === 'HomePage' ? getHomePageView() : getPlayersPageView()
  }
  return edit ? editPlayer() : playerList()
}
