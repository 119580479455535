import { useAuth0 } from '@auth0/auth0-react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import { Chip, Stack, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPlayers, selectAllPlayers } from '../players/players.slice'
import { getBaseUrl } from '../utils/utils'
import EditTeamSlice from './team.edit'
import { allTeams, fetchTeams } from './teams.slice'

let selectedTeam = {}

export default function TeamsSummary() {
  const {
    isAuthenticated,
    user,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0()
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [token, setToken] = React.useState(null)
  const dispatch = useDispatch()
  const teamsFetchStatus = useSelector((state) => state?.teams?.status)
  const teams = useSelector(allTeams)

  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)

  const [edit, setEdit] = React.useState(false)

  useEffect(() => {
    async function _setToken() {
      setToken(await getAccessTokenSilently())
    }
    _setToken()
  }, [isAuthenticated])

  useEffect(() => {
    if (teamsFetchStatus === 'idle') {
      dispatch(fetchTeams())
    }
  }, [teamsFetchStatus, dispatch])

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
    if (playersStatus === 'succeeded' && isAuthenticated) {
      const findP = allPlayers.find((p) => user?.email === p.playerId)
      setIsAdmin(findP?.admin ? true : false)
    }
  }, [playersStatus, dispatch])

  const handleEditTeam = (data) => {
    selectedTeam = data
    setEdit(true)
  }
  const handleDeleteTeam = (data) => {
    const baseUrl = getBaseUrl()
    fetch(`${baseUrl}teams/${data.teamId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const isOwner = (rule) => {
    if (isAuthenticated) {
      if (isAdmin) {
        return true
      } else if (
        rule?.createdBy === user?.email ||
        rule?.updatedBy === user?.email
      ) {
        return true
      }
    }
    return false
  }
  const editTeam = () => {
    return (
      <EditTeamSlice
        editTeam={selectedTeam}
        allTeams={teams}
        user={user}
        token={token}
        isAdmin={isAdmin}
      ></EditTeamSlice>
    )
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))
  const notFound = () => {
    return (
      <>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            mt: 1,
            mb: 1,
            bgcolor: '#149e72',
            color: 'warning.contrastText',
            borderRadius: 1,
          }}
        >
          No team is registered yet.
        </Typography>
      </>
    )
  }
  const showLoading = () => {
    return (
      <Typography variant="h5">
        <Stack spacing={1} sx={{ textAlign: 'center' }}>
          ...loading teams
        </Stack>
      </Typography>
    )
  }
  const teamsDisplay = () => {
    return (
      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 100 }} aria-label="team table">
            <TableHead>
              <TableRow>
                <StyledTableCell color="primary">Team Name</StyledTableCell>
                <StyledTableCell align="right">Captain</StyledTableCell>
                <StyledTableCell align="right">Team Players</StyledTableCell>
                {/* <StyledTableCell align="right">Player Two</StyledTableCell> */}
                {/* {isAuthenticated && (
                  <StyledTableCell align="right">Edit</StyledTableCell>
                )} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.map((row) => (
                <TableRow
                  key={row.teamId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.teamName}
                    <span></span>
                    {isAuthenticated && isOwner(row) && (
                      <>
                        <Chip
                          variant="outlined"
                          size="small"
                          sx={{
                            '& .MuiChip-label': {
                              color: 'blue',
                              fontWeight: 'small',
                            },
                          }}
                          color="error"
                          icon={<EditIcon />}
                          label={'Edit'}
                          onClick={() => handleEditTeam(row)}
                        />
                        <Chip
                          variant="outlined"
                          size="small"
                          sx={{
                            '& .MuiChip-label': {
                              color: 'blue',
                              fontWeight: 'small',
                            },
                          }}
                          color="error"
                          icon={<DeleteForeverIcon />}
                          label={'Delete'}
                          onClick={() => handleDeleteTeam(row)}
                        />
                      </>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.teamCaptainName}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.teamPlayersFullNames[0]} -{'  '}
                    {row.teamPlayersFullNames[1]}
                  </StyledTableCell>
                  {/* <StyledTableCell align="right">
                    {row.teamPlayersFullNames[1]}
                  </StyledTableCell> */}
                  {/* {isAuthenticated && isOwner(row) && (
                    <StyledTableCell align="right">
                      <EditIcon
                        onClick={() => handleEdit(row)}
                        color="warning"
                      />
                    </StyledTableCell>
                  )} */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
  const teamsSummaryList = () => {
    return (
      <>
        {isLoading ||
        teamsFetchStatus === 'idle' ||
        teamsFetchStatus === 'loading'
          ? showLoading()
          : teams?.length === 0
          ? notFound()
          : teamsDisplay()}
      </>
    )
  }
  return edit ? editTeam() : teamsSummaryList()
}
