import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../api/client'
import { getBaseUrl } from '../utils/utils'

const initialState = {
  ruleData: [],
  status: 'idle',
  error: null,
}

const baseUrl = getBaseUrl()

const ruleSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    async addRule(state, action) {
      console.log('state: ', state)
      console.log('action payload: ', action.payload)
      const response = await client.post(`${baseUrl}rules`, action.payload)
      console.log('Response from backend: ', response.data)
      return response.data
    },
  },
  extraReducers(builder) {
    if (!fetchRule) return
    builder
      .addCase(fetchRule.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchRule.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.ruleData = state.ruleData?.concat(action.payload)
      })
      .addCase(fetchRule.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const fetchRule = createAsyncThunk('rules/fetchRule', async () => {
  const response = await client.get(`${baseUrl}rules`)
  console.log('Get rules response from backend: ', response.data)
  return response.data
})

export default ruleSlice.reducer

export const { addRule } = ruleSlice.actions

export const allRules = (state) => state.rules?.ruleData
