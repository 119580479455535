import { Alert } from '@mui/material';
import * as React from 'react';
import Ticker from 'react-ticker';

const displayMessages = [
  <Alert p="0" color="info" icon={false}>Join us, and Be the Best in the field.</Alert>,
  <Alert p="0" color="info" icon={false}>Be ready! <strong>Season 9</strong> is coming soon. You'll need your best game!.</Alert>,
  <Alert p="0" color="info" icon={false}>Play Tennis, Let your &#128147; Race</Alert>,
  <Alert p="0" color="info" icon={false}>Follow the Rules or follow the <strong>FOOLS</strong>.</Alert>,
  <Alert p="0" color="info" icon={false}>Tennis is not just a Game ...</Alert>,
  <Alert p="0" color="info" icon={false}>It's a gateway to a <strong>Happy Life</strong></Alert>,
  <Alert p="0" color="info" icon={false}>Play Tennis, <strong>Live Better</strong>.</Alert>,
  <Alert p="0" color="info" icon={false}>Join RRTL - GET your tennis on TRACK</Alert>,
  <Alert p="0" color="info" icon={false}>Anything that can go Wrong will go WRONG.</Alert>,
  <Alert p="0" color="info" icon={false}>Some days you play volleyball &amp; go to Gym</Alert>,
  <Alert p="0" color="info" icon={false}>But when you play Tennis, it's <strong>Real DEAL</strong>!</Alert>,
  <Alert p="0" color="info" icon={false}>How you can take your Tennis seriously ...</Alert>,
  <Alert p="0" color="info" icon={false}>Join RRTL!!</Alert>,
  <Alert p="0" color="info" icon={false}>There are so many BEAUTIFUL Reasons ...</Alert>,
  <Alert p="0" color="info" icon={false}>To be HAPPY and Join <strong>RRTL</strong></Alert>,
  <Alert p="0" color="info" icon={false}>Life is Better on Tennis Courts!</Alert>,
  <Alert p="0" color="info" icon={false}>If you become Champion Today, Thank RRTL.</Alert>,
  <Alert p="0" color="info" icon={false}>It's all going to work out, keep going with RRTL!</Alert>,
  <Alert p="0" color="info" icon={false}>Fuel up, it's <strong>🏆</strong> time! Give it your all on the court!</Alert>,
  <Alert p="0" color="info" icon={false}>Bottle up happiness with every game. RRTL</Alert>
]

export default function Quotes() {
  const [counter, setCounter] = React.useState(0);

  const handleNext = () => {
    setCounter((prevCounter) => (prevCounter + 1) % displayMessages.length);
  };

  return (
    <Ticker mode="chain" speed={4} onNext={handleNext}>
      {() => displayMessages[counter]}
    </Ticker>
  );
}
