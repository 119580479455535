import { useReducer } from 'react'
import { useDispatch } from 'react-redux'

const initialFileState = {
	fileError: null,
	fileName: null,
	fileSize: null,
	fileType: null,
	fileContents: null,
}

function bytesToMb(bytes) {
	const mb = bytes / 1000000
	return mb
}

export default function UseFileChange() {
	const dispatch = useDispatch()
	const [{ fileError, fileContents, fileName, fileSize, fileType }, fileDispatch] = useReducer(
		fileChangeReducer,
		initialFileState
	)

	const handleFileChange = (event, fileObjIn = null) => {
		const fileObj = fileObjIn || (event.target.files && event.target.files[0])
		if (!fileObj) {
			return
		}

		// const [type] = fileObj.type.split('/')
		// if (!type || type !== 'image') {
		//   fileDispatch({
		//     type: 'FILE_CHANGE_FAILURE',
		//     fileError: 'You can only upload image files.',
		//   })
		//   return
		// }

		if (fileObj.size > 10000000) {
			fileDispatch({
				type: 'FILE_CHANGE_FAILURE',
				fileError: `File is too large, file size is ${bytesToMb(fileObj.size).toFixed(
					2
				)} MB, maximum allowed size - 10 MB.`,
			})
			return
		}

		// dispatch(
		//   storeEvent({
		//     type: 'FILE_CHANGE_SUCCESS',
		//     fileName: fileObj.name,
		//     fileSize: fileObj.size,
		//     fileType: fileObj.type,
		//   }),
		// )

		fileDispatch({
			type: 'FILE_CHANGE_SUCCESS',
			fileName: fileObj.name,
			fileSize: fileObj.size,
			fileType: fileObj.type,
			fileContents: fileObj,
		})
	}

	return {
		fileError,
		fileContents,
		fileName,
		fileType,
		fileSize,
		handleFileChange,
		fileDispatch,
	}
}

export function fileChangeReducer(_state, action) {
	switch (action.type) {
		case 'FILE_CHANGE_SUCCESS': {
			return {
				fileError: null,
				fileName: action.fileName,
				fileSize: action.fileSize,
				fileType: action.fileType,
				fileContents: action.fileContents,
			}
		}
		case 'FILE_CHANGE_FAILURE': {
			return {
				...initialFileState,
				fileError: action.fileError,
			}
		}
		case 'RESET_FILE_STATE': {
			return initialFileState
		}
		default: {
			throw new Error(`Unsupported action type: ${JSON.stringify(action)}`)
		}
	}
}
