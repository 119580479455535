import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import CampaignIcon from '@mui/icons-material/Campaign'
import RotateLeftIcon from '@mui/icons-material/CheckCircleOutline'
import DateRangeIcon from '@mui/icons-material/DateRange'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import GavelIcon from '@mui/icons-material/Gavel'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import CheckCircleOutlineIcon from '@mui/icons-material/RotateLeft'
import RuleIcon from '@mui/icons-material/Rule'
import SportsHandballIcon from '@mui/icons-material/SportsHandball'
import SportsTennisIcon from '@mui/icons-material/SportsTennis'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Alert, FormHelperText } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as React from 'react'

export default function SeasonTimeLine() {
  const timelineData = [
    {
      date: 'July 19 - 25',
      icon: <GroupAddIcon />,
      textColor: 'darkmagenta',
       text: (
        <>
          <RotateLeftIcon color="success" />Committee Volunteer Reg
        </>
      ),
      color: 'success',
    },
    {
      date: 'July 28',
      icon: <CampaignIcon />,

       text: (
        <>
          <RotateLeftIcon color="success" />Committee Announcement
        </>
      ),
      textColor: 'darkmagenta',
      color: 'secondary',
    },
    {
      date: 'Aug 7- Aug 11 ',
      icon: <RuleIcon />,
      text: (
        <>
          <RotateLeftIcon color="success" />Provisional Rules published
        </>
      ),

      helperText: 'Feedback/Suggestion Period.',
      color: 'warning',
      textColor: 'darkmagenta',
    },
    {
      date: 'Aug 14',
      icon: <GavelIcon />,
       
      text: (
        <>
          <RotateLeftIcon color="success" />Approved rules published
        </>
      ),

      color: 'error',
      textColor: 'darkmagenta',
    },
    {
      date: 'Aug 7 - Aug 15',
      icon: <AppRegistrationIcon />,
     
      text: (
        <>
          <RotateLeftIcon color="success" />Team Registration
        </>
      ),
      color: 'secondary',
      textColor: 'darkmagenta',
    },
    {
      date: 'Aug 17',
      icon: <CampaignIcon />,
       text: (
        <>
          <RotateLeftIcon color="success" /> Teams Announced
        </>
      ),
    
      textColor: 'darkmagenta',

      color: 'success',
    },
    {
      date: 'Aug 25 - Aug 28',
      icon: <DateRangeIcon />,
       text: (
        <>
          <RotateLeftIcon color="warning" /> Provisional schedule published
        </>
      ),
  
      helperText: 'Feedback/Suggestion Period.',
      color: 'primary',
      textColor: 'darkmagenta',
    },
    {
      date: 'Sep 1',
      icon: <ThumbUpOffAltIcon />,
      text: (
        <>
          <RotateLeftIcon color="success" /> Approved schedule published
        </>
      ),
      textColor: 'darkmagenta',
      color: 'info',
    },
    {
      date: 'Sep 9 - Oct 11',
      icon: <SportsHandballIcon />,
      text: (
        <>
          <CheckCircleOutlineIcon color="info" /> League Matches
        </>
      ),
      textColor: 'darkmagenta',
      color: 'secondary',
    },
    {
      date: 'Oct 14 (15,16 Resv)',
      icon: <SportsTennisIcon />,
      text: 'Semifinals',
      color: 'warning',
      textColor: 'darkmagenta',
    },
    {
      date: 'Oct 16 (17 Resv)',
      icon: <MilitaryTechIcon />,
      text: 'Third-place match',
      color: 'secondary',
      textColor: 'darkmagenta',
    },
    {
      date: 'Oct 18 (19, 20 Resv)',
      icon: <EmojiEventsIcon color="warning" />,
      text: 'Finals',
      textColor: 'darkmagenta',
      color: 'grey',
    },
  ]

  return (
    <>
      <Timeline position="alternate">
        <Typography
          variant="h6"
          align="center"
          sx={{ fontWeight: 'bold' }}
          gutterBottom
        >
          RRTL Season 9 - Timelines
          <FormHelperText
            align="center"
            sx={{ color: 'darkmagenta', textAlign: 'center' }}
          >
            <sup>*</sup>Dates may be adjusted.
          </FormHelperText>
        </Typography>
        {timelineData.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              variant="body2"
              color={item.textColor || 'text.secondary'}
            >
              {item.date}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color={item.color}>{item.icon}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                {item.text}
              </Typography>
              {item.helperText && (
                <FormHelperText> {item.helperText}</FormHelperText>
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Alert
        severity="error"
        sx={{ textAlign: 'center', alignItems: 'center' }}
      >
        The RRTL committee has the right to change dates.
      </Alert>
    </>
  )
}
