import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../api/client'
import { getBaseUrl } from '../utils/utils'

const initialState = {
  playersData: [],
  status: 'idle',
  error: null,
}
const baseUrl = getBaseUrl()

const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    async playerUpdated(state, action) {
      // const { teamPlayers, teamCaptain, teamName } = action.payload;
      // const t = {
      //   teamPlayers,
      //   teamCaptain,
      //   teamName,
      // };
      // console.log(t);
      const response = await client.post(`${baseUrl}players`, 't')
      console.log('Response from backend: ', response.data)
      return response.data
    },
  },
  extraReducers(builder) {
    if (!fetchPlayers) return
    builder
      .addCase(fetchPlayers.pending, (state, action) => {
        console.log(' loding Action Payload .....')
        state.status = 'loading'
      })
      .addCase(fetchPlayers.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.playersData = state.playersData.concat(action.payload)
      })
      .addCase(fetchPlayers.rejected, (state, action) => {
        state.status = 'failed'
        console.log(
          ' failed Action Payload .....process.env.NODE_ENV ',
          process.env.NODE_ENV,
          '  rtret',
          getBaseUrl(),
        )
        state.error = action.error.message
      })
      .addCase(addNewPlayer.fulfilled, (state, action) => {
        state.playersData.push(action.payload)
      })
  },
})

export const fetchPlayers = createAsyncThunk(
  'players/fetchPlayers',
  async () => {
    const response = await client.get(`${baseUrl}players`)
    // const teams = await client.get(`${baseUrl}teams`);
    return response.data
  },
)

export const updatePlayer = createAsyncThunk(
  'players/updatePlayer',
  async (val) => {
    // const response = await client.get(
    //   "https://jsonplaceholder.typicode.com/todos"
    // );
    // console.log("Response from backend: ", response.data);
    // return response.data;
    return [{ playerName: 'Cary' }]
  },
)

// todo
export const addNewPlayer = createAsyncThunk(
  'players/addNewPlayer',
  async (initialPost) => {
    const response = await client.post('/fakeApi/posts', initialPost)
    return response.data
  },
)

export default playersSlice.reducer
// add/update players
export const { playerUpdated } = playersSlice.actions
// get players
export const selectAllPlayers = (state) => state.players
export const selectPostById = (state, postId) =>
  state.players.players.find((post) => post.id === postId)
