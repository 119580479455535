import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { AvatarGroup, Divider } from '@mui/material'
import useId from '@mui/utils/useId'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: 0,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  maxWidth: 600,
  marginTop: '0px',
  marginBottom: '8px',
  marginLeft: '-8px',
}))


export default function ScheduleAvatarTile({
  hostTeamPlayersId,
  guestTeamPlayersId,
  hostTeamName,
  guestTeamName,
  players,
  matchDate,
}) {
  const [avatarImgUrl, setAvatarImgUrl] = React.useState({
    host: [],
    guest: [],
  })
  const id  = useId()
  React.useEffect(() => {
    const hostAvatarUrls = players
      .filter((item) => hostTeamPlayersId?.includes(item.playerId))
      .map((item) => `${item.avatarImgUrl}?${id}/${hostTeamName}/${guestTeamName}`)

    const guestAvatarUrls = players
      .filter((item) => guestTeamPlayersId?.includes(item.playerId))
      .map((item) => `${item.avatarImgUrl}?${id}/${guestTeamName}/${hostTeamName}`)
    console.log({ hostAvatarUrls, hostTeamPlayersId })
    console.log({ guestAvatarUrls, guestTeamPlayersId })
    setAvatarImgUrl({ host: hostAvatarUrls, guest: guestAvatarUrls })
  }, [players])

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 1 }}>
      <Item
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
        }}
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack>
            <AvatarGroup total={2}>
              <Avatar src={avatarImgUrl.host[0]}></Avatar>
              <Avatar src={avatarImgUrl.host[1]}></Avatar>
            </AvatarGroup>
          </Stack>
          <Stack sx={{ minWidth: 2 }}>
            <Typography variant="caption" noWrap>
              {hostTeamName}
            </Typography>
            <Divider orientation="horizental" flexItem>
              Vs
            </Divider>
            <Typography variant="caption" noWrap>
              {guestTeamName}
            </Typography>
            <Typography variant="caption" noWrap>
              {matchDate}
            </Typography>
            {/* <Typography noWrap>Sep 27th (Wed) 08:30 PM</Typography> */}
            {/* <Typography noWrap>OSP park</Typography> */}
          </Stack>
          <Stack>
            <AvatarGroup total={2}>
              <Avatar src={avatarImgUrl.guest[0]}></Avatar>
              <Avatar src={avatarImgUrl.guest[1]}></Avatar>
            </AvatarGroup>
          </Stack>
        </Stack>
      </Item>
    </Box>
  )
}
