import { useAuth0 } from '@auth0/auth0-react'
import {
  Grid,
  makeStyles,
  Snackbar,
  Toolbar,
  Typography
} from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import { pink } from '@mui/material/colors'
import Paper from '@mui/material/Paper'
import { alpha, createTheme, styled, ThemeProvider } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makePostCall } from '../api/client'
import DaysCheckboxList from '../components/common/DayCheckList'
import FreeSoloCreatePlayer from '../components/common/FreeSoloCreatePlayer'
import { BasicModal } from '../utils/common.element'
import Elevation from './elevation'
import { fetchPlayers, selectAllPlayers } from './players.slice'

const darkTheme = createTheme({ palette: { mode: 'dark' } })
const lightTheme = createTheme({ palette: { mode: 'light' } })

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  minHeight: 60,
  fontWeight: 'bolder',
  lineHeight: '60px',
}))

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      marginTop: '0px',
      padding: '1px 1px',
      paddingRight: '1px',
      paddingLeft: '1px',
    },
    '& .MuiStack-root': {
      marginTop: '2px',
    },
    '& .MuiAlert-message': {
      marginTop: '0px',
    },
  },
  boxStyle: {
    width: '100%',
    border: '1px dashed lightcoral',
    borderLeft: '1px solid lightcoral',
    borderRight: '1px solid lightcoral',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    marginTop: '2px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 540,
    fontSize: '0.875rem',
    lineHeight: 2.8,
    backgroundColor: '#76e10e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}))

export const registHeader = () => (
  <ThemeProvider theme={lightTheme}>
    <Item
      key={'24-elevation'}
      elevation={8}
      sx={{ fontSize: '16px' }}
    >
      <Divider variant="middle" flexItem>
        {' '}
        RRTL - Fall 2024 Tournament [SEASON-09]: Registration
      </Divider>
    </Item>
  </ThemeProvider>
)
const LeaguePlayersRegistrationForm = () => {
  const initFormDataValues = {
    hasPartner: null,
    partnerChange: null,
    volunteerCommittee: '',
    volunteerUmpire: '',
    rulesComments: '',
    scheduleComments: '',
    daysCountPreference: null,
    travelCheck: false,
    teamName: null,
    favorableDays: [],
    unFavorableDays: [],
    datesRange1StartOutageDate: new Date('04-20-2024'),
    datesRange1EndOutageDate: new Date('04-20-2024'),
  }
  const [formData, setFormData] = useState(initFormDataValues)
  const dispatch = useDispatch()
  const { user } = useAuth0()
  const [loading, setLoading] = useState(true)
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const [players, setPlayers] = useState(null)
  const [dateRanges, setDateRanges] = useState(['datesRange1'])
  const [toastOpen, setToastOpen] = React.useState(false)
  const [isSubmitOkay, setIsSubmitOkay] = React.useState(false)
  const [openModel, setOpenModel] = React.useState(false)
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [submitButton, setSubmitButton] = React.useState({
    message: null,
    severity: null,
  })
  const [toastMessageInfo, setToastMessageInfo] = React.useState({
    message: null,
    severity: null,
  })
  const [yourNameInfo, setYourNameInfo] = React.useState({
    playerId: null,
    firstName: '',
    lastName: '',
    fullName: '',
  })
  const [partnerNameInfo, setPartnerNameInfo] = React.useState({
    playerId: null,
    firstName: '',
    lastName: '',
    fullName: '',
  })

  const playersStatus = useSelector((state) => state.players.status)
  const classes = useStyles()
  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
  }, [playersStatus, dispatch])

  useEffect(() => {
    let isOkayCheck = true
    const {
      daysCountPreference,
      favorableDays,
      unFavorableDays,
      hasPartner,
      partnerChange,
      volunteerCommittee,
      volunteerUmpire,
      teamName,
    } = formData
    if (
      !daysCountPreference ||
      !hasPartner ||
      !yourNameInfo ||
      !volunteerCommittee ||
      !volunteerUmpire
    ) {
      isOkayCheck = false
    }
    if (favorableDays.length < 1 && unFavorableDays.length < 1) {
      isOkayCheck = false
    }
    if (
      hasPartner?.toLowerCase() === 'yes' &&
      (!partnerNameInfo || !partnerChange || !teamName)
    ) {
      isOkayCheck = false
    }
    setIsSubmitOkay(isOkayCheck)
  }, [formData])

  useEffect(() => {
    if (loading) return
    setPlayers(allPlayers)
  }, [loading])

  useEffect(() => {
    if (playersStatus === 'succeeded') {
      const findP = allPlayers.find((p) => user?.email === p.playerId)
      setIsAdmin(findP?.admin)
      setLoading(false)
    }
  }, [playersStatus])

  useEffect(() => {
    if (submitButton.message) {
      setToastMessageInfo({
        message: submitButton.message,
        severity: submitButton.severity,
      })
      setToastOpen(true)
      setFormData(initFormDataValues)
    }
  }, [submitButton])
  // For formData.hasPartner === 'No'
  useEffect(() => {
    if (formData.hasPartner === 'No') {
      setToastMessageInfo({
        message: `We'll do our best to find options for you, and you'll have the chance to choose from available players.`,
        severity: 'info',
      })
      setToastOpen(true)
    } else {
      setToastOpen(false)
    }
  }, [formData.hasPartner])

  useEffect(() => {
    if (formData.volunteerCommittee === 'Yes') {
      setToastMessageInfo({
        message: `Thank you for volunteering for the committee. RRTL management will be in touch with you soon.`,
        severity: 'info',
      })
      setToastOpen(true)
    } else {
      setToastOpen(false)
    }
  }, [formData.volunteerCommittee])

  useEffect(() => {
    if (formData.volunteerUmpire === 'Yes') {
      setToastMessageInfo({
        message: `Thank you for volunteering for the umpiring. RRTL management will be in touch when needed.`,
        severity: 'info',
      })
      setToastOpen(true)
    } else {
      setToastOpen(false)
    }
  }, [formData.volunteerUmpire])

  // For formData.daysCountPreference === 'oneDaysOffWeek'
  useEffect(() => {
    if (formData.daysCountPreference === 'oneDaysOffWeek') {
      setToastMessageInfo({
        message:
          'Select more than one day for flexibility, as completing all on time might be difficult.',
        severity: 'warning',
      })
      setToastOpen(true)
    } else if (formData.daysCountPreference !== 'threePlusDaysOffWeek') {
      setToastOpen(false)
    }
  }, [formData.daysCountPreference])

  // For formData.daysCountPreference === 'threePlusDaysOffWeek'
  useEffect(() => {
    if (formData.daysCountPreference === 'threePlusDaysOffWeek') {
      setToastMessageInfo({
        message: `Thank you for your flexibility. We'll schedule as many matches as possible for you.`,
        severity: 'success',
      })
      setToastOpen(true)
    } else if (formData.daysCountPreference !== 'oneDaysOffWeek') {
      setToastOpen(false)
    }
  }, [formData.daysCountPreference])

  useEffect(() => {
    if (formData.datesRange3EndOutageDate) {
      setToastMessageInfo({
        message: `Having too many unavailabilities may make it difficult to manage your match schedule.`,
        severity: 'warning',
      })
      setToastOpen(true)
    }
  }, [formData.datesRange3EndOutageDate])

  // For formData.unFavorableDays
  useEffect(() => {
    // if (formData?.unFavorableDays?.length === 5) {
    //   setToastMessageInfo({
    //     message:
    //       'Select more than three day of week for flexibility, as completing all on time might be difficult.',
    //     severity: 'warning',
    //   })
    //   setToastOpen(true)
    // }
    if (
      (formData?.favorableDays.length > 1 ||
        formData?.unFavorableDays.length > 1) &&
      (formData?.unFavorableDays?.length > 5 ||
        (formData?.unFavorableDays?.length >= 4 &&
          formData?.favorableDays?.length < 3))
    ) {
      const msg =
        formData?.unFavorableDays?.length > 4
          ? 'Select less than 5 un-favorable'
          : formData?.favorableDays?.length < 4
          ? 'Select more than 3 favorable'
          : 'Select more favorable'
      setToastMessageInfo({
        message: `${msg} days of week for flexibility, as completing all on time might be difficult.`,
        severity: msg === 'Select more than 3 favorable' ? 'info' : 'warning',
      })
      setToastOpen(true)
    }
  }, [formData.unFavorableDays, formData.favorableDays])

  const [value, setValue] = React.useState('one')
  const [playerFirstName, setPlayerFirstName] = React.useState('')
  const [playerLastName, setPlayerLastName] = React.useState('')

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleAddDateRange = () => {
    const newIndex = dateRanges.length + 1
    setDateRanges([...dateRanges, `datesRange${newIndex}`])
    setFormData((prevData) => ({
      ...prevData,
      [`datesRange${newIndex}StartOutageDate`]: new Date(
        `04-${20 + newIndex}-2024`,
      ),
      [`datesRange${newIndex}EndOutageDate`]: new Date(
        `04-${20 + newIndex}-2024`,
      ),
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    console.log(' ..... ', { name, value })
    if (name === 'hasPartner' && value == 'No') {
      setPartnerNameInfo({
        playerId: null,
        firstName: '',
        lastName: '',
        fullName: '',
      })
      setFormData((prevData) => ({
        ...prevData,
        ['teamName']: null,
      }))
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSwift = (e, type) => {
    console.log('***', e.target.checked)
    setFormData((prevData) => ({
      ...prevData,
      [type]: e.target.checked,
    }))
  }
  const handleOnSubmit = (e) => {
    e.preventDefault()
    const playersSubmitData = formData
    playersSubmitData['ownerPlayerInfo'] = {
      id: yourNameInfo?.id,
      fullName: yourNameInfo?.fullName,
      firstName: yourNameInfo?.firstName,
      lastName: yourNameInfo?.lastName,
    }
    playersSubmitData['partnerPlayerInfo'] = {
      id: partnerNameInfo?.id,
      fullName: partnerNameInfo?.fullName,
      firstName: partnerNameInfo?.firstName,
      lastName: partnerNameInfo?.lastName,
    }
    playersSubmitData['isActive'] = true
    makePostCall(
      playersSubmitData,
      'challenge',
      null,
      setSubmitButton,
      true,
      'Your Spring 2024 league form, ',
    )
    setOpenModel(true)
  }
  const playerSelection = (player, title) => (
    <Box>
      {players?.length > 0 && (
        <Autocomplete
          id="player-autocomplete"
          freeSolo
          options={players}
          getOptionLabel={(player) => player.fullName}
          onChange={(event, newValue) => {
            setFormData({
              ...formData,
              [`${player}PlayerFullName`]: newValue?.fullName,
              [`${player}PlayerId`]: newValue?.id,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="given-name"
              name="fullName"
              required
              fullWidth
              id={`${title}-fullName`}
              label={title}
              autoFocus
            />
          )}
        />
      )}
    </Box>
  )

  const dateRangeElement = (counter, index) => {
    const color =
      index === 0
        ? 'orange'
        : index === 1
        ? 'lightcoral'
        : index === 2
        ? '#c4421b'
        : 'red'
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        id={`${counter}-${index}-key-local`}
      >
        <Divider variant="middle" sx={{ color, fontWeight: 'bolder' }} flexItem>
          Dates Range {index + 1}
        </Divider>
        <Stack direction="row" container spacing={1}>
          <DatePicker
            clearable
            disablePast
            minDate={new Date('04-20-2024')}
            maxDate={new Date('06-15-2024')}
            value={formData[`${counter}StartOutageDate`]}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                [`${counter}StartOutageDate`]: e,
              }))
            }
            renderInput={(params) => (
              <TextField fullWidth {...params} helperText="From date" />
            )}
          />

          <DatePicker
            clearable
            fullWidth
            disablePast
            minDate={formData[`${counter}StartOutageDate`]}
            maxDate={new Date('06-15-2024')}
            value={formData[`${counter}EndOutageDate`]}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                [`${counter}EndOutageDate`]: e,
              }))
            }
            renderInput={(params) => (
              <TextField fullWidth {...params} helperText="To date" />
            )}
          />
        </Stack>
      </LocalizationProvider>
    )
  }

  const partnerCheck = () => (
    <Alert
      icon={false}
      severity="info"
      sx={{
        width: '100%',
        height: '100%',
      }}
      variant="outlined"
    >
      <AlertTitle>Do you have a playing partner?</AlertTitle>
      <RadioGroup
        row
        name="hasPartner"
        value={formData.hasPartner}
        onChange={handleChange}
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
      </RadioGroup>
    </Alert>
  )
  const partnerChangeCheck = () => (
    <Alert
      icon={false}
      severity="info"
      sx={{
        width: '100%',
        height: '100%',
      }}
      variant="outlined"
    >
      Open to changing a partner if requested?
      <RadioGroup
        row
        aria-label="partnerChange"
        name="partnerChange"
        value={formData.partnerChange}
        onChange={handleChange}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel
          value="no"
          control={<Radio />}
          label="Absolutely Not"
        />
        <FormControlLabel
          value="possibly"
          control={<Radio />}
          label="Possibly - depends on choices"
        />
      </RadioGroup>
    </Alert>
  )
  const daysSelectionsSection = () => (
    <Alert
      icon={false}
      severity="info"
      sx={{
        width: '100%',
        height: '100%',
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
      }}
      variant="outlined"
    >
      <Divider textAlign="center" variant="middle" sx={{ marginBottom: '6px' }}>
        Your Weekly Schedule?
      </Divider>

      <Stack direction="row" container spacing={1}>
        <DaysCheckboxList
          title={'Favorable  days'}
          formData={formData}
          setFormData={setFormData}
          isPreferred
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <DaysCheckboxList
          title={'Unfavored days'}
          formData={formData}
          setFormData={setFormData}
        />
      </Stack>
    </Alert>
  )
  const getBoxContent = (content) => (
    <Box className={classes.boxStyle}>{content}</Box>
  )
  const swiftTogle = (title, name) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <PinkSwitch
          onChange={(e) => handleSwift(e, name)}
          value={formData[name]}
          name={name}
          inputProps={{ 'aria-label': 'travel check' }}
        />
        <Typography>{title}</Typography>
      </Stack>
    )
  }
  const daysCountPreference = () => (
    // <Paper elevation={24} sx={{ padding: 0 }}>
    <Alert
      icon={false}
      severity="info"
      sx={{
        width: '100%',
        height: '100%',
      }}
      variant="outlined"
    >
      <Divider textAlign="center" variant="middle">
        {' '}
        Days per Week?
      </Divider>
      <RadioGroup
        row
        name="daysCountPreference"
        value={formData.daysCountPreference}
        onChange={handleChange}
      >
        <FormControlLabel
          value="oneDaysOffWeek"
          control={<Radio />}
          label="1 day - per week"
        />
        <FormControlLabel
          value="twoDaysOffWeek"
          control={<Radio />}
          label="2 days - per week"
        />
        <FormControlLabel
          value="threeDaysOffWeek"
          control={<Radio />}
          label="3 days - per week"
        />
        <FormControlLabel
          value="threePlusDaysOffWeek"
          control={<Radio />}
          label="3+ days - per week"
        />
      </RadioGroup>
      {helperText(
        "We'll aim to meet your preferences. Typically, you'll play 3 matches every 2 weeks.",
      )}
    </Alert>
    // </Paper>
  )
  const helperText = (text) => (
    <FormHelperText
      id="component-helper-text"
      sx={{
        letterSpacing: 0,
      }}
    >
      {text}
    </FormHelperText>
  )
  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    // setToastMessageInfo({ message: null, severity: null })
    setToastOpen(false)
  }
  const finalModel = BasicModal(
    openModel,
    {
      severity: submitButton.severity,
      message: submitButton.message,
    },
    'Thank you for registering for the Spring 2024 league. We look forward to seeing you on the courts!',
    setOpenModel,
  )

  const teamFeild = () => (
    <>
      {!formData.daysCountPreference && getBoxContent('Team Name')}
      <TextField
        error={!formData.teamName}
        sx={{ marginTop: '8px', marginBottom: '6px' }}
        fullWidth
        disabled={!partnerNameInfo.firstName || !yourNameInfo.firstName}
        name={'teamName'}
        onChange={handleChange}
        id="standard-error-helper-text"
        label="Please enter your team name"
        variant="outlined"
        value={formData['teamName']}
      />
    </>
  )
  const feedBackTabs = (
    <Alert
      icon={false}
      severity="info"
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
      }}
      variant="outlined"
    >
      <Tabs
        value={value}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="one" label="Rules" wrapped />
        <Tab value="two" label="Schedule" />
        <Tab value="three" label="General" />
      </Tabs>
    </Alert>
  )
  const feedBackfields = (
    <Card
      icon={false}
      severity="info"
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
      }}
      variant="outlined"
    >
      {value === 'one' && (
        <>
          <TextField
            sx={{ mt: '6px' }}
            fullWidth
            label="Suggestion for Rules"
            name="rulesComments"
            multiline
            rows={4}
            value={formData.rulesComments}
            onChange={handleChange}
          />
          <FormHelperText id="component-helper-text">
            The best feedback is what we don’t want to hear. – George Raveling
          </FormHelperText>
        </>
      )}
      {value === 'two' && (
        <>
          <TextField
            sx={{ mt: '6px' }}
            fullWidth
            label="Suggestion for Schedule"
            name="scheduleComments"
            multiline
            rows={4}
            value={formData.scheduleComments}
            onChange={handleChange}
          />
          <FormHelperText id="component-helper-text">
            Your feedback is crucial. Share your thoughts and shape the RRTL.
          </FormHelperText>
        </>
      )}
      {value === 'three' && (
        <>
          <TextField
            sx={{ mt: '6px' }}
            fullWidth
            label="Ideas for committee,food, anything ..!"
            name="generalComments"
            multiline
            rows={4}
            value={formData.generalComments}
            onChange={handleChange}
          />
          <FormHelperText id="component-helper-text">
            Feedback: The secret ingredient for a recipe of success.
          </FormHelperText>
        </>
      )}
      <Divider variant="middle">
        <SentimentSatisfiedAltIcon color="success"></SentimentSatisfiedAltIcon>
      </Divider>
    </Card>
  )
  const volunteerUmpireField = (
    <Alert
      icon={false}
      severity="info"
      sx={{
        width: '100%',
        height: '100%',
      }}
      variant="outlined"
    >
      Volunteer to be final/semi-final umpire?
      <RadioGroup
        row
        name="volunteerUmpire"
        value={formData.volunteerUmpire}
        onChange={handleChange}
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
        <FormControlLabel
          value="can"
          control={<Radio />}
          label="If nobody else available"
        />
      </RadioGroup>
    </Alert>
  )
  const volunteerCommitteeField = (
    <Alert
      icon={false}
      severity="info"
      sx={{
        width: '100%',
        height: '100%',
      }}
      variant="outlined"
    >
      Volunteer to be committee member?
      <RadioGroup
        row
        name="volunteerCommittee"
        value={formData.volunteerCommittee}
        onChange={handleChange}
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
        <FormControlLabel value="can" control={<Radio />} label="If needed" />
      </RadioGroup>
    </Alert>
  )

  const travelCheckFields = (
    <Card
      icon={false}
      severity="info"
      sx={{
        backgroundColor: '#fff',
      }}
    >
      {swiftTogle(
        'Do you have any travel or out-of-town plans?',
        'travelCheck',
      )}

      {formData.travelCheck && dateRanges.map((d, i) => dateRangeElement(d, i))}
      {formData.travelCheck && (
        <FormHelperText
          fullWidth
          id="component-helper-text"
          sx={{
            margin: '0 8px',
            padding: '8px',
            border: '.5px dashed lightcoral',
            borderLeft: 'none',
            borderRight: 'none',
          }}
        >
          Please select your unavailable days.i.e, "4/25/2024 to 4/29/2024"
          indicates 6 days unavailable.
        </FormHelperText>
      )}
      {formData.travelCheck && (
        <AlertTitle sx={{ mt: '10px' }}>
          <Chip
            icon={<AddIcon />}
            label="Add more unavailable date range"
            color="info"
            variant="outlined"
            onClick={handleAddDateRange}
          />
          <FormHelperText
            id="component-helper-text"
            sx={{
              letterSpacing: 0,
            }}
          >
            <Divider />
            {`We'll work to avoid scheduling matches during your unavailable
              dates range.`}
            <Divider textAlign="center" variant="middle">
              {' '}
              <WarningAmberIcon
                sx={{
                  fontSize: '3rem',
                  width: '0.35em',
                  height: '0.35em',
                }}
                color="warning"
              />
            </Divider>
            {`Excessive unavailability could impact your
              participation in the league.`}
          </FormHelperText>
        </AlertTitle>
      )}
    </Card>
  )
  const pageView = (
    <>
      <Elevation contentFun={registHeader}></Elevation>
      <Alert severity="info" icon={false}>
        <AlertTitle>
          <b>League Matches:&nbsp;&nbsp;</b>
          <span style={{ fontWeight: 'bold', color: '#db13ef' }}>
            April 20th - May 31st
          </span>
          {` at Old Settler Park courts (8:30 pm).`}
        </AlertTitle>
        <VolunteerActivismIcon
          fontSize="small"
          color="success"
        ></VolunteerActivismIcon>
        Teams may mutually decide to use other venues.
      </Alert>
      {!isAdmin && (
        <Elevation
          contentFun={() => (
            <Button
              href="/challenge-matches/list"
              size="large"
              color="warning"
              variant="outlined"
              fullWidth
            >
              Click for submitted players
            </Button>
          )}
        ></Elevation>
      )}
      <Paper elevation={24} sx={{ padding: 0 }}>
        {!loading && allPlayers && (
          <>
            {getBoxContent(
              yourNameInfo?.firstName && partnerNameInfo?.firstName
                ? 'Players information'
                : 'Your information!',
            )}
            <Stack
              spacing={2}
              sx={{ marginTop: '10px' }}
              className={classes.root}
            >
              <FreeSoloCreatePlayer
                players={allPlayers}
                playerNameInfo={yourNameInfo}
                setPlayerNameInfo={setYourNameInfo}
                self
              ></FreeSoloCreatePlayer>
            </Stack>
          </>
        )}
        {yourNameInfo?.fullName && (
          <Stack sx={{ margin: 0, padding: 0 }}>{partnerCheck()}</Stack>
        )}
        {formData.hasPartner === 'Yes' && (
          <>
            {!partnerNameInfo?.firstName &&
              getBoxContent('Your partner information!')}
            <Stack
              spacing={2}
              sx={{ marginTop: '10px' }}
              className={classes.root}
            >
              <FreeSoloCreatePlayer
                players={allPlayers}
                playerNameInfo={partnerNameInfo}
                setPlayerNameInfo={setPartnerNameInfo}
              ></FreeSoloCreatePlayer>
            </Stack>
            {partnerNameInfo?.firstName && partnerChangeCheck()}
            {formData.partnerChange && teamFeild()}
          </>
        )}
      </Paper>
      {yourNameInfo?.firstName &&
        (formData.hasPartner === 'No' || partnerNameInfo.firstName) && (
          <Paper elevation={24} sx={{ padding: 0 }}>
            <Stack
              spacing={2}
              sx={{ margin: '0px', marginTop: '1px' }}
              className={classes.root}
            >
              {getBoxContent(
                'Days choices: Will factor into your match scheduling.',
              )}
              {daysCountPreference()}
              {daysSelectionsSection()}
            </Stack>

            <Stack sx={{ margin: 0, padding: 0 }}>
              {getBoxContent(
                'Out-of-town/unavailable: Dates range from 4/20 to 6/15',
              )}
              {travelCheckFields}
              {getBoxContent('Volunteering: Makes a big difference!')}
              {volunteerCommitteeField}
              {volunteerUmpireField}
              {getBoxContent(
                'Share your thoughts: Suggestions, Ideas & Comments',
              )}
              {feedBackTabs}
              {feedBackfields}
            </Stack>
          </Paper>
        )}
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity={toastMessageInfo.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {toastMessageInfo.message}
        </Alert>
      </Snackbar>
      {finalModel}
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={5}>
              <b>RRTL Spring 2024</b>
            </Grid>
            <Grid item xs={3}>
              <Button
                disabled={!isSubmitOkay}
                variant="contained"
                color="warning"
                onClick={handleOnSubmit}
                fullWidth
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <b>8th Season</b>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )

  return pageView
}

export default LeaguePlayersRegistrationForm
