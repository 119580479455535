import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { autoPlay } from 'react-swipeable-views-utils'
import SwipeableViews from 'react-swipeable-views'
import ElevationTile from '../components/Tiles/ElevationTile'
import MdPhone from '@mui/icons-material/Phone';
import { Chip, Link } from '@mui/material'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const messageTiles = [
    <ElevationTile
        href="/contactus"
        tileContent={  <Chip  color="primary" variant="outlined" href="/contactus" icon={<MdPhone />} label="Contact Us: For coming Winter Season 2024" />}
    mode="light"
  />,
  <ElevationTile
    tileContent={'Spring 2024 Champions 🏆: Asif/Badar'}
    mode="light"
  />,
  <ElevationTile
    tileContent={'Summer 2023 Champions 🏆: Umair/Asim'}
    mode="dark"
  />,
  <ElevationTile
    tileContent={'Spring 2023 Champions 🏆: Badar/Asif'}
    mode="light"
  />,
  <ElevationTile
    tileContent={'Summer 2022 Champions 🏆: Zulfi/Badar'}
    mode="dark"
  />,
  <ElevationTile tileContent={'Join us, Be part of us'} mode="dark" />,
]

function HomeBanner() {
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = messageTiles.length

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  return (
    // <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
    <AutoPlaySwipeableViews
      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      index={activeStep}
      onChangeIndex={handleStepChange}
      interval={5000} // Adjust interval to control auto-play speed (in ms)
      animateTransitions={true} // Enable/disable transitions
      enableMouseEvents
    >
      {messageTiles.map((tile, index) => (
        <div key={index}>
          {Math.abs(activeStep - index) <= 2 ? (
            <Box
              component="section"
              sx={{
                pt: 0.2,
                pb: 0.2,
                pl: 0,
                pr: 0,
                border: '.2px solid blue',
              }}
            >
              {tile}
            </Box>
          ) : null}
        </div>
      ))}
    </AutoPlaySwipeableViews>
    // </Box>
  )
}

export default HomeBanner
