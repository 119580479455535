import { useAuth0 } from '@auth0/auth0-react'
import GroupIcon from '@mui/icons-material/Group'
import LoginIcon from '@mui/icons-material/Login'
import Autocomplete from '@mui/material/Autocomplete'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { makePostCall } from '../api/client'
import { Spinner } from '../components/Spinner'
import { fetchPlayers, selectAllPlayers } from '../players/players.slice'
import { alertMessage, Copyright } from '../utils/common.element'
import { MATCHES_TYPES } from '../utils/constants'


const theme = createTheme()

export default function TournamentPlayerEnrollment() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)

  const error = useSelector((state) => state.players.error)
  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [comment, setComment] = useState(null)
  const activeLeagues = MATCHES_TYPES.filter((t) => t.active === true).map(
    (activeType) => activeType.value,
  )
  const [leaguesSelection, setLeaguesSelection] = useState([activeLeagues[0]])
  const [submitButton, setSubmitButton] = useState({
    message: 'Submit players',
    severity: null,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
  }, [playersStatus, dispatch])

  const handlePlayerSelections = (e, v) => {
    const selectedPlayers = v.map((p) => {
      return { id: p.id, fullName: p.fullName }
    })
    setSelectedPlayers(selectedPlayers)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const body = {
      players: selectedPlayers,
      league: leaguesSelection,
      comment,
      isTournamentSignup: true,
    }
    makePostCall(
      body,
      'challenge',
      null,
      setSubmitButton,
      true,
      'Players Enrollment',
    )
  }

  const leaguesSelectionInputElement = () => {
    return (
      <>
        <Autocomplete
          fullWidth
          multiple
          id="multiple-limit-tags"
          options={activeLeagues}
          disabled
          getOptionLabel={(option) => option || ''}
          defaultValue={[activeLeagues[0]]}
          onChange={(e, v) => setLeaguesSelection(v)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Interested in joining"
              name="tournamentName"
              placeholder="Tournament Name"
            />
          )}
        />
        <FormHelperText id="matches-options-helper-text">
          Please select all the options you wish to join.
        </FormHelperText>
      </>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      {playersStatus === 'loading' && (
        <>
          <Spinner text="Loading players data for teams..." />
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </>
      )}
      {playersStatus === 'error' && (
        <Spinner text={`Something went wrong... ${error}`} />
      )}
      {playersStatus === 'succeeded' && (
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <GroupIcon />
            </Avatar>
            <>
              <Typography component="h1" variant="h5">
                Register players for Spring 2024 tournament
              </Typography>
              <FormHelperText id="component-helper-text">
                <b>
                  {' '}
                  If a player does not appear in the search dropdown, then you
                  need to register them first.
                </b>
                <Button
                  color="secondary"
                  href="/players/sign-up"
                  aria-label="sign up"
                  sx={{ fontSize: '0.8rem' }}
                >
                  {' '}
                  Register
                  <LoginIcon href="/players/sign-up" />
                </Button>
              </FormHelperText>
            </>
          </Box>
          {allPlayers && (
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={allPlayers}
                    getOptionLabel={(option) => option.fullName}
                    onChange={(e, value) => handlePlayerSelections(e, value)}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select players for Spring 2024"
                        placeholder="Select players"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  {leaguesSelectionInputElement()}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Please share your thoughts or suggestions."
                    variant="outlined"
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <FormHelperText id="matches-options-helper-text">
                    Add something like, "We would prefer to play on the same
                    team" etc.
                  </FormHelperText>
                </Grid>
              </Grid>
              {submitButton.severity &&
                alertMessage(
                  submitButton.severity,
                  submitButton.message,
                  navigate,
                )}
              {submitButton.severity !== 'success' && (
                <Button
                  onClick={handleSubmit}
                  fullWidth
                  disabled={
                    selectedPlayers.length === 0 ||
                    submitButton.severity === 'success'
                  }
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit Players
                  <GroupIcon
                    mr="3"
                    color={
                      selectedPlayers.length === 0 ||
                      submitButton.severity === 'success'
                        ? 'default'
                        : 'warning'
                    }
                  />
                </Button>
              )}
            </Box>
          )}
          <Copyright sx={{ mt: 5 }} />
        </Container>
      )}
    </ThemeProvider>
  )
}
