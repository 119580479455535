import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Grid from '@mui/material/Grid'
import { Fab, Typography } from '@mui/material'
import Login from '@mui/icons-material/Login'

function SignIn() {
  const { loginWithPopup } = useAuth0()

  return (
    <main style={{ padding: '1rem 0' }}>
      <Grid container>
        <Grid container justifyContent="center">
          {/* <Button variant="contained" onClick={loginWithRedirect} fullWidth>
            Sign In
          </Button> */}
          <Typography component="h1" variant="h5">
            You first need to login to register team.
          </Typography>
          <Fab variant="extended" onClick={loginWithPopup} color="success">
            <Login sx={{ mr: 1 }} />
            Log in
          </Fab>
        </Grid>
      </Grid>
    </main>
  )
}

export default SignIn
