import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Link from '@mui/material/Link'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './home/home'
import SignUpTeam from './teams/team.signup'
import SignUpPlayer from './players/player.submit'
import PlayersList from './players/player.profile.list'
import PlayerCardWrapper from './players/Player.card.wrapper'
import HomeIcon from '@mui/icons-material/Home'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SportsTennisIcon from '@mui/icons-material/SportsTennis'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import PostAddIcon from '@mui/icons-material/PostAdd'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import UpdateIcon from '@mui/icons-material/Update'
import DateRangeIcon from '@mui/icons-material/DateRange'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import SummarizeIcon from '@mui/icons-material/Summarize'
import SportsHandballIcon from '@mui/icons-material/SportsHandball'
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi'
import FitbitIcon from '@mui/icons-material/Fitbit'
import ExposureIcon from '@mui/icons-material/Exposure'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { useAuth0 } from '@auth0/auth0-react'

import TeamsSummary from './teams/teams.summary'
import EditTeam from './teams/team.edit'
import { ScheduleSubmit } from './matches/schedule/schedule.submit'
import { ScheduleSubmitV2 } from './matches/schedule/schedule.submit.v2'
import { ScheduleList } from '../src/matches/schedule/schedule.list'
import { ResultSubmit } from './matches/results/results.submit'
import { ResultsList } from './matches/results/results.list'
import SignIn from './auth/SignIn'
import Profile from './auth/profile'
import { Button } from '@mui/material'
import RuleSubmit from './rules/rules.submit'
import RulesView from './rules/rules.view'
import { getWindowDimensions } from './utils/utils'
import PointsTable from './matches/points.table/points.table.view'
import TopPlayers from './players/championships.players'
import { PhotoGallery } from './components/photo-gallory'
import AppTopRelated from './players/player.ranking.summary'
import PlayersProfile from './profiles/ProfileFollowers'
import PlayersCard from './profiles/PlayersCard'
import ChampionshipsList from './components/championships/championships.list'
import ImagesUpload from './components/ImagesUpload'
import RegistrationForChallengeMatches from './matches/ChallengeMatches/challenge-matches.registration'
import EventCountdown from './components/news-alerts/EventCountdown'
import ChallengeMatchesList from './matches/ChallengeMatches/challenge-matches.list'
import PlayerEnrollment from './teams/players.enrollment'
import LeaguePlayersRegistrationForm from './players/league-players-registration-form'
import LeagueSubmit from './leagues/league-submit'
import ContactUs from './components/common/ContactUs/ContactUs'
import VolunteerRegistrationForm from './comittee/committe-regis'
import CommitteeRegList from './leagues/committee-reg-list'
import TournamentTeamRegis from './players/TournamentTeamRegis'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0.1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export default function PersistentDrawerLeft() {
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [collapse, setCollapse] = React.useState(false)
  const [teamsCollapse, setTeamsCollapse] = React.useState(false)
  const [matchesCollapse, setMatchesCollapse] = React.useState(false)
  const [scheduleCollapse, setScheduleCollapse] = React.useState(false)
  const [generalCollapse, setGeneralCollapse] = React.useState(false)
  const [championshipCollapse, setChampionshipCollapse] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handlePlayers = () => {
    setCollapse(!collapse)
  }
  const { width } = getWindowDimensions()
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant={width > 500 ? 'h6' : 'string'}
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Round Rock Tennis League
          </Typography>
          <Button
            color="inherit"
            size={width > 500 ? 'medium' : 'small'}
            fontSize="small"
            onClick={isAuthenticated ? logout : loginWithRedirect}
          >
            {isAuthenticated ? `${user?.nickname}-Log out` : 'Log In'}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          // subheader={
          //   <ListSubheader component="div" id="nested-list-subheader">
          //     Home
          //   </ListSubheader>
          // }
        >
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon fontSize="large" color="success" />
            </ListItemIcon>
            <Link href="/">
              <ListItemText primary="Home" />
            </Link>
          </ListItemButton>

          <ListItemButton onClick={handlePlayers}>
            <ListItemIcon>
              <PeopleAltIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Players" />
            {collapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={collapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <PersonAddAlt1Icon color="error" />
                </ListItemIcon>
                <Link href="/players/sign-up">
                  <ListItemText primary="Register Player" />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <SportsTennisIcon color="warning" />
                </ListItemIcon>
                <Link href="/players/all">
                  <ListItemText primary="Players List" />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={(e) => setTeamsCollapse(!teamsCollapse)}>
            <ListItemIcon>
              <SportsKabaddiIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Teams" />
            {teamsCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={teamsCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AppRegistrationIcon color="success" />
                </ListItemIcon>
                <Link href="/teams/sign-up">
                  <ListItemText primary="Register Team" />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <SummarizeIcon />
                </ListItemIcon>
                <Link href="/teams">
                  <ListItemText primary="Teams List" />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={(e) => setMatchesCollapse(!matchesCollapse)}>
            <ListItemIcon>
              <SportsHandballIcon color="error" />
            </ListItemIcon>
            <ListItemText primary="Matches" />
            {matchesCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={matchesCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <CreditScoreIcon color="warning" />
                </ListItemIcon>
                <Link href="/result/list">
                  <ListItemText primary="Results" />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <PostAddIcon color="secondary" />
                </ListItemIcon>
                <Link href="/result/submit">
                  <ListItemText primary="Submit Result" />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <ExposureIcon color="success" />
                </ListItemIcon>
                <Link href="/result/points-table">
                  <ListItemText primary="Points Table" />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={(e) => setScheduleCollapse(!scheduleCollapse)}
          >
            <ListItemIcon>
              <UpdateIcon color="error" />
            </ListItemIcon>
            <ListItemText primary="Schedule" />
            {scheduleCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={scheduleCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <DateRangeIcon color="info" />
                </ListItemIcon>
                <Link href="/schedule/list">
                  <ListItemText primary="Coming Matches" />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <Link href="/schedule/submit">
                  <ListItemText primary="Create Schedule" />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <TurnedInNotIcon />
                </ListItemIcon>
                <Link href="/league/submit">
                  <ListItemText primary="League Submit" />
                </Link>
              </ListItemButton>
              {/* <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <Link href="/auth/signIn">
                  <ListItemText primary="Sign In" />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <Link href="/auth/profile">
                  <ListItemText primary="User Profile" />
                </Link>
              </ListItemButton> */}
            </List>
          </Collapse>

          <ListItemButton onClick={(e) => setGeneralCollapse(!generalCollapse)}>
            <ListItemIcon>
              <FitbitIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="General" />
            {generalCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={generalCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorderIcon color="warning" />
                </ListItemIcon>
                <Link href="/rules">
                  <ListItemText primary="Rules" />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarHalfIcon color="success" />
                </ListItemIcon>
                <Link href="/rules/submit">
                  <ListItemText primary="Create Rule" />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          <Collapse in={generalCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorderIcon color="warning" />
                </ListItemIcon>
                <Link href="/images/list">
                  <ListItemText primary="Photos" />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          <Collapse in={generalCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorderIcon color="warning" />
                </ListItemIcon>
                <Link href="/images/upload">
                  <ListItemText primary="Upload Photos" />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          {/* Championshipt */}
          <ListItemButton
            onClick={(e) => setChampionshipCollapse(!generalCollapse)}
          >
            <ListItemIcon>
              <EmojiEventsIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Championships" />
            {championshipCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={championshipCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <EmojiEventsIcon color="info" />
                  <EmojiEventsIcon color="primary" />
                </ListItemIcon>
                <Link href="/championships/list">
                  <ListItemText primary="Champ... List" />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <EmojiEventsIcon color="success" />
                </ListItemIcon>
                <Link href="/championship/submit">
                  <ListItemText primary="Add Champ..." />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          <Collapse in={generalCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorderIcon color="warning" />
                </ListItemIcon>
                <Link href="/images/list">
                  <ListItemText primary="Photos" />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
            <ListItemButton sx={{ pl: 2 }}>
                <ListItemIcon>
                  <ContactPhoneIcon color="success" />
                </ListItemIcon>
                <Link href="/contactus">
                  <ListItemText primary="Contact Us" />
                </Link>
              </ListItemButton>
            <ListItemButton sx={{ pl: 2 }}>
                <ListItemIcon>
                  <ContactPhoneIcon color="success" />
                </ListItemIcon>
                <Link href="/committee/registration">
                  <ListItemText primary="Committee Regis" />
                </Link>
              </ListItemButton>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/auth/signIn" element={<SignIn />}></Route>
            <Route path="/auth/profile" element={<Profile />}></Route>
            <Route path="/result/list" element={<ResultsList />}></Route>
            <Route path="/result/submit" element={<ResultSubmit />}></Route>
            <Route
              path="/result/points-table"
              element={<PointsTable />}
            ></Route>

            <Route path="/schedule/submit" element={<ScheduleSubmit />}></Route>
            <Route
              path="/schedule/submit/v2"
              element={<ScheduleSubmitV2 />}
            ></Route>
            <Route path="/schedule/list" element={<ScheduleList />}></Route>

            <Route path="/rules" element={<RulesView />}></Route>
            <Route path="/rules/submit" element={<RuleSubmit />}></Route>

            <Route path="/teams" element={<TeamsSummary />}></Route>
            <Route path="/teams/sign-up" element={<SignUpTeam />}></Route>
            <Route path="/teams/edit" element={<EditTeam />}></Route>
            <Route path="/players/sign-up" element={<SignUpPlayer />}></Route>
            <Route path="/images/list" element={<PhotoGallery />}></Route>
            <Route path="/images/upload" element={<ImagesUpload />}></Route>
            <Route path="/players/top-players" element={<TopPlayers />}></Route>
            <Route
              path="/players/profiles"
              element={<PlayersProfile />}
            ></Route>
            <Route path="/players/all" element={<PlayersCard />}></Route>
            <Route path="/players/:playerId" element={<PlayerCardWrapper />} />
            <Route
              path="/players/best-players"
              element={<AppTopRelated />}
            ></Route>
            <Route
              path="/players/player-list"
              element={<PlayersList viewPage="playerList" />}
            ></Route>
            <Route
              path="/championships/list"
              element={<ChampionshipsList viewPage="championshipsList" />}
            ></Route>
            <Route
              path="/challenge-matches/registration"
              element={
                <RegistrationForChallengeMatches viewPage="tegistrationForChallengeMatches" />
              }
            ></Route>
            <Route
              path="/challenge-matches/list"
              element={<ChallengeMatchesList viewPage="challengeMatchesList" />}
            ></Route>
            
            <Route
              path="/players/league/registration"
              element={
                <LeaguePlayersRegistrationForm viewPage="leaguePlayersRegistrationForm" />
              }
            ></Route>
            <Route
              path="/players/tournament/registration"
              element={
                <TournamentTeamRegis viewPage="tournamentTeamRegis" />
              }
            ></Route>
            <Route
              path="/events/coming"
              element={<EventCountdown viewPage="eventCountdown" />}
            ></Route>
            <Route
              path="/players/register"
              element={<PlayerEnrollment viewPage="eventCountdown" />}
            ></Route>
            <Route
              path="/league/submit"
              element={<LeagueSubmit viewPage="eventCountdown" />}
            ></Route>
            <Route
              path="/contactUs"
              element={<ContactUs viewPage="ContactUs" />}
            ></Route>
            <Route
              path="/committee/registration"
              element={<VolunteerRegistrationForm viewPage="Comittee Registration" />}
            ></Route>
            <Route
              path="/committee/list"
              element={<CommitteeRegList viewPage="committeeRegList" />}
            ></Route>
            <Route
              path="*"
              element={
                <main style={{ padding: '1rem' }}>
                  <p>There's nothing here!</p>
                  <Link href="/"> GO home</Link>
                </main>
              }
            >
              {/* <Outlet /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </Main>
    </Box>
  )
}
