import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import orderBy from 'lodash/orderBy'
import { getBaseUrl } from '../utils/utils'

const VolunteerTable = () => {
  const [displayData, setDisplayData] = useState([])

  useEffect(() => {
    const url = `${getBaseUrl()}challenge`
    axios
      .get(url)
      .then((response) => {
        const cutoffDate = new Date('2024-07-18')
        const filteredTeams = response.data.filter((player) => {
          const playerDate = new Date(player.createdAt)
          return playerDate >= cutoffDate
        })
        console.log('filteredTeams ', filteredTeams)
        setDisplayData(  orderBy(
           filteredTeams,
            ['fullTime',],
            ['desc', 'asc'],
          ))
      })
      .catch((error) => {
        console.error('Error fetching player data:', error)
      })
  }, [])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Full Commitment</TableCell>
            <TableCell>Rules Regulations</TableCell>
            <TableCell>Reserve - Conflict Resolution</TableCell>
            <TableCell>Reserve - Whn POC or Un-availabilty</TableCell>
            <TableCell>Final Dinner Planning</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayData.map((row, index) => (
              <TableRow key={index}>
                   <TableCell>{index+1}</TableCell>
              <TableCell>
                {`${row.firstName || row.ownerPlayerInfo?.firstName} ${
                  row.lastName || row.ownerPlayerInfo?.lastName
                }`}
              </TableCell>
              <TableCell>{row.fullTime ? 'Yes' : 'No'}</TableCell>

              <TableCell>{row.rulesResolution ? 'Yes' : 'No'}</TableCell>
              <TableCell>{row.conflictResolution ? 'Yes' : 'No'}</TableCell>
              <TableCell>
                {row.conflictResolutionsOnly ? 'Yes' : 'No'}
              </TableCell>
              <TableCell>{row.finalDinner ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default VolunteerTable
