import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { getDispDateTime } from '../../utils/utils'

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
)

export default function ScheduleDetailsModal({ schedule }) {
  console.log('schedule ', schedule)
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined" schedule={schedule}>
        <React.Fragment>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {schedule?.tournamentName}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {getDispDateTime(schedule?.matchTimeAndDate)}{' '}
            </Typography>{' '}
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {schedule?.gameCourt}
            </Typography>{' '}
            <Divider sx={{ marginTop: '10px' }}></Divider>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {schedule?.hostTeamName}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.error" gutterBottom>
              {schedule?.hostTeamPlayers}
            </Typography>
            <Divider>VS</Divider>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {schedule?.guestTeamName}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.warning" gutterBottom>
              {schedule?.guestTeamPlayers}
            </Typography>
          </CardContent>
        </React.Fragment>
      </Card>
      {schedule?.updatedFrom ? (
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {' '}
              <strong>Updated From: </strong>
              {schedule?.updatedFrom.map((d) => getDispDateTime(d)).join(',\n')}
            </Typography>

            <Divider></Divider>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              <strong>Updated By: </strong> {schedule?.updatedBy}
            </Typography>
            <Divider></Divider>
          </CardContent>
        </Card>
      ) : (
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <span>*Match is not changed from orignal schdule date </span>
        </Typography>
      )}
    </Box>
  )
}
