import * as React from 'react'
import moment from 'moment'
import { styled, darken, alpha, lighten } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler'
import MultipleStopIcon from '@mui/icons-material/MultipleStop'
import classNames from 'clsx'
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  Resources,
  DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui'
import WbSunny from '@mui/icons-material/WbSunny'
import FilterDrama from '@mui/icons-material/FilterDrama'
import Opacity from '@mui/icons-material/Opacity'
import ColorLens from '@mui/icons-material/ColorLens'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'
import { getNameInitialsFromStr } from '../utils/utils'
import { Button, Chip, Switch } from '@mui/material'

const resOwerns = []

const PREFIX = 'Schedule'

const classes = {
  cell: `${PREFIX}-cell`,
  content: `${PREFIX}-content`,
  text: `${PREFIX}-text`,
  sun: `${PREFIX}-sun`,
  cloud: `${PREFIX}-cloud`,
  rain: `${PREFIX}-rain`,
  sunBack: `${PREFIX}-sunBack`,
  cloudBack: `${PREFIX}-cloudBack`,
  rainBack: `${PREFIX}-rainBack`,
  opacity: `${PREFIX}-opacity`,
  appointment: `${PREFIX}-appointment`,
  apptContent: `${PREFIX}-apptContent`,
  flexibleSpace: `${PREFIX}-flexibleSpace`,
  flexContainer: `${PREFIX}-flexContainer`,
  tooltipContent: `${PREFIX}-tooltipContent`,
  tooltipText: `${PREFIX}-tooltipText`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  circle: `${PREFIX}-circle`,
  textCenter: `${PREFIX}-textCenter`,
  dateAndTitle: `${PREFIX}-dateAndTitle`,
  titleContainer: `${PREFIX}-titleContainer`,
  container: `${PREFIX}-container`,
}

const getBorder = (theme) =>
  `1px solid ${
    theme.palette.mode === 'light'
      ? lighten(alpha(theme.palette.divider, 1), 0.88)
      : darken(alpha(theme.palette.divider, 1), 0.68)
  }`

const DayScaleCell = (props) => (
  <MonthView.DayScaleCell
    {...props}
    style={{ textAlign: 'center', fontWeight: 'bold' }}
  />
)

// #FOLD_BLOCK
const StyledOpacity = styled(Opacity)(() => ({
  [`&.${classes.rain}`]: {
    color: '#4FC3F7',
  },
}))
// #FOLD_BLOCK
const StyledWbSunny = styled(WbSunny)(() => ({
  [`&.${classes.sun}`]: {
    color: '#FFEE58',
  },
}))
// #FOLD_BLOCK
const StyledFilterDrama = styled(FilterDrama)(() => ({
  [`&.${classes.cloud}`]: {
    color: '#90A4AE',
  },
}))

// #FOLD_BLOCK
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.cell}`]: {
    color: '#78909C!important',
    position: 'relative',
    userSelect: 'none',
    verticalAlign: 'top',
    padding: 0,
    height: 100,
    borderLeft: getBorder(theme),
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      paddingRight: 0,
    },
    'tr:last-child &': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      outline: 0,
    },
  },
  [`&.${classes.sunBack}`]: {
    backgroundColor: '#FFFDE7',
  },
  [`&.${classes.cloudBack}`]: {
    backgroundColor: '#ECEFF1',
  },
  [`&.${classes.rainBack}`]: {
    backgroundColor: '#E1F5FE',
  },
  [`&.${classes.opacity}`]: {
    opacity: '0.5',
  },
}))
// #FOLD_BLOCK
const StyledDivText = styled('div')(() => ({
  [`&.${classes.text}`]: {
    padding: '0.5em',
    textAlign: 'center',
  },
}))
// #FOLD_BLOCK
const StyledDivContent = styled('div')(() => ({
  [`&.${classes.content}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
  },
}))

// #FOLD_BLOCK
const StyledAppointmentsAppointment = styled(Appointments.Appointment)(() => ({
  [`&.${classes.appointment}`]: {
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6,
    },
  },
}))

// #FOLD_BLOCK
const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
  [`&.${classes.flexibleSpace}`]: {
    flex: 'none',
  },
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}))
// #FOLD_BLOCK
const StyledAppointmentsAppointmentContent = styled(
  Appointments.AppointmentContent,
)(() => ({
  [`&.${classes.apptContent}`]: {
    '&>div>div': {
      whiteSpace: 'normal !important',
      lineHeight: 1.2,
    },
  },
}))

const resources = [
  {
    fieldName: 'ownerId',
    title: 'Owners',
    instances: resOwerns,
  },
]

const WeatherIcon = ({ id }) => {
  switch (id) {
    case 0:
      return <StyledOpacity className={classes.rain} fontSize="large" />
    case 1:
      return <StyledWbSunny className={classes.sun} fontSize="large" />
    case 2:
      return <StyledFilterDrama className={classes.cloud} fontSize="large" />
    default:
      return null
  }
}

// #FOLD_BLOCK
const CellBase = React.memo(
  ({
    startDate,
    formatDate,
    otherMonth,
    // #FOLD_BLOCK
  }) => {
    const iconId = Math.abs(Math.floor(Math.sin(startDate.getDate()) * 10) % 3)
    const isFirstMonthDay = startDate.getDate() === 1
    const formatOptions = isFirstMonthDay
      ? { day: 'numeric', month: 'long' }
      : { day: 'numeric' }
    return (
      <StyledTableCell
        tabIndex={0}
        className={classNames({
          [classes.cell]: true,
          [classes.rainBack]: iconId === 0,
          [classes.sunBack]: iconId === 1,
          [classes.cloudBack]: iconId === 2,
          [classes.opacity]: otherMonth,
        })}
      >
        <StyledDivContent className={classes.content}>
          <WeatherIcon classes={classes} id={iconId} />
        </StyledDivContent>
        <StyledDivText className={classes.text}>
          {formatDate(startDate, formatOptions)}
        </StyledDivText>
      </StyledTableCell>
    )
  },
)

const TimeTableCell = CellBase

const Appointment = ({ ...restProps }) => (
  <StyledAppointmentsAppointment
    {...restProps}
    className={classes.appointment}
  />
)

const AppointmentContent = ({ ...restProps }) => (
  <StyledAppointmentsAppointmentContent
    {...restProps}
    className={classes.apptContent}
  />
)

const mappResourceArray = (schd) => {
  resOwerns.push({
    id: schd.scheduleId,
    text: (
      <>
        {' '}
        <Button sx={{ fontWeight: 'bolder' }}>{schd.hostTeamName}</Button>
        <Chip
          label={schd.hostTeamPlayers}
          variant="outlined"
          color="secondary"
        />
        <Button sx={{ fontWeight: 'bolder' }}>{schd.guestTeamName}</Button>
        <Chip
          label={schd.guestTeamPlayers}
          variant="outlined"
          color="secondary"
        />
        {/* <div stye={{ alignItems: 'center' }}>
            <MultipleStopIcon color={'error'}></MultipleStopIcon>{' '}
          </div> */}
      </>
    ),
    color: '#7E57C2',
  })
}
const getSchdData = (schedules) => {
  return schedules.map((schd) => {
    mappResourceArray(schd)
    const mapObj = {
      id: schd.scheduleId,
      title: `${getNameInitialsFromStr(
        schd.hostTeamPlayers,
      )} VS ${getNameInitialsFromStr(schd.guestTeamPlayers)}`,
      startDate: moment(schd.matchTimeAndDate),
      endDate: moment(moment(schd.matchTimeAndDate)).add(1, 'hours'),
      ownerId: schd.scheduleId,
    }
    return mapObj
  })
}
export default class ScheduleMonthView extends React.PureComponent {
  // #FOLD_BLOCK
  constructor(props) {
    console.log('Props ', props)
    const data = props?.schedules ? getSchdData(props?.schedules) : []
    super(props)
    this.state = {
      data,
      setIsMonthView: props?.setIsMonthView,
    }

    this.commitChanges = this.commitChanges.bind(this)
  }

  // #FOLD_BLOCK
  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state
      if (added) {
        const startingAddedId =
          data.length > 0 ? data[data.length - 1].id + 1 : 0
        data = [...data, { id: startingAddedId, ...added }]
      }
      if (changed) {
        data = data.map((appointment) =>
          changed[appointment.id]
            ? { ...appointment, ...changed[appointment.id] }
            : appointment,
        )
      }
      if (deleted !== undefined) {
        data = data.filter((appointment) => appointment.id !== deleted)
      }
      return { data }
    })
  }

  FlexibleSpace = ({ ...restProps }) => {
    console.log('restProps', restProps)
    return (
      <StyledToolbarFlexibleSpace
        {...restProps}
        className={classes.flexibleSpace}
      >
        <div className={classes.flexContainer}>
          {/* <ColorLens fontSize="large" htmlColor="#FF7043" /> */}
          {/* <Switch color="warning" onChange={() => {}}></Switch> */}
          <Chip
            onClick={() => this.state.setIsMonthView(false)}
            elevation={24}
            color="secondary"
            label="Schedules table View"
            deleteIcon={<DeleteIcon />}
            variant="outlined"
          />
          {/* <Typography variant="h6" style={{ marginLeft: '5px' }}> */}

          {/* </Typography> */}
        </div>
      </StyledToolbarFlexibleSpace>
    )
  }

  render() {
    const { data } = this.state

    return (
      <Paper>
        <Scheduler data={data}>
          <EditingState onCommitChanges={this.commitChanges} />
          <ViewState defaultCurrentDate={new Date()} />

          <MonthView
            startDayHour={7}
            endDayHour={20}
            excludedDays={[0]}
            timeTableCellComponent={TimeTableCell}
            dayScaleCellComponent={DayScaleCell}
          />
          <Appointments
            appointmentComponent={Appointment}
            appointmentContentComponent={AppointmentContent}
          />
          <Resources data={resources} />
          <Toolbar flexibleSpaceComponent={this.FlexibleSpace} />

          <DateNavigator />
          <EditRecurrenceMenu />
          <AppointmentTooltip showCloseButton />
          <AppointmentForm />
          <DragDropProvider />
        </Scheduler>
      </Paper>
    )
  }
}
