import React from 'react'
import './Advertisement.css' // Import the corresponding CSS file

const Advertisement = () => {

  return (
    <div className="card">
      <img
        src="https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/rrtl-summer-2023.3.jpg"
        alt="Card Image"
      />
    </div>
  )
}

export default Advertisement
