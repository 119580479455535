import { Badge, Chip, Grid, SnackbarContent } from '@material-ui/core'
import CardContent from '@mui/material/CardContent'
import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { fPercent, fShortenNumber } from '../utils/formatNumber'
import Card from '../components/common/Card/Card'
import CardAvatar from '../components/common/Card/CardAvatar'
import CardHeader from '../components/common/Card/CardHeader'
import NavigationIcon from '@mui/icons-material/Navigation'

import { Button, Fab, Snackbar, Stack } from '@mui/material'

export default function PlayerRankingDetails(props) {
  const { player, position, setOpen } = props
  const {
    teamName,
    matchesWon,
    matchesLost,
    totalMatchesPlayed,
    fullName,
    playerPoints,
    netGamesWon,
    netGamesLost,
    playerRatings,
    playerId,

    avatarImgUrl = null,
  } = player
  const getAction = (label) => (
    <Button color="warning" size="small">
      {label}
    </Button>
  )

  const getCardView = () => {
    return (
      <Badge
        badgeContent={<b>{<CloseOutlinedIcon color="warning" />}</b>}
        color="default"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClick={() => setOpen(false)}
      >
        <Card sx={{ maxWidth: 245 }}>
          <CardHeader
            color="warning"
            stats
            icon
            subheader={teamName}
            title={fullName}
          >
            <Badge
              badgeContent={<b>{position}</b>}
              color="secondary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClick={() => setOpen(false)}
            ></Badge>
            <CardAvatar profile>
              <a onClick={(e) => e.preventDefault()}>
                <img src={avatarImgUrl} alt={fullName} />
              </a>
            </CardAvatar>
          </CardHeader>

          <CardContent>
            <Stack spacing={0.5} sx={{ maxWidth: '100%', mb: '10px' }}>
              <SnackbarContent
                message={fullName}
                action={getAction(teamName)}
              />
              {fullName === 'Talha Khan' &&
                <SnackbarContent
                  style={{ backgroundColor: '#1a3333' }}
                  message={
                    <div>
                      <div style={{ color: 'yellow' }}>
                        Summer 2023 League
                    </div>
                      <div style={{ color: 'yellow' }}>
                        Fall 2022 League
                    </div>
                    </div>
                  }
                  action={
                    <div>
                      <div style={{ color: 'yellow' }}>
                        Runner-up
                    </div>
                      <div style={{ color: 'yellow' }}>
                        Runner-up
                    </div>
                    </div>
                  }
                />
              }
              <SnackbarContent
                sx={{ backgroundColor: 'yellow' }}
                message={
                  <Chip
                    color="primary"
                    size="large"
                    label={`Points: ${fShortenNumber(playerPoints)} `}
                  ></Chip>
                }
                action={
                  <Chip color="primary" size="large" label={<div>{`RRTL Ranking # ${position}`}</div>}></Chip>
                }
              />
            </Stack>

            <Grid container spacing={2}>
              {/* <Grid item xs={6}>
                <Chip color="primary" label={fullName}></Chip>
              </Grid>
              <Grid item xs={6}>
                <Chip color="primary" label={teamName}></Chip>
              </Grid> */}
              {/* <Grid item xs={6}>
                <Chip
                  color="primary"
                  label={`Points: ${fShortenNumber(playerPoints)} `}
                ></Chip>
              </Grid>
              <Grid item xs={6}>
                <Chip color="primary" label={`Ranking # ${position}`}></Chip>
              </Grid> */}
              <Grid item xs={6}>
                <Chip
                  variant="outlined"
                  label={
                    <>
                      Total Match: <b>{totalMatchesPlayed}</b>
                    </>
                  }
                ></Chip>
              </Grid>
              <Grid item xs={6}>
                <Chip
                  variant="outlined"
                  label={
                    <>
                      Winning:
                      <b>
                        {fPercent(
                          (matchesWon * 100) / (matchesLost + matchesWon),
                        )}
                      </b>
                    </>
                  }
                ></Chip>
              </Grid>
              <Grid item xs={6}>
                <Chip
                  variant="outlined"
                  label={
                    <>
                      Matches Won: <b>{player.matchesWon}</b>
                    </>
                  }
                ></Chip>
              </Grid>
              <Grid item xs={6}>
                <Chip
                  variant="outlined"
                  label={
                    <>
                      Matches Lost: <b>{player.matchesLost}</b>
                    </>
                  }
                ></Chip>
              </Grid>
              <Grid item xs={6}>
                <Chip
                  variant="outlined"
                  label={
                    <>
                      Games Won: <b>{netGamesWon}</b>
                    </>
                  }
                ></Chip>
              </Grid>
              <Grid item xs={6}>
                <Chip
                  variant="outlined"
                  label={
                    <>
                      Games Lost: <b>{netGamesLost}</b>
                    </>
                  }
                ></Chip>
              </Grid>
            </Grid>
          </CardContent>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            aria-label="add"
            href="players/all"
          >
            {/* <NavigationIcon sx={{ mt: 3, mb: 0 }} /> */}
            All Players's Profile
          </Fab>
          {/* <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions> */}
        </Card>
      </Badge>
    )
  }
  return getCardView()
}
