import * as React from 'react'
import Grid from '@mui/material/Grid'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'All',
]

const noteTexts = [
  '- Only registered players will be eligible to participate in challenge matches.',
  '- The results of challenge matches will be recorded on the website, impacting player rankings.',
  '- During the challenge week, players will be required to indicate their availability.',
  '- A random draw will determine player pairings.',
  '- First challenge matches will be scheduled between September 15th and September 30th.',
  '*- In case of NO draw, the committee will determine an alternative.',
]

export default function MatchDaysSelection({ selections, setSelections }) {
  const handleChange = (event) => {
    setSelections({
      ...selections,
      matchDays: {
        ...selections.matchDays,
        [event.target.name]: event.target.checked,
      },
    })
  }

  const handlePreferenceChange = (event, preferenceKey) => {
    setSelections({
      ...selections,
      [preferenceKey]: event.target.value,
    })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          variant="standard"
          sx={{ textAlign: 'left' }}
        >
          <FormLabel component="legend">
            <Typography variant="subtitle1" sx={{ color: '#1976d2' }}>
              Select Match Preferences
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-label="playerPreference"
            name="playerPreference"
            value={selections.playerPreference}
            onChange={(event) =>
              handlePreferenceChange(event, 'playerPreference')
            }
          >
            <Alert severity="success" icon={false}>
              <FormControlLabel
                value="onceAMonth"
                control={<Radio />}
                label="Once a month"
              />
              <FormControlLabel
                value="twiceAMonth"
                control={<Radio />}
                label="Twice a month"
              />
              <FormControlLabel
                value="either"
                control={<Radio />}
                label="Either"
              />
            </Alert>
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          variant="standard"
          sx={{ textAlign: 'left' }}
        >
          <FormLabel component="legend">
            <Typography variant="subtitle1" sx={{ color: '#1976d2' }}>
              Select Playing Preferences
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-label="playingPreference"
            name="playingPreference"
            value={selections.playingPreference}
            onChange={(event) =>
              handlePreferenceChange(event, 'playingPreference')
            }
          >
            <Alert severity="info" icon={false}>
              <FormControlLabel
                value="double"
                control={<Radio />}
                label="Double"
                disabled={true}
              />
            </Alert>
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          variant="standard"
          sx={{ textAlign: 'left' }}
        >
          <FormLabel component="legend">
            <Typography variant="subtitle1" sx={{ color: '#1976d2' }}>
              Select Pairup Preferences
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-label="pairupPreference"
            name="pairupPreference"
            value={selections.pairupPreference}
            onChange={(event) =>
              handlePreferenceChange(event, 'pairupPreference')
            }
          >
            <Alert severity="warning" icon={false}>
              <FormControlLabel
                value="okayWithDraw"
                control={<Radio />}
                label="Random Draw"
              />
              <FormControlLabel
                value="either"
                control={<Radio />}
                label="Other*"
              />
            </Alert>
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          variant="standard"
          sx={{ textAlign: 'left' }}
        >
          <FormLabel component="legend">
            <Typography variant="subtitle1" sx={{ color: '#1976d2' }}>
              Select Your Available Match Days
            </Typography>
          </FormLabel>
          <FormGroup>
            <Box
              sx={{
                backgroundColor: 'rgb(237, 247, 237)',
              }}
            >
              <Grid container>
                {daysOfWeek.map((day) => (
                  <Grid item xs={4} sm={6} md={4} lg={3} key={day}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selections.matchDays[day.toLowerCase()]}
                          onChange={handleChange}
                          name={day.toLowerCase()}
                        />
                      }
                      label={<Typography variant="body3">{day}</Typography>}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </FormGroup>
          {noteTexts.map((note, index) => (
            <Typography
              key={index}
              variant="caption"
               sx={{
                textAlign: 'left',
                color: index === noteTexts.length - 2 ? 'indigo' : 'inherit',
              }}
            >
              {note}
            </Typography>
          ))}
        </FormControl>
      </Grid>
    </Grid>
  )
}
