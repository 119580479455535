import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/react'
import EditIcon from '@mui/icons-material/Edit'
import ShortcutIcon from '@mui/icons-material/Shortcut'
import { Badge, Box, Chip, Link, ListItem, Stack } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import DeleteIcon from '@mui/icons-material/Delete'
import { makeDeleteCall } from '../../api/client'

// import EditNoteIcon from '@mui/icons-material/EditNote'

import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BarLoader from 'react-spinners/BarLoader'
import HashLoader from 'react-spinners/HashLoader'
import QuickMenu from '../../components/quick-menu'
import { fetchPlayers, selectAllPlayers } from '../../players/players.slice'
import { MATCHES_TYPES } from '../../utils/constants'
import {
  capitalizeFirstLetter,
  getPlayerFirstNames,
  getWindowDimensions,
  mapNumToWord,
} from '../../utils/utils'
import { allResults, fetchResult } from './result.slice'
import ResultsFilterGrid from './results.details.list'
import { ResultSubmit } from './results.submit'
import PlayerResultsTile from '../schedule/player.results.tile'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
    border: '1px solid #673174',
    padding: 1,
    // paddingLeft: 7,
    // paddingRight: 3,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    border: '0.3px dashed grey',
    padding: 1,
    fontWeight: 'normal',
    // paddingLeft: 7,
    // paddingRight: 3,
    textAlign: 'center',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white,
    height: '0rem',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 1,
    backgroundColor: theme.palette.common.white,
    borderSpacing: '15px 20px',
    height: '0rem',
  },
}))

const commonStyles = {
  bgcolor: 'background.paper',
  border: '1px solid',
  color: '#673174',
  m: 1,
  borderColor: 'text.primary',
  width: '5rem',
  height: '5rem',
}

const getMatchSetPointsHeader = (maxMatchSetSize) => {
  // let setsHeaders = [];
  let _setsHeaders = []
  let i = 1
  while (i <= maxMatchSetSize) {
    const eleSet = mapNumToWord[i]
    _setsHeaders.push(eleSet)
    i++
  }
  return _setsHeaders.join(' | ')
}
const getTextColor = (teamType, row) => {
  return teamType === 'host'
    ? row.winningTeam?.teamName === row.hostTeam?.teamName
      ? 'success'
      : 'warning'
    : row.winningTeam?.teamName === row.guestTeam?.teamName
    ? 'success'
    : 'warning'
}

const getTeamTextColor = (teamType, row) => {
  return teamType === 'host'
    ? row.winningTeam?.teamName === row.hostTeam?.teamName
      ? true
      : false
    : row.winningTeam?.teamName === row.guestTeam?.teamName
    ? true
    : false
}

const getTeamPoints = (maxMatchSetSize, row, teamType) => {
  const textColor = getTextColor(teamType, row)
  console.log('row.guestTeam ', {
    host: row.hostTeam?.teamName,
    guest: row.guestTeam?.teamName,
    win: row.winningTeam?.teamName,
  })
  let setsCells = []
  let i = 1
  while (i <= row?.matchSetsSize) {
    const key = `set${mapNumToWord[i]}Points`
    const setCells = () => {
      return teamType === 'host' ? row.hostTeam[key] : row.guestTeam[key]
    }
    setsCells.push(setCells())
    i++
  }

  if (row.matchStatus === 'forfeited') {
    return (
      <StyledTableCell align="center" key={`set-points-cell`}>
        N/A
      </StyledTableCell>
    )
  }
  return (
    <StyledTableCell align="center" key={`set-points-cell`}>
      <ListItem
        sx={{
          marginTop: '26px',
          marginRight: '10px',
          maxWidth: setsCells.length > 2 ? '85px' : '50px',
        }}
      >
        {setsCells.map((e, i) => {
          return (
            <>
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                key={`badge-score-${e}-${i}`}
                badgeContent={JSON.stringify(e)}
                color={textColor}
              >
                <Chip
                  size="small"
                  key={`chip-score-${e}-${i}`}
                  label={i + 1}
                />
              </Badge>
            </>
          )
        })}
      </ListItem>
    </StyledTableCell>
  )
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`
let selectedTeam = {}

export function ResultsList() {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0()
  const dispatch = useDispatch()
  const { width } = getWindowDimensions()
  const [maxMatchSetSize, setMaxMatchSetSize] = React.useState(1)
  const resultsFetchStatus = useSelector((state) => {
    return state?.results?.status
  })
  const results = useSelector(allResults)

  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)
  const [edit, setEdit] = React.useState(false)
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [token, setToken] = React.useState(null)
  const [selectedMatches, setSelectedMatches] = React.useState([])
  const [matchRadioSelection, setMatchRadioSelection] = React.useState(0)
  const [detailedView, setDetailedView] = React.useState(false)
  const [isDeleted, setIsDeleted] = React.useState(false)
  useEffect(() => {
    async function _setToken() {
      setToken(await getAccessTokenSilently())
    }
    _setToken()
  }, [isAuthenticated])

  useEffect(() => {
    if (resultsFetchStatus === 'idle') {
      dispatch(fetchResult())
    }
  }, [resultsFetchStatus, dispatch])

  useEffect(() => {
    if (resultsFetchStatus === 'succeeded') {
      const _maxMatchSetSize = Math.max(...results.map((s) => s.matchSetsSize))
      setMaxMatchSetSize(_maxMatchSetSize)
      const type = MATCHES_TYPES[matchRadioSelection].value
      const filteredMatches = results.filter((s) =>
        type === 'All' ? s : s.tournamentName === type,
      )
      setSelectedMatches(filteredMatches)
    }
  }, [resultsFetchStatus, matchRadioSelection, results])

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
    if (playersStatus === 'succeeded' && isAuthenticated) {
      const findP = allPlayers.find((p) => user?.email === p.playerId)
      setIsAdmin(findP?.admin ? true : false)
    }
  }, [playersStatus, dispatch, isAuthenticated, allPlayers, user?.email])

  const handleEditTeam = (data) => {
    selectedTeam = data
    setEdit(true)
  }

  const handleDelete = (id) => {
    console.log('Calling delete result: ', id)
    makeDeleteCall(id, 'result', token, () => {})
    setIsDeleted(true)
  }
  const isOwner = (result) => {
    if (isAuthenticated) {
      if (isAdmin) {
        return true
      } else if (
        result?.createdBy === user?.email ||
        result?.updatedBy === user?.email
      ) {
        return true
      }
    }
    return false
  }

  const formattedDate = (row) => {
    return (
      <>
        {' '}
        {moment(row.matchTimeAndDate).format('MMM Do (dddd)')}
        {isAdmin && (
          <div>
            {/* <Chip
              variant="contained"
              size="small"
              sx={width < 500 ? { mt: 1 } : { ml: -1 }}
              color="warning"
              icon={<EditIcon />}
              label={'Edit'}
              onClick={() => handleEditTeam(row)}
            /> */}
            {!isDeleted && (
              <>
                <EditIcon
                  variant="contained"
                  size="small"
                  sx={width < 500 ? { mt: 1 } : { ml: -1 }}
                  color="warning"
                  icon={<EditIcon />}
                  label={'Edit'}
                  onClick={() => handleEditTeam(row)}
                />
                <DeleteIcon
                  size="small"
                  color="primary"
                  onClick={() => handleDelete(row.resultId)}
                />
              </>
            )}
          </div>
        )}
      </>
    )
  }
  const handleChange = (event) => {
    setMatchRadioSelection(event.target.value)
  }

  const getRadioSelection = () => {
    return (
      <>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={matchRadioSelection}
            onChange={handleChange}
          >
            {MATCHES_TYPES.map((m, i) => (
              <FormControlLabel
                key={`${m.value}-key`}
                value={i}
                control={<Radio />}
                label={m.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    )
  }
  const resultsView = () => {
    const { width } = getWindowDimensions()
    return (
      <Paper sx={{ width }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            m: 1,
            bgcolor: '#673174',
            color: 'warning.contrastText',
            borderRadius: 1,
          }}
        >
          Results
          <Chip
            size="small"
            variant="outlined"
            sx={{ marginLeft: '6px', marginTop: '4px' }}
            onClick={() => setDetailedView(true)}
            label="Simple view"
            icon={<ShortcutIcon size="small" />}
            color="warning"
          ></Chip>
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {isAdmin && (
            <Link variant="text" href="/result/submit">
              <Chip label={'Add New Result'} color="primary" />
            </Link>
          )}
        </Stack>
        <Paper align="center">{getRadioSelection()}</Paper>

        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, maxWidth: width }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  rowSpan="3"
                  style={{ width: '1px' }}
                >
                  Date
                </StyledTableCell>
                <StyledTableCell rowSpan="3" style={{ width: '4px' }}>
                  Teams
                </StyledTableCell>
                <StyledTableCell rowSpan="3" style={{ width: '4px' }}>
                  Players
                </StyledTableCell>
                <StyledTableCell rowSpan="3" style={{ width: '4px' }}>
                  Games
                </StyledTableCell>

                <StyledTableCell align="center" style={{ width: '2px' }}>
                  Winning Team
                </StyledTableCell>

                <StyledTableCell align="center" style={{ width: '1px' }}>
                  Points
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: '1px' }}>
                  Status
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: '3px' }}>
                  League
                </StyledTableCell>
                {width > 600 && (
                  <>
                    {/* <StyledTableCell rowSpan="3" style={{ width: '4px' }}>
                      
                    </StyledTableCell> */}
                    <StyledTableCell rowSpan="3">Comments</StyledTableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedMatches.map((row, i) => (
                <React.Fragment key={`table-${row.matchTimeAndDate}-${i}`}>
                  {/* first team */}
                  <StyledTableRow
                    key={`${row.hostTeam.teamId}-row-${i}`}
                    sx={{ ...commonStyles, borderColor: 'error.main' }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      rowSpan="2"
                      sx={{ color: 'darkblue' }}
                    >
                      {formattedDate(row)}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      rowSpan="2"
                      sx={{ color: 'darkblue' }}
                    >
                      <PlayerResultsTile
                        hostTeamPlayersId={[]}
                        guestTeamPlayersId={[]}
                        hostTeamName={[]}
                        guestTeamName={[]}
                        data={row}
                        players={allPlayers}
                      />
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderColor: 'error.main',
                        color: getTeamTextColor('host', row) ? 'green' : 'red',
                        fontWeight: 'light',
                      }}
                    >
                      {row.hostTeam?.teamName} (
                      {getPlayerFirstNames(row.hostTeamPlayers)})
                    </StyledTableCell>
                    {getTeamPoints(maxMatchSetSize, row, 'host')}
                    <StyledTableCell
                      align="center"
                      rowSpan="2"
                      sx={{ color: 'darkblue' }}
                    >
                      {row.matchStatus === 'draw'
                        ? 'Match is Draw.'
                        : row.winningTeam?.teamName}
                      {/* {row.matchStatus !== 'draw' &&
                        `(${getPlayerFirstNames(row.winningTeamPlayers)})`} */}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      rowSpan="2"
                      sx={{ color: 'darkblue' }}
                    >
                      {row.matchPoints}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      rowSpan="2"
                      sx={{ color: 'darkblue' }}
                    >
                      {capitalizeFirstLetter(row.matchStatus)}
                    </StyledTableCell>
                    {width > 600 && (
                      <>
                        <StyledTableCell
                          align="center"
                          rowSpan="2"
                          sx={{ color: 'darkblue' }}
                        >
                          {row.tournamentName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          rowSpan="2"
                          sx={{ color: 'darkblue' }}
                        >
                          {row.resultComments}
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                  {/* second team */}
                  <StyledTableRow key={row.guestTeam.teamId}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{
                        color: getTeamTextColor('guest', row) ? 'green' : 'red',
                        fontWeight: 'light',
                      }}
                    >
                      {row.guestTeam?.teamName} (
                      {getPlayerFirstNames(row.guestTeamPlayers)})
                    </StyledTableCell>
                    {getTeamPoints(maxMatchSetSize, row, 'guest')}
                  </StyledTableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }
  const loadingView = () => {
    return (
      <>
        <h3 align="center">
          {' '}
          <HashLoader color="orange" css={override} size={50} /> ...loading
          results{' '}
          <BarLoader color="#1a39a8" css={override} size={6} margin={2} />
        </h3>
      </>
    )
  }
  const failedView = () => {
    return (
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          mt: 1,
          mb: 1,
          bgcolor: '#ff3900',
          color: 'warning.contrastText',
          borderRadius: 1,
        }}
      >
        something went wrong... please check later {resultsFetchStatus}
      </Typography>
    )
  }
  const noResultView = () => {
    return (
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          mt: 1,
          mb: 1,
          bgcolor: '#7a76a1',
          color: 'warning.contrastText',
          borderRadius: 1,
        }}
      >
        No result have been submitted yet.
      </Typography>
    )
  }
  const editResult = () => {
    return <ResultSubmit result={selectedTeam} user={user}></ResultSubmit>
  }
  const getView = () => {
    if (resultsFetchStatus === 'loading' || resultsFetchStatus === 'idle') {
      return loadingView()
    } else if (resultsFetchStatus === 'succeeded' && results?.length > 0) {
      return resultsView()
    } else if (resultsFetchStatus === 'succeeded' && results?.length === 0) {
      return noResultView()
    } else {
      return failedView()
    }
  }
  return edit ? (
    <>
      <QuickMenu />
      {editResult()}
    </>
  ) : (
    <Box sx={{ width }}>
      <QuickMenu />
      {detailedView ? (
        <ResultsFilterGrid
          results={results}
          setDetailedView={setDetailedView}
          isAdmin={isAdmin}
        />
      ) : (
        getView()
      )}
    </Box>
  )
}
