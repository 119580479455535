import { TrendingUpTwoTone } from '@mui/icons-material'

export const TOURNAMENT_NAME = 'Fall 2024'

export const MATCHES_TYPES = [
  { key: 'fall-2024', value: 'Fall 2024', active: true },
  { key: 'spring-2024', value: 'Spring 2024', active: true },
  // { key: 'winter-2023', value: 'Winter 2023', active: true },
  { key: 'summer-2023', value: 'Summer 2023', active: false },
  { key: 'spring-2023', value: 'Spring 2023', active: false },
  // { key: 'fall-2022', value: 'Fall 2022', active: false },
  // { key: 'summer-2022', value: 'Summer 2022' },
  // { key: 'practice-matches', value: 'Practice Matches' },
  // { key: 'challenge-matches', value: 'Challenge Matches', active: true },
  { key: 'all', value: 'All', active: TrendingUpTwoTone },
]

export const TOURNAMENTS = MATCHES_TYPES.map((match) => match.value).filter(
  (value) => value !== 'All',
)


 export const WEEK_DAYS = [
    { name: 'Sunday', id: 0 },
    { name: 'Monday', id: 1 },
    { name: 'Tuesday', id: 2 },
    { name: 'Wednesday', id: 3 },
    { name: 'Thursday', id: 4 },
    { name: 'Friday', id: 5 },
    { name: 'Saturday', id: 6 },
  ]