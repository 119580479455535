import { Link } from '@mui/material'
import Paper from '@mui/material/Paper'
import { createTheme, styled, ThemeProvider } from '@mui/material/styles'
import * as React from 'react'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}))

const themes = {
  light: createTheme({ palette: { mode: 'light' } }),
  dark: createTheme({ palette: { mode: 'dark' } }),
}

export default function ElevationTile({
  tileContent,
  mode = 'light',
  href = null,
}) {
  console.log("tileContent ", tileContent)
  return (
    <ThemeProvider theme={themes[mode]}>
      <Item elevation={24}>
        {href ? (
          <Link href={href}>
            <strong>{tileContent}</strong>
          </Link>
        ) : (
          <strong>{tileContent}</strong>
        )}
      </Item>
    </ThemeProvider>
  )
}
