import { useAuth0 } from '@auth0/auth0-react'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DateTimePicker from '@mui/lab/DateTimePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Autocomplete, LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makePostCall } from '../../api/client'
import { Spinner } from '../../components/Spinner'
import { fetchPlayers, selectAllPlayers } from '../../players/players.slice'
import { allTeams, fetchTeams } from '../../teams/teams.slice'
import { BasicModal } from '../../utils/common.element'
import { TOURNAMENTS } from '../../utils/constants'
import TeamByPlayers from './team-by-players'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

export function ScheduleSubmitV2(props) {
  const editSchedule = props?.schedule
  const {
    isAuthenticated,
    user,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0()
  const [token, setToken] = React.useState(null)
  const [isAdmin, setIsAdmin] = React.useState(false)
  const dispatch = useDispatch()

  const teams = useSelector(allTeams)
  const teamsFetchStatus = useSelector((state) => state?.teams?.status)

  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)

  useEffect(() => {
    async function _setToken() {
      setToken(await getAccessTokenSilently())
    }
    _setToken()
  }, [isAuthenticated, getAccessTokenSilently])

  useEffect(() => {
    if (teamsFetchStatus === 'idle') {
      dispatch(fetchTeams())
    }
    if (teamsFetchStatus === 'succeeded' && teams.length > 0) {
      if (editSchedule) {
        handleTeamsSelection('hostTeam', editSchedule?.hostTeamId)
        handleTeamsSelection('guestTeam', editSchedule?.guestTeamId)
      }
    }
  }, [teamsFetchStatus, dispatch])

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }

    if (playersStatus === 'succeeded' && isAuthenticated) {
      const findP = allPlayers.find((p) => user?.email === p.playerId)
      setIsAdmin(findP?.admin ? true : true)
    }
  }, [playersStatus, isAuthenticated, dispatch])

  const [hostTeamId, setHostTeamId] = React.useState('')
  const [hostTeamName, setHostTeamName] = React.useState('')
  const [hostTeamPlayers, setHostTeamPlayers] = React.useState('')
  const [hostTeamPlayersId, setHostTeamPlayersId] = React.useState('')
  const [guestTeamId, setGuestTeamId] = React.useState('')
  const [guestTeamName, setGuestTeamName] = React.useState('')
  const [guestTeamPlayers, setGuestTeamPlayers] = React.useState('')
  const [guestTeamPlayersId, setGuestTeamPlayersId] = React.useState('')

  const [matchTimeAndDate, setMatchTimeAndDate] = React.useState(
    moment(new Date()).format(editSchedule?.matchTimeAndDate || ''),
  )
  const [tournamentName, setTournamentName] = React.useState(
    editSchedule?.tournamentName || TOURNAMENTS[0],
  )
  const [gameCourt, setGameCourt] = React.useState(
    editSchedule?.gameCourt || 'Old Settlers Park',
  )
  const [additionalInfo, setAdditionalInfo] = React.useState(
    editSchedule?.additionalInfo || '',
  )

  const [submitButton, setSubmitButton] = React.useState({
    message: 'Submit schedule',
    severity: null,
  })
  const [openModel, setOpenModel] = React.useState(false)

  const handleTeamsSelection = (name, value) => {
    const selectedTeam = teams.find((t) => t.teamId === value)
    const selectedPlayers = selectedTeam?.teamPlayersFullNames
    if (name === 'hostTeam') {
      if (value) {
        setHostTeamPlayers(`${selectedPlayers[0]} - ${selectedPlayers[1]}`)
        setHostTeamId(selectedTeam?.teamId)
        setHostTeamName(selectedTeam?.teamName)
        setHostTeamPlayersId(selectedTeam?.teamPlayersIds)
      } else {
        setHostTeamPlayers(``)
        setHostTeamId('')
        setHostTeamName('')
        setHostTeamPlayersId('')
      }
    }
    if (name === 'guestTeam') {
      if (value) {
        setGuestTeamPlayers(`${selectedPlayers[0]} - ${selectedPlayers[1]}`)
        setGuestTeamId(selectedTeam?.teamId)
        setGuestTeamName(selectedTeam?.teamName)
        setGuestTeamPlayersId(selectedTeam?.teamPlayersIds)
      } else {
        setGuestTeamPlayers(``)
        setGuestTeamId('')
        setGuestTeamName('')
        setGuestTeamPlayersId('')
      }
    }
  }

  const handleMatchDate = (e) => {
    setMatchTimeAndDate(moment(e).format())
  }

  const handleSubmit = () => {
    const scheduleDetails = {
      matchTimeAndDate,
      hostTeamId,
      hostTeamName,
      hostTeamPlayers,
      hostTeamPlayersId,
      guestTeamId,
      guestTeamName,
      guestTeamPlayers,
      guestTeamPlayersId,
      gameCourt,
      tournamentName,
      additionalInfo,
    }
    if (editSchedule) {
      scheduleDetails['updatedBy'] = user?.email
      scheduleDetails['updatedAt'] = new Date().toISOString()
      scheduleDetails['scheduleId'] = editSchedule?.scheduleId
    } else {
      scheduleDetails['createdBy'] = user?.email
      scheduleDetails['createdAt'] = new Date().toISOString()
    }
    console.log('Submit schedule: ', scheduleDetails)
    makePostCall(scheduleDetails, 'schedule', token, setSubmitButton)
    setOpenModel(true)
  }

  const leaguesSelectionInputElement = () => {
    return (
      <Autocomplete
        fullWidth
        sx={{ mt: 2 }}
        id="matchType"
        value={tournamentName}
        options={TOURNAMENTS}
        getOptionLabel={(option) => option}
        onChange={(e, v) => setTournamentName(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label="Schedule for"
            name="matchType"
            placeholder="Match Type"
          />
        )}
      />
    )
  }
  return (
    <>
      {isAuthenticated && (
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            m: 1,
            bgcolor: '#673174',
            color: 'warning.contrastText',
            borderRadius: 1,
          }}
        >
          Create schedule
        </Typography>
      )}
      {isAuthenticated && teamsFetchStatus === 'loading' && (
        <>
          <Spinner text="Loading  data..." />
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </>
      )}
      {!isAuthenticated && (
        <Button
          sx={{ my: '40px', fontWeight: '600' }}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={loginWithPopup}
        >
          Without login, scheduled can not be created
          <LockOpenIcon fontSize="large" sx={{ mr: 1, color: 'yellow' }} />
        </Button>
      )}
      {isAuthenticated && !isAdmin && (
        <Button sx={{ my: '30px' }} fullWidth variant="contained" color="error">
          Only admin can add/update schedule {JSON.stringify(isAdmin)}
        </Button>
      )}
      {isAuthenticated && isAdmin && (
        <>
          {/* Host Team */}
          <Box sx={{ justifyContent: 'center', width: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              <Box gridColumn="span 12">
                <Item>
                  <TeamByPlayers
                    fieldName="hostTeam"
                    hostTeamPlayers={hostTeamPlayersId}
                    playersData={allPlayers}
                    teamData={teams}
                    handleTeamsSelection={handleTeamsSelection}
                  ></TeamByPlayers>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel id="host-team-input-label">
                      Host Team
                    </InputLabel>
                    {/* <Select
                      labelId="host-team-label"
                      id="host-team-id"
                      value={hostTeamId}
                      name="hostTeam"
                      autoWidth
                      onChange={(e) =>
                        handleTeamsSelection(e.target.name, e.target.value)
                      }
                      label="host team"
                    >
                      {teams.map((t) => (
                        <MenuItem
                          key={t.teamId}
                          value={t.teamId}
                          name={t.teamName}
                          {...{
                            disabled: t.teamId === guestTeamId ? true : false,
                          }}
                        >
                          {t.teamName} [{t.teamPlayersFullNames[0]}-
                          {t.teamPlayersFullNames[1]}]
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Autocomplete
                      fullWidth={true}
                      size="large"
                      disabled={teams?.length < 1}
                      options={teams}
                      getOptionLabel={(option) =>
                        `${option?.teamName}-${option?.teamPlayersFullNames}` ||
                        ''
                      }
                      onChange={(e, value) =>
                        handleTeamsSelection('hostTeam', value?.teamId)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label={'Host team'} />
                      )}
                    />
                    <FormHelperText>{hostTeamPlayers}</FormHelperText>
                  </FormControl>
                </Item>
              </Box>
            </Box>
          </Box>
          <Box sx={{ justifyContent: 'center', width: 1 }}>
            {/* Guest Team */}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              <Box gridColumn="span 12">
                <Item>
                  <TeamByPlayers
                    fieldName="guestTeam"
                    playersData={allPlayers}
                    teamData={teams}
                    handleTeamsSelection={handleTeamsSelection}
                  ></TeamByPlayers>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel id="gueat-team-label">Guest Team</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="guestTeam"
                      value={guestTeamId}
                      name="guestTeam"
                      autoWidth
                      onChange={(e) =>
                        handleTeamsSelection(e.target.name, e.target.value)
                      }
                      label="guest team"
                    >
                      {teams.map((t) => (
                        <MenuItem
                          key={t.teamId}
                          value={t.teamId}
                          name={t.teamName}
                          {...{
                            disabled: t.teamId === hostTeamId ? true : false,
                          }}
                        >
                          {t.teamName} [{t.teamPlayersFullNames[0]}-
                          {t.teamPlayersFullNames[1]}]
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{guestTeamPlayers}</FormHelperText>
                  </FormControl>
                </Item>
              </Box>
            </Box>
            {/* Match time and Date */}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              <Box gridColumn="span 12">
                <Item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                      <DateTimePicker
                        clearable
                        value={matchTimeAndDate}
                        onChange={handleMatchDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText="Enter match date and time"
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Item>
              </Box>
            </Box>
          </Box>
          {/* Tournament Name */}
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 12">
              <Item>
                <Stack spacing={3}>{leaguesSelectionInputElement()}</Stack>
              </Item>
            </Box>
          </Box>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 12">
              <Item>
                <Stack spacing={3}>
                  <TextField
                    label="Game Court"
                    color="info"
                    value={gameCourt}
                    onChange={(e) => setGameCourt(e.target.value)}
                    focused
                  />
                </Stack>
              </Item>
            </Box>
          </Box>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 12">
              <Item>
                <Stack spacing={3}>
                  <TextField
                    label="additional comments ..."
                    color="info"
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                    focused
                  />
                </Stack>
              </Item>
            </Box>
          </Box>

          <>
            {BasicModal(
              openModel,
              {
                severity: submitButton.severity,
                message: submitButton.message,
              },
              'Submit new Schedule',
              setOpenModel,
            )}

            {
              <Button
                onClick={handleSubmit}
                fullWidth
                disabled={submitButton.severity === 'success'}
                variant="contained"
                disableElevation
                disabled={tournamentName?.length < 1}
                sx={{ mt: 3, mb: 2 }}
              >
                Submit Schedule:) :) {submitButton.severity}
              </Button>
            }
          </>
        </>
      )}
    </>
  )
}
