import {
  Avatar,
  Box,
  Card,

  Divider,
  Typography
} from '@mui/material'
// @mui
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React from 'react'
// components
import Image from '../components/common/Image'
import SvgIconStyle from '../components/common/SvgIconStyle'
// utils
import cssStyles from '../utils/cssStyles'
import { fShortenNumber } from '../utils/formatNumber'
import PlayerResultsGrid from './player.results'

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
  top: 0,
  zIndex: 8,
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
}))

// ----------------------------------------------------------------------

PlayerCard.propTypes = {
  player: PropTypes.object,
}

export default function PlayerCard({ player, results, loading }) {
  const {
    playerName = `${player?.firstName} ${player?.lastName}`,
    avatarImgUrl = 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/avatars/general.jpeg',
    teamName,
    totalMatchesPlayed,
    matchesWon,
    matchesLost,
    netGamesWon,
    netGamesLost,
    points,
    playerRating,
  } = player

  return (
    <Card sx={{ textAlign: 'center' }}>
      <Box sx={{ position: 'relative' }}>
        <SvgIconStyle
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg"
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            left: 0,
            right: 0,
            bottom: -26,
            mx: 'auto',
            position: 'absolute',
            color: 'background.paper',
          }}
        />
        <Avatar
          alt={playerName}
          src={avatarImgUrl}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            left: 0,
            right: 0,
            bottom: -32,
            mx: 'auto',
            position: 'absolute',
          }}
        />
        {/* <OverlayStyle /> */}
        <Image src={avatarImgUrl} alt={avatarImgUrl} ratio="16/9" />
      </Box>

      <Typography variant="subtitle1" sx={{ mt: 6 }}>
        {playerName}
      </Typography>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {teamName}
      </Typography>

      {/* <Stack alignItems="center">
        <SocialsButton initialColor sx={{ my: 2.5 }} />
      </Stack> */}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Box
        sx={{ py: 2, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}
      >
        <div>
          <Typography
            variant="caption"
            component="div"
            sx={{ mb: 0.75, color: 'text.disabled' }}
          >
            Total Matches
          </Typography>
          <Typography variant="subtitle1">
            {fShortenNumber(totalMatchesPlayed)}
          </Typography>
        </div>

        <div>
          <Typography
            variant="caption"
            component="div"
            sx={{ mb: 0.75, color: 'text.disabled' }}
          >
            Matches Lost
          </Typography>
          <Typography variant="subtitle1">
            {fShortenNumber(matchesLost)}
          </Typography>
        </div>

        <div>
          <Typography
            variant="caption"
            component="div"
            sx={{ mb: 0.75, color: 'text.disabled' }}
          >
            Matches Won
          </Typography>
          <Typography variant="subtitle1">
            {fShortenNumber(matchesWon)}
          </Typography>
        </div>
      </Box>
      <Box
        sx={{ py: 1, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}
      >
        <div>
          <Typography
            variant="caption"
            component="div"
            sx={{ mb: 0.75, color: 'text.disabled' }}
          >
            Total Point
          </Typography>
          <Typography variant="subtitle1">
            {fShortenNumber(points?.totalPoints)}
          </Typography>
        </div>

        <div>
          <Typography
            variant="caption"
            component="div"
            sx={{ mb: 0.75, color: 'text.disabled' }}
          >
           USTA Rating<sup>*</sup>
          </Typography>
          <Typography variant="subtitle1">
            {fShortenNumber(playerRating)}
          </Typography>
        </div>

        <div>
          <Typography
            variant="caption"
            component="div"
            sx={{ mb: 0.75, color: 'text.disabled' }}
          >
            Games Won/Lost
          </Typography>
          <Typography variant="subtitle1">
            {fShortenNumber(netGamesWon)}/{fShortenNumber(netGamesLost)}
          </Typography>
        </div>
      </Box>
      <Divider
        textAlign="center"
        style={{
          borderRadius: 5,
          borderWidth: 0.5,

          borderStyle: 'dashed',
        }}
      >
        Results
      </Divider>
      {
        <PlayerResultsGrid
          results={results}
          loading={loading}
        ></PlayerResultsGrid>
      }
    </Card>
  )
}
