import Grid from '@mui/material/Grid'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allResults, fetchResult } from '../matches/results/result.slice'
import { getPlayerFirstNames } from '../utils/utils'
import MathesResults from './MathesResults'

const darkTheme = createTheme({ palette: { mode: 'dark' } })

export default function Elevation() {
  const dispatch = useDispatch()
  const [tickerIndex, setTickerIndex] = useState(0)
  const [mappedResult, setMappedResult] = useState(null)
  const [mappedResults, setMappedResults] = useState([{}])
  const resultsFetchStatus = useSelector((state) => {
    return state?.results?.status
  })
  const results = useSelector(allResults)

  useEffect(() => {
    if (resultsFetchStatus === 'idle') {
      dispatch(fetchResult())
    }
    if (resultsFetchStatus === 'succeeded') {
      const getMappResults = results.slice(0, 5).map((m) => {
        return {
          matchStatus: m?.matchStatus,
          matchTimeAndDate: m?.matchTimeAndDate,
          hostPlayers: getPlayerFirstNames(m.hostTeamPlayers),
          guestPlayers: getPlayerFirstNames(m.guestTeamPlayers),
          hostName: m.hostTeam?.teamName,
          guestName: m.guestTeam?.teamName,
          hostPoints: m.hostTeam?.setOnePoints,
          guestPoints: m.guestTeam?.setOnePoints,
        }
      })
      // start/kick off with first result
      setMappedResult(getMappResults[1])
      setMappedResults(getMappResults)
    }
  }, [resultsFetchStatus, dispatch])

  const handleOnNext = () => {
    tickerIndex < 4 ? setTickerIndex(tickerIndex + 1) : setTickerIndex(0)
    setMappedResult(mappedResults[tickerIndex])
  }
  const resultView = () => {
    return (
      <Grid item xs={12} key={'index'}>
        <ThemeProvider theme={darkTheme}>
          {mappedResult?.matchStatus === 'completed' &&
            mappedResult?.hostName &&
            mappedResult?.guestName &&
            mappedResult?.hostPoints !== undefined &&
            mappedResult?.guestPoints !== undefined && (
              <MathesResults
                mappedResult={mappedResult}
                _onNext={handleOnNext}
              ></MathesResults>
            )}
        </ThemeProvider>
      </Grid>
    )
  }
  return results.length > 1 && mappedResult ? resultView() : null
}
