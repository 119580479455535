import React, { useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAuth0 } from '@auth0/auth0-react'
import Login from '@mui/icons-material/Login'
import { useSelector, useDispatch } from 'react-redux'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { useNavigate } from 'react-router-dom'
import { selectAllPlayers, fetchPlayers } from './players.slice'

import { getBaseUrl } from '../utils/utils'
import { Copyright, alertMessage } from '../utils/common.element'
import { FormHelperText } from '@mui/material'

const theme = createTheme()

export default function SignUpPlayer(props) {
  const {
    isAuthenticated,
    user,
    isLoading,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0()
  const [token, setToken] = React.useState(null)
  const navigate = useNavigate()
  const [firstName, setFirstName] = React.useState(
    props?.player?.firstName || '',
  )
  const [lastName, setLastName] = React.useState(props?.player?.lastName || '')

  const [friendFirstName, setFriendFirstName] = React.useState('')
  const [friendLastName, setFriendLastName] = React.useState('')

  const [email, setEmail] = React.useState(user?.email || '')
  const [phone, setPhone] = React.useState(props?.player?.phone || '')
  const [desc, setDesc] = React.useState(props?.player?.description || '')
  const [checked, setChecked] = React.useState(true)
  const [isChanged, setIsChanged] = React.useState(true)
  const [registerOpt, setRegisterOpt] = React.useState('yourself')

  const [playerInfo, setPlayerInfo] = React.useState()
  const dispatch = useDispatch()

  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)

  const handleFirstName = (event) => {
    setFirstName(event.target.value)
  }

  const handleLastName = (event) => {
    setLastName(event.target.value)
  }

  React.useEffect(() => {
    setEmail(user?.email)
    setRegisterOpt('friend')
  }, [user?.email])

  React.useEffect(() => {
    const playerInfo = allPlayers.find((p) => p.playerId === email)
    if (playerInfo) {
      setLastName(playerInfo?.lastName)
      setFirstName(playerInfo?.firstName)
      setEmail(playerInfo?.email)
      setPhone(playerInfo?.phone)
      setDesc(playerInfo?.description)
      setChecked(playerInfo?.allowMessage)
    }
    setPlayerInfo(playerInfo)
    checkChange()
  }, [email, allPlayers])

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
  }, [playersStatus, dispatch])

  useEffect(async () => {
    setToken(await getAccessTokenSilently())
  }, [isAuthenticated])

  const handlePhone = (event) => {
    setPhone(event.target.value)
  }

  const handleDesc = (event) => {
    setDesc(event.target.value)
  }
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [submitButton, setSubmitButton] = React.useState({
    message: 'Submit your details',
    severity: null,
  })

  const friendPlayer = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const player = {
      firstName: data.get('friendFirstName'),
      lastName: data.get('friendLastName'),
      playerId: `${data.get('friendFirstName')}-${data.get(
        'friendLastName',
      )}`.toLocaleLowerCase(),
      createdBy: user?.email,
      createdAt: new Date().toISOString(),
      email: null,
      phone: null,
      description: null,
      allowMessage: false,
    }
    return player
  }
  const yourSelfPlayer = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const player = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: user?.email,
      playerId: user?.email,
      phone: data.get('phone'),
      description: data.get('description'),
      allowMessage: checked,
    }
    if (props?.player?.playerId) {
      player['teamId'] = props?.player?.playerId
    }
    return player
  }
  const handleSubmit = (event) => {
    const player =
      registerOpt === 'yourself' ? yourSelfPlayer(event) : friendPlayer(event)
    const baseUrl = getBaseUrl()
    console.log('player ', player)
    fetch(`${baseUrl}players`, {
      method: 'POST',
      body: JSON.stringify(player),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        response.json()
        setIsSubmitted(false)
      })
      .then((response) => {
        setIsSubmitted(true)
        // success = undefined
        if (!response) {
          setSubmitButton({
            message: 'Player is successfully registered.',
            severity: 'success',
          })
        }
        if (response === 409) {
          setSubmitButton({
            message: 'Name is already in used, use different name',
            severity: 'error',
          })
        }
      })
      .catch((error) => {
        setIsSubmitted(true)
        setSubmitButton({
          message: 'oops ... something went wrong, please try later.',
          severity: 'error',
        })
        console.error('Player registration Error:', error)
      })
  }

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked)
  }

  const checkChange = () => {
    if (
      lastName === playerInfo?.lastName ||
      firstName === playerInfo?.firstName ||
      email === playerInfo?.email ||
      phone === playerInfo?.phone ||
      desc === playerInfo?.description ||
      checked === playerInfo?.allowMessage
    ) {
      setIsChanged(false)
    } else {
      setIsChanged(true)
    }
  }
  const handleChange = (event) => {
    console.log(event.target.value)
    setRegisterOpt(event.target.value)
  }

  const registerOptions = () => {
    return (
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={registerOpt}
          onChange={handleChange}
        >
          <FormControlLabel
            value="yourself"
            control={<Radio />}
            label="Add your details"
          />
          <FormControlLabel
            value="friend"
            control={<Radio />}
            label="Add friend details"
          />
          {/* <FormControlLabel
            value="disabled"
            disabled
            control={<Radio />}
            label="other"
          /> */}
        </RadioGroup>
      </FormControl>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <SportsHandballIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            {/* {playerInfo?.email ? "Update your details " : "Register Player"} */}
            {isAuthenticated && registerOptions()}
          </Typography>
          {!isAuthenticated && !isLoading && (
            <>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={10}>
                      <Grid item xs={12}></Grid>
                      {/* <Grid item xs={12}>
                        <Button
                          fullWidth
                          color="info"
                          variant="contained"
                          sx={{ backgroundColor: "#0e57dd" }}
                        >
                          login to continue
                        </Button>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          href="#outlined-buttons"
                          color="warning"
                          fullWidth
                          // onClick={loginWithPopup}
                          sx={{ backgroundColor: '#5c38cb' }}
                          endIcon={<Login />}
                        >
                          Log In/Sign up to register
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          {isAuthenticated && !isLoading && (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                {registerOpt === 'yourself' ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        onChange={handleFirstName}
                        value={firstName}
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                      />
                      <Grid item lg={12}>
                        <FormHelperText id="friend-first-helper-text">
                          Use legal name and avoid special characters.
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        onChange={handleLastName}
                        value={lastName}
                        name="lastName"
                        autoComplete="family-name"
                      />
                      <Grid item lg={12}>
                        <FormHelperText id="friend-first-helper-text">
                          Use legal name and avoid special characters.
                        </FormHelperText>
                      </Grid>
                    </Grid>

                    {email && (
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          value={email}
                          disabled={true}
                          name="email"
                          autoComplete="email"
                          label="Email"
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="phone"
                        label="Phone"
                        type="phone"
                        onChange={handlePhone}
                        value={phone}
                        id="phone"
                        // autoComplete="new-phone"
                      />
                      <FormHelperText id="friend-first-helper-text">
                        Please provide your WhatsApp number for communication.
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="description"
                        label="Fun fact about you"
                        placeholder="say something about"
                        type="text"
                        onChange={handleDesc}
                        value={desc}
                        id="description"
                        // autoComplete="new-phone"
                      />
                    </Grid>
                    {/* {!!phone && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="allowMessage"
                          checked={checked}
                          onChange={handleCheckBoxChange}
                          color="primary"
                        />
                      }
                      label="I want to receive tennis matches update via whatsapp group chat"
                    />
                  </Grid>
                )} */}
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        name="friendFirstName"
                        onChange={(e) => setFriendFirstName(e.target.value)}
                        value={friendFirstName}
                        id="friendFirstName"
                        label="Friend First Name"
                        autoFocus
                      />
                      <Grid item lg={12}>
                        <FormHelperText id="friend-first-helper-text">
                          Use legal name and avoid special characters.
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="friendLastName"
                        onChange={(e) => setFriendLastName(e.target.value)}
                        value={friendLastName}
                        name="friendLastName"
                        label="Friend Last Name"
                      />
                      <Grid item lg={12}>
                        <FormHelperText id="friend-last-helper-text">
                          Use legal name and avoid special characters.
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>

              {submitButton.severity &&
                alertMessage(
                  submitButton.severity,
                  submitButton.message,
                  navigate,
                )}
              {submitButton.severity !== 'success' && (
                <Button
                  disabled={
                    isSubmitted ||
                    !(firstName || friendFirstName) ||
                    !(lastName || friendLastName) ||
                    !isChanged
                  }
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {true ? 'Submit details' : 'Nothing to new to submit'}
                </Button>
              )}
              {submitButton.severity !== 'success' && (
                <Button
                  href="/"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Cancel
                </Button>
              )}
            </Box>
          )}
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}
