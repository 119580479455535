import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled, StyledEngineProvider } from "@mui/material/styles";
import React from "react";
import ReactPlayer from "react-player";
import Advertisement from "../components/common/Advertisement";
import PhotoCarousel from "../components/common/PhotoCarousel";
import QuickMenu from "../components/quick-menu";
import Quotes from "../components/Quotes";
import SeasonTimeLine from "../components/SeasonTimeLine/SeasonTimeLine";
import LatestUpdates from "../components/update";
import TopPlayers from "../players/championships.players";
import PlayersRatting from "../players/player.ranking.summary";
import PlayersBarChart from "../players/players.barchart";
import PlayersCarousel from "../players/players.carousel";
import {
  IMAGES_URL_1,
  IMAGES_URL_2,
  SPRING_2024_URLS,
  SUMMER_2023_DINNER_URLS,
  SUMMER_2023_URLS,
} from "../static/images/imagesData";
import { getWindowDimensions } from "../utils/utils";
import HomeBanner from "./HomeBanner";
import HomePageNews from "../components/news-alerts/HomePageNews";

export default function PersistentDrawerLeft() {
  const [showVideo, setShowVideo] = React.useState(false);
  const { width } = getWindowDimensions();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: 1,
    textAlign: "center",
    color: theme.palette.text.primary,
  }));

  return (
    <Box width={width}>
      <StyledEngineProvider>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <QuickMenu></QuickMenu>
            {/* <Item>
              <HomePageNews />
            </Item> */}
            <Item>
              <HomeBanner></HomeBanner>
            </Item>
            <Item>
              <Quotes></Quotes>
            </Item>
            <Item>
              <LatestUpdates />
            </Item>
            {/* <Item>
              <Link href="/contactus">
                <ElevationTile tileContent={'Contact Us'} />
              </Link>
            </Item> */}
            <Grid container spacing={0.3}>
              <Grid item xs={12} md={6}>
                <Item>
                  <SeasonTimeLine />
                </Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item>
                  <PhotoCarousel images={SPRING_2024_URLS} />
                </Item>
              </Grid>
            </Grid>
            {/* <Item>
              <NewsHeader />
            </Item> */}
            <Item>
              <PlayersBarChart />
            </Item>
            {/* <Item>
              <EventCountdown />
            </Item> */}
            <Item>
              <PhotoCarousel images={SUMMER_2023_URLS} />
            </Item>
            <Item>
              <PhotoCarousel images={SUMMER_2023_DINNER_URLS} />
            </Item>
            <Item>
              <Advertisement />
            </Item>
          </Grid>
        </Grid>
        <Grid container spacing={0.3}>
          <Grid item xs={12} md={6}>
            <Item>
              <PlayersCarousel />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <PlayersRatting setShowVideo={setShowVideo} />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <TopPlayers />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              {showVideo && (
                <ReactPlayer
                  className="react-player fixed-bottom"
                  url="https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/videos/join-us.mp4"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              )}
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              overflowY: "auto",
              maxHeight: "516px",
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            <Item>
              {showVideo && (
                <ReactPlayer
                  className="react-player fixed-bottom"
                  url="https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/videos/up-coming-events.mp4"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              )}
            </Item>
          </Grid>
        </Grid>
        <Grid container spacing={0.5}>
          <Grid item xs={12} md={6}>
            <Item>
              <PhotoCarousel images={IMAGES_URL_1} />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <PhotoCarousel images={IMAGES_URL_2} />
            </Item>
          </Grid>
        </Grid>
      </StyledEngineProvider>
    </Box>
  );
}
