import PropTypes from 'prop-types'
import React, { useState } from 'react'
// @mui
import { Box, Grid, Card, Button, Avatar, Typography } from '@mui/material'
// components
import Iconify from '../components/common/Iconify'

// ----------------------------------------------------------------------

PlayersProfile.propTypes = {
  followers: PropTypes.array,
}

const p = [
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_1.jpg',
    name: 'Jayvion Simon',
    country: 'Kenya',
    isFollowed: true,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg',
    name: 'Lucian Obrien',
    country: 'Madagascar',
    isFollowed: true,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg',
    name: 'Deja Brady',
    country: 'Netherlands Antilles',
    isFollowed: true,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg',
    name: 'Harrison Stein',
    country: 'Azerbaijan',
    isFollowed: false,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg',
    name: 'Reece Chung',
    country: 'Aruba',
    isFollowed: false,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_6.jpg',
    name: 'Lainey Davidson',
    country: 'Comoros',
    isFollowed: true,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_7.jpg',
    name: 'Cristopher Cardenas',
    country: 'Sierra Leone',
    isFollowed: false,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_8.jpg',
    name: 'Melanie Noble',
    country: 'Bermuda',
    isFollowed: false,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_9.jpg',
    name: 'Chase Day',
    country: 'Italy',
    isFollowed: false,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_10.jpg',
    name: 'Shawn Manning',
    country: 'Iran',
    isFollowed: false,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_11.jpg',
    name: 'Soren Durham',
    country: 'Denmark',
    isFollowed: true,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b12',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_12.jpg',
    name: 'Cortez Herring',
    country: 'Congo',
    isFollowed: true,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_13.jpg',
    name: 'Brycen Jimenez',
    country: 'Cambodia',
    isFollowed: true,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b14',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_14.jpg',
    name: 'Giana Brandt',
    country: 'Virgin Islands, British',
    isFollowed: false,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b15',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
    name: 'Aspen Schmitt',
    country: 'Bahamas',
    isFollowed: false,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b16',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_16.jpg',
    name: 'Colten Aguilar',
    country: 'Italy',
    isFollowed: false,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_17.jpg',
    name: 'Angelique Morse',
    country: 'France',
    isFollowed: true,
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b18',
    avatarUrl:
      'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_18.jpg',
    name: 'Selina Boyer',
    country: 'Portugal',
    isFollowed: false,
  },
]
export default function PlayersProfile({ followers = p }) {
  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Players
      </Typography>

      <Grid container spacing={0}>
        {followers.map((follower) => (
          <Grid key={follower.id} item xs={12} md={4}>
            <FollowerCard follower={follower} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

// ----------------------------------------------------------------------

FollowerCard.propTypes = {
  follower: PropTypes.object,
}

function FollowerCard({ follower }) {
  const { name, country, avatarUrl, isFollowed } = follower

  const [toggle, setToogle] = useState(isFollowed)

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Avatar alt={name} src={avatarUrl} sx={{ width: 48, height: 48 }} />

      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Iconify
            icon={'eva:pin-fill'}
            sx={{ width: 16, height: 16, mr: 0.5, flexShrink: 0 }}
          />
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {country}
          </Typography>
        </Box>
      </Box>

      <Button
        size="small"
        onClick={() => setToogle(!toggle)}
        variant={toggle ? 'text' : 'outlined'}
        color={toggle ? 'primary' : 'inherit'}
        startIcon={toggle && <Iconify icon={'eva:checkmark-fill'} />}
        sx={{ flexShrink: 0 }}
      >
        {toggle ? 'Followed' : 'Follow'}
      </Button>
    </Card>
  )
}
