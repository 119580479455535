import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { useDemoData } from '@mui/x-data-grid-generator'
import { width } from 'dom-helpers'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}))

class Columns {
  constructor(field, headerName, width, type, hide) {
    this.field = field
    this.headerName = headerName
    this.width = width || 100
    this.type = type || 'string'
    this.hide = hide || false
  }
}
const columns = [
  new Columns('id', 'PlayerId', 50, null, true),
  new Columns('matchDate', 'Date', null, 'date', null),
  new Columns('playingWithPlayers', 'Partners', 140, null, null),
  new Columns('playingAgainstPlayers', 'Opponents', 140, null, null),
  new Columns('gamesWon', 'Games Won', 60, null, null),
  new Columns('gamesLost', 'Games Lost', 60, null, null),
  new Columns('matchResult', 'Result', 80, null, null),
  new Columns('teams', 'Teams', 200, null, null),
  new Columns('playingWithTeam', 'Teams', 100, null, true),
  new Columns('playingAgainstTeam', 'Vs Teams', 100, null, true),
  new Columns('winningTeam', 'Winning Team', null, null, null),
  new Columns('winningTeamPlayer', 'Winning Team Players', 120, null, null),
  new Columns('matchType', 'Type', null, null, null),
  new Columns('matchSetSize', 'Set Size', 60, null, null),
  new Columns('matchStatus', 'Match Status', 90, null, null),
  new Columns('resultComments', 'Comments', null, null, null),
]
const rows = [
  {
    isHost: true,
    isGuest: false,
    matchResult: 'WON',
    id: 82,
    matchStatus: 'completed',
    matchDate: '2022-10-20T21:15:26-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Irfan Patel - Syed Ali',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'The Dragons',
    gamesWon: 6,
    gamesLost: 0,
    winningTeam: 'Gladiators',
    winningTeamPlayer: 'Badar Usmani - Asif Hasan',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: 'WON',
    id: 80,
    matchStatus: 'completed',
    matchDate: '2022-10-20T20:30:01-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Irfan Patel - Syed Ali',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'The Dragons',
    gamesWon: 6,
    gamesLost: 0,
    winningTeam: 'Gladiators',
    winningTeamPlayer: 'Badar Usmani - Asif Hasan',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: 'LOST',
    id: 77,
    matchStatus: 'completed',
    matchDate: '2022-10-18T21:15:14-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Talha Khan - Umair Rahim',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'SmashBros',
    gamesWon: 6,
    gamesLost: 8,
    winningTeam: 'SmashBros',
    winningTeamPlayer: 'Talha Khan - Umair Rahim',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: 'WON',
    id: 76,
    matchStatus: 'completed',
    matchDate: '2022-10-18T20:30:52-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Talha Khan - Umair Rahim',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'SmashBros',
    gamesWon: 6,
    gamesLost: 4,
    winningTeam: 'Gladiators',
    winningTeamPlayer: 'Badar Usmani - Asif Hasan',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: 'WON',
    id: 66,
    matchStatus: 'completed',
    matchDate: '2022-10-15T08:45:00-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Akash Oberoi - Asim Ahmed',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'Mario Bros',
    gamesWon: 9,
    gamesLost: 7,
    winningTeam: 'Gladiators',
    winningTeamPlayer: 'Badar Usmani - Asif Hasan',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: 'LOST',
    id: 65,
    matchStatus: 'completed',
    matchDate: '2022-10-15T07:00:00-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Akash Oberoi - Asim Ahmed',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'Mario Bros',
    gamesWon: 5,
    gamesLost: 7,
    winningTeam: 'Mario Bros',
    winningTeamPlayer: 'Akash Oberoi - Asim Ahmed',
  },
  {
    isHost: false,
    isGuest: true,
    matchResult: 'WON',
    id: 56,
    matchStatus: 'completed',
    matchDate: '2022-10-11T21:15:00-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Zulifqar Ahmad - Sameer Sharma',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'Avengers',
    gamesWon: 6,
    gamesLost: 3,
    winningTeam: 'Gladiators',
    winningTeamPlayer: 'Badar Usmani - Asif Hasan',
  },
  {
    isHost: false,
    isGuest: true,
    matchResult: 'WON',
    id: 55,
    matchStatus: 'completed',
    matchDate: '2022-10-11T20:30:00-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Zulifqar Ahmad - Sameer Sharma',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'Avengers',
    gamesWon: 6,
    gamesLost: 1,
    winningTeam: 'Gladiators',
    winningTeamPlayer: 'Badar Usmani - Asif Hasan',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: 'WON',
    id: 48,
    matchStatus: 'completed',
    matchDate: '2022-10-04T21:15:29-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Jawad Dakhan - Muhammad  Waseem',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'Blasters',
    gamesWon: 6,
    gamesLost: 0,
    winningTeam: 'Gladiators',
    winningTeamPlayer: 'Badar Usmani - Asif Hasan',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: 'WON',
    id: 49,
    matchStatus: 'completed',
    matchDate: '2022-10-04T21:15:29-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Jawad Dakhan - Muhammad  Waseem',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'Blasters',
    gamesWon: 6,
    gamesLost: 2,
    winningTeam: 'Gladiators',
    winningTeamPlayer: 'Badar Usmani - Asif Hasan',
  },
  {
    isHost: false,
    isGuest: true,
    matchResult: 'WON',
    id: 45,
    matchStatus: 'completed',
    matchDate: '2022-09-21T20:15:47-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asim Ahmed',
    playingAgainstPlayers: 'Majid  Atique  - Aman Sethi',
    playingWithTeam: 'Technical Masters',
    playingAgainstTeam: 'Dil Jaley',
    gamesWon: 9,
    gamesLost: 3,
    winningTeam: 'Technical Masters',
    winningTeamPlayer: 'Badar Usmani - Asim Ahmed',
  },
  {
    isHost: false,
    isGuest: true,
    matchResult: undefined,
    id: 42,
    matchStatus: 'completed',
    matchDate: '2022-08-10T20:35:00-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asim Ahmed',
    playingAgainstPlayers: 'Zulifqar Ahmad - Talha Khan',
    playingWithTeam: 'Technical Masters',
    playingAgainstTeam: 'Delta',
    gamesWon: 9,
    gamesLost: 5,
    winningTeam: 'Technical Masters',
    winningTeamPlayer: 'Badar Usmani - Asim Ahmed',
  },
  {
    isHost: false,
    isGuest: true,
    matchResult: undefined,
    id: 40,
    matchStatus: 'completed',
    matchDate: '2022-07-21T20:15:45-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Badar Usmani - Asif Hasan',
    playingAgainstPlayers: 'Zulifqar Ahmad - Jawad Zubairi',
    playingWithTeam: 'Gladiators',
    playingAgainstTeam: 'Breaking Bad',
    gamesWon: 7,
    gamesLost: 9,
    winningTeam: 'Breaking Bad',
    winningTeamPlayer: 'Zulifqar Ahmad - Jawad Zubairi',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 39,
    matchStatus: 'completed',
    matchDate: '2022-07-03T19:30:06-05:00',
    matchType: 'Double',
    matchSetSize: 2,
    resultComments: 'Final - 🏆 Champions ',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Adeel Taseer - Muhammad Ali Taseer',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Legends',
    gamesWon: 13,
    gamesLost: 8,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 37,
    matchStatus: 'completed',
    matchDate: '2022-07-01T19:30:00-05:00',
    matchType: 'Double',
    matchSetSize: 3,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Talha Khan - Umair Rahim',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'SmashBros',
    gamesWon: 16,
    gamesLost: 10,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 34,
    matchStatus: 'draw',
    matchDate: '2022-06-25T20:30:39-05:00',
    matchType: 'Double',
    matchSetSize: 2,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Asim Ahmed - Asif Hasan',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Ball Crackers',
    gamesWon: 8,
    gamesLost: 7,
    winningTeam: undefined,
    winningTeamPlayer: {},
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 30,
    matchStatus: 'completed',
    matchDate: '2022-06-21T05:00:00.000Z',
    matchType: 'Double',
    matchSetSize: 2,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Jawad Zubairi - Muhammad Ali Taseer',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Volley Boys',
    gamesWon: 6,
    gamesLost: 12,
    winningTeam: 'Volley Boys',
    winningTeamPlayer: 'Jawad Zubairi - Muhammad Ali Taseer',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 27,
    matchStatus: 'completed',
    matchDate: '2022-06-19T06:45:00-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Majid  Atique  - Aman Sethi',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Dil Jaley',
    gamesWon: 8,
    gamesLost: 6,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 21,
    matchStatus: 'completed',
    matchDate: '2022-06-18T20:30:11-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Jawad Zubairi - Hassan Tariq',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Champs',
    gamesWon: 6,
    gamesLost: 2,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 24,
    matchStatus: 'completed',
    matchDate: '2022-06-17T20:30:06-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Jawad Zubairi - Muhammad Ali Taseer',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Volley Boys',
    gamesWon: 7,
    gamesLost: 9,
    winningTeam: 'Volley Boys',
    winningTeamPlayer: 'Jawad Zubairi - Muhammad Ali Taseer',
  },
  {
    isHost: false,
    isGuest: true,
    matchResult: undefined,
    id: 17,
    matchStatus: 'completed',
    matchDate: '2022-06-13T20:30:16-05:00',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Adeel Taseer - Muhammad Ali Taseer',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Legends',
    gamesWon: 6,
    gamesLost: 1,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 15,
    matchStatus: 'completed',
    matchDate: '2022-06-10T03:23:00.184Z',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Jawad Zubairi - Hassan Tariq',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Champs',
    gamesWon: 9,
    gamesLost: 7,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 11,
    matchStatus: 'completed',
    matchDate: '2022-06-07T03:38:08.389Z',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Majid  Atique  - Aman Sethi',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Dil Jaley',
    gamesWon: 6,
    gamesLost: 1,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 6,
    matchStatus: 'completed',
    matchDate: '2022-06-03T03:43:11.309Z',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Talha Khan - Umair Rahim',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'SmashBros',
    gamesWon: 9,
    gamesLost: 7,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 5,
    matchStatus: 'completed',
    matchDate: '2022-06-03T03:41:59.142Z',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Talha Khan - Umair Rahim',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'SmashBros',
    gamesWon: 6,
    gamesLost: 3,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 4,
    matchStatus: 'completed',
    matchDate: '2022-06-01T03:21:33.067Z',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Asim Ahmed - Muhammad  Waseem',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Technical Topi',
    gamesWon: 6,
    gamesLost: 2,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 3,
    matchStatus: 'completed',
    matchDate: '2022-06-01T03:20:52.430Z',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'none',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Asim Ahmed - Muhammad  Waseem',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Technical Topi',
    gamesWon: 6,
    gamesLost: 4,
    winningTeam: 'Warriors',
    winningTeamPlayer: 'Zulifqar Ahmad - Badar Usmani',
  },
  {
    isHost: true,
    isGuest: false,
    matchResult: undefined,
    id: 1,
    matchStatus: 'completed',
    matchDate: '2022-05-30T03:22:43.923Z',
    matchType: 'Double',
    matchSetSize: 1,
    resultComments: 'correction',
    playingWithPlayers: 'Zulifqar Ahmad - Badar Usmani',
    playingAgainstPlayers: 'Adeel Taseer - Muhammad Ali Taseer',
    playingWithTeam: 'Warriors',
    playingAgainstTeam: 'Legends',
    gamesWon: 4,
    gamesLost: 6,
    winningTeam: 'Legends',
    winningTeamPlayer: 'Adeel Taseer - Muhammad Ali Taseer',
  },
]
export default function StripedGrid({ results, loading }) {
  console.log('###results ', results, 'loading ', loading)
  // let { data, loading } = useDemoData({
  //   dataSet: 'Employee',
  //   rowLength: 200,
  // })
  const data = { columns, rows: results || [] }
  // console.log('data ', data.columns[0], data.rows[0])

  return (
    <div style={{ height: 300, width: '100%' }}>
      <StripedDataGrid
        loading={loading}
        {...data}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
    </div>
  )
}
