import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { orderBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { selectAllPlayers, fetchPlayers } from '../players/players.slice'
import PlayerCard from './PlayerCard'
import { calculatePoints, calculateRatings } from '../utils/utils'
import { getResultsByPlayers } from '../utils/players'
import { allResults, fetchResult } from '../matches/results/result.slice'

// ----------------------------------------------------------------------

PlayersCard.propTypes = {
  user: PropTypes.object,
}

export default function PlayersCard() {
  const [resultsByPlayers, setResultsByPlayers] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [playerMapped, setPlayerMapped] = React.useState([])
  const results = useSelector(allResults)
  const dispatch = useDispatch()
  const resultsFetchStatus = useSelector((state) => {
    return state?.results?.status
  })

  useEffect(() => {
    if (resultsFetchStatus === 'idle') {
      setLoading(true)
      dispatch(fetchResult())
    }
    if (resultsFetchStatus === 'succeeded') {
      console.log('results ', results.length)
      setResultsByPlayers(getResultsByPlayers(results))
      setLoading(false)
    }
  }, [resultsFetchStatus, dispatch])

  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
    if (playersStatus === 'succeeded') {
      const players = allPlayers.map((player) => {
        const calculatedInfo = calculatePoints(player) || 0
        const playerRating = calculateRatings(calculatedInfo?.totalPoints)
        return {
          ...player,
          ...{ points: calculatedInfo, playerRating },
        }
      })
      const orderedPlayer = orderBy(players, ['playerRating'], ['desc'])

      setPlayerMapped(orderedPlayer)
    }
  }, [playersStatus, dispatch])

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 0.5,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        },
      }}
    >
      {playerMapped.map((alpha, i) => {
        return (
          <PlayerCard
            key={i}
            player={alpha}
            results={resultsByPlayers[alpha?.playerId]}
            loading={loading}
          />
        )
      })}
    </Box>
  )
}
