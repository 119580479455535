import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import { Chip } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React, { useEffect, useState } from 'react'
import { WEEK_DAYS } from '../../utils/constants'

const DaysCheckboxList = ({ title, isPreferred, formData, setFormData }) => {
  const [selectedDays, setSelectedDays] = useState([])
  const [disabledDays, setDisabledDays] = useState([])

  useEffect(() => {
    if (isPreferred) {
      setDisabledDays(formData.unFavorableDays)
    } else {
      setDisabledDays(formData.favorableDays)
    }
  }, [isPreferred, formData])

  const handleToggle = (value) => () => {
    let newSelectedDays = [...selectedDays]

    if (newSelectedDays.includes(value)) {
      newSelectedDays = newSelectedDays.filter((day) => day !== value)
    } else {
      newSelectedDays.push(value)
    }

    setSelectedDays(newSelectedDays)

    const favorableDays = 'favorableDays'
    const unFavorableDays = 'unFavorableDays'

    if (isPreferred) {
      setFormData((prevData) => ({
        ...prevData,
        [favorableDays]: newSelectedDays,
        [unFavorableDays]: prevData[unFavorableDays].filter(
          (day) => day !== value,
        ),
      }))
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [unFavorableDays]: newSelectedDays,
        [favorableDays]: prevData[favorableDays].filter((day) => day !== value),
      }))
    }
  }


  return (
    <div>
      <Chip
        color={isPreferred ? 'secondary' : 'secondary'}
        label={title}
        size="medium"
        icon={
          isPreferred ? (
            <SentimentSatisfiedAltIcon color="success" />
          ) : (
            <SentimentVeryDissatisfiedIcon color="warning" />
          )
        }
        variant="outlined"
        sx={{
          height: 'auto',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
          },
        }}
      />
      <List>
        {WEEK_DAYS.map(
          (day) =>
            !disabledDays.includes(day.id) && (
              <ListItem key={day.id} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(day.id)}
                  disabled={disabledDays.includes(day.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedDays.includes(day.id)}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': `checkbox-list-label-${day.name}`,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={day.name} />
                </ListItemButton>
              </ListItem>
            ),
        )}
      </List>
    </div>
  )
}

export default DaysCheckboxList
