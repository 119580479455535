// import * as React from "react";
import React, { useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getBaseUrl, getCamelCaseString } from '../utils/utils'
import { Spinner } from '../components/Spinner'
import TextField from '@mui/material/TextField'
import { useAuth0 } from '@auth0/auth0-react'
import LinearProgress from '@mui/material/LinearProgress'
import Login from '@mui/icons-material/Login'
import { useNavigate } from 'react-router-dom'
import { Copyright, alertMessage } from '../utils/common.element'
import { Fab } from '@mui/material'
import { TOURNAMENTS } from '../utils/constants'

const theme = createTheme()

export default function RuleSubmit(props) {
  const {
    isAuthenticated,
    user,
    isLoading,
    loginWithPopup,
    error,
    getAccessTokenSilently,
  } = useAuth0()
  const [token, setToken] = React.useState(null)
  const navigate = useNavigate()
  const [ruleDetails, setRuleDetails] = React.useState({
    tournamentName: TOURNAMENTS[0],
    rulesCategory: 'in-house',
    rulesState: 'permanent',
    ruleTitle: props?.rule?.ruleTitle,
    ruleShortDesc: props?.rule?.ruleShortDesc,
    ruleFullDesc: props?.rule?.ruleFullDesc,
    ruleName: props?.rule?.ruleName,
  })

  const [submitButton, setSubmitButton] = React.useState({
    message: 'Submit rule',
    severity: null,
  })

  useEffect(() => {
    async function _setToken() {
      setToken(await getAccessTokenSilently())
    }
    _setToken()
  }, [isAuthenticated])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (props && Object.keys(props).length !== 0) {
      ruleDetails['updatedBy'] = user?.email
      ruleDetails['updatedAt'] = new Date().toISOString()
      ruleDetails.ruleId = props.rule?.ruleId
    } else {
      ruleDetails['createdBy'] = user?.email
      ruleDetails['createdAt'] = new Date().toISOString()
    }

    ruleDetails.active = true
    const body = ruleDetails
    const baseUrl = getBaseUrl()
    fetch(`${baseUrl}rules`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('Response from backend: ', response)
        if (response['ruleId']) {
          setSubmitButton({
            message: 'Rule is successfully registered.',
            severity: 'success',
          })
        }
        if (response === 409) {
          setSubmitButton({
            message: 'Rule is already in submitted, add new rule',
            severity: 'error',
          })
        }
      })
      .catch((error) => {
        console.error('Registration Error:', error)
        setSubmitButton({
          message: 'oops ... something went wrong, please try later.',
          severity: 'error',
        })
      })
  }
  const handleChange = (name, value) => {
    setRuleDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (name === 'ruleTitle') {
      setRuleDetails((prevState) => ({
        ...prevState,
        ['ruleName']: getCamelCaseString(value),
      }))
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {isLoading && (
        <>
          <Spinner text="Loading players data for teams..." />
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </>
      )}
      {!isLoading && !isAuthenticated && (
        <>
          <Typography component="h1" variant="h5">
            First you need to login to submit rule
          </Typography>
          <Fab
            variant="extended"
            onClick={loginWithPopup}
            color="success"
            size="small"
          >
            <Login sx={{ mr: 5 }} color="warning" size="small" />
            Log in
          </Fab>
        </>
      )}
      {error && <Spinner text={`something went wrong ...${error}`} />}
      {isAuthenticated && (
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <>
              {isAuthenticated && (
                <Typography component="h1" variant="h5">
                  Submit Rule
                </Typography>
              )}
              {!isAuthenticated && (
                <>
                  <Typography component="h1" variant="h5">
                    First you need to login to submit rule
                  </Typography>
                  <Fab
                    variant="extended"
                    onClick={loginWithPopup}
                    color="success"
                  >
                    <Login sx={{ mr: 1 }} />
                    Log in
                  </Fab>
                </>
              )}
            </>
          </Box>
          {isAuthenticated && (
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="ruleName"
                    label="Rule Name"
                    type="text"
                    value={ruleDetails?.name}
                    id="ruleName"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="ruleTitle"
                    label="Rule Title"
                    type="text"
                    value={ruleDetails?.ruleTitle}
                    id="ruleTitle"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="ruleShortDesc"
                    label="Short Description"
                    type="text"
                    value={ruleDetails?.ruleShortDesc}
                    id="ruleShortDesc"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="ruleFullDesc"
                    label="Full Description"
                    type="text"
                    value={ruleDetails?.ruleFullDesc}
                    id="ruleFullDesc"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="rulesCategory"
                    label="Rule Category"
                    type="text"
                    value={ruleDetails?.rulesCategory}
                    id="rulesCategory"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="tournamentName"
                    label="Tournament Name"
                    type="text"
                    value={ruleDetails?.tournamentName}
                    id="tournamentName"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Grid>
              </Grid>
              {submitButton.severity &&
                alertMessage(
                  submitButton.severity,
                  submitButton.message,
                  navigate,
                )}
              {submitButton.severity !== 'success' && (
                <Button
                  onClick={handleSubmit}
                  fullWidth
                  disabled={
                    submitButton.severity === 'success' ||
                    !ruleDetails['ruleName'] ||
                    !ruleDetails['ruleTitle'] ||
                    !ruleDetails['ruleFullDesc'] ||
                    !ruleDetails['ruleShortDesc']
                  }
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit Rule -:)
                </Button>
              )}
            </Box>
          )}
          <Copyright sx={{ mt: 5 }} />
        </Container>
      )}
    </ThemeProvider>
  )
}
