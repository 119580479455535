import { CardHeader, Grid, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import champs from "../../players/champions.json"


const columns = [
  { id: 'championshipName', label: 'Championship', minWidth: 150 },

  {
    id: 'winnerPlayers',
    label: 'Winners',
    minWidth: 140,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'runnerUpPlayers',
    label: 'Runner-up',
    minWidth: 140,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  { id: 'winnerPoints', label: 'Winners', minWidth: 130, align: 'left' },
  { id: 'runnerUpPoints', label: 'Runner-up', minWidth: 130 },
]

function createData(
  id,
  championshipName,
  winnerTeamName,
  winnerPlayerOne,
  winnerPlayerTwo,
  runnerUpPlayerOne,
  runnerUpPlayerTwo,
  winnerPointsSetOne = 'NA',
  winnerPointsSetTwo = 'NA',
  winnerPointsSetThree = 'NA',
  runnerUpPointsSetOne = 'NA',
  runnerUpPointsSetTwo = 'NA',
  runnerUpPointsSetThree = 'NA',
) {
  return {
    id,
    championshipName,
    winnerTeamName,
    winnerPlayers: `${winnerPlayerOne} - ${winnerPlayerTwo}`,
    runnerUpPlayers: `${runnerUpPlayerOne} - ${runnerUpPlayerTwo}`,
    winnerPoints: [
      winnerPointsSetOne,
      winnerPointsSetTwo,
      winnerPointsSetThree,
    ].map((e, i) => (
      <>
        {e} {i != 2 ? '|' : ''}{' '}
      </>
    )),
    runnerUpPoints: [
      runnerUpPointsSetOne,
      runnerUpPointsSetTwo,
      runnerUpPointsSetThree,
    ].map((e, i) => (
      <>
        {e} {i != 2 ? '|' : ''}{' '}
      </>
    )),
  }
}

const rows = []
const getScores = (s, g) => (
  <>
    {s}
    <sup>{g >= 0 ? g : '0'}</sup>{' '}
  </>
)
champs.forEach((ele) => {
  console.log('ele.winnerPointsSetOne ', ele.winnerPointsSetOne)
  const item = createData(
    ele.id,
    ele.championshipName,
    ele.winnerTeamName,
    ele.winnerPlayerOne,
    ele.winnerPlayerTwo,
    ele.runnerUpPlayerOne,
    ele.runnerUpPlayerTwo,
    getScores(1, ele.winnerPointsSetOne),
    getScores(2, ele.winnerPointsSetTwo),
    getScores(3, ele.winnerPointsSetThree),
    getScores(1, ele.runnerUpPointsSetOne),
    getScores(2, ele.runnerUpPointsSetTwo),
    getScores(3, ele.runnerUpPointsSetThree),
    // supGames(1, ele.winnerPointsSetOne >= 0 ? ele.winnerPointsSetOne : 'NA'),
    // supGames(2, ele.winnerPointsSetTwo >= 0 ? ele.winnerPointsSetOne : 'NA'),
    // supGames(3, ele.winnerPointsSetThree >= 0 ? ele.winnerPointsSetOne : 'NA'),
    // supGames(1, ele.runnerUpPointsSetOne >= 0 ? ele.winnerPointsSetOne : 'NA'),
    // supGames(2, ele.runnerUpPointsSetTwo >= 0 ? ele.winnerPointsSetOne : 'NA'),
    // supGames(
    //   3,
    //   ele.runnerUpPointsSetThree >= 0 ? ele.winnerPointsSetOne : 'NA',
    // ),
  )
  rows.push(item)
})

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          backgroundColor="#6e534f"
          height="60px"
          fontSize="2em"
          fontWeight="bolder"
          color="mintcream"
        >
          <CardHeader
            title={
              <Typography gutterBottom variant="h6" component="h6">
                Round Rock Tennis Championships
              </Typography>
            }
          />
        </Grid>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={`${column.id}-${i}`}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontSize: '1em',
                    fontWeight: 'bold',
                    backgroundColor: 'rgba(25, 118, 210, 0.12)',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`row-${i}`}
                  >
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
