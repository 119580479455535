import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Card,
  CardContent,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Grid,
} from '@mui/material'
import { makePostCall } from '../../../api/client'
import { BasicModal } from '../../../utils/common.element'
import ElevationTile from '../../Tiles/ElevationTile'
import CardHeader from '../Card/CardHeader'

function ContactUs() {
  const [submitButton, setSubmitButton] = React.useState({
    message: 'Submit Registration',
    severity: null,
  })
  const [openModel, setOpenModel] = React.useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: 'onChange',
  })

  const onSubmit = async (data) => {
    const playerData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      addToWhatsApp: true,
      message: data.message,
    }
    makePostCall(
      playerData,
      'common/sendEmail',
      null,
      setSubmitButton,
      true,
      'Your inquiry',
    )
    setOpenModel(true)
  }

  const validateName = (value) => /^[a-zA-Z\s]*$/.test(value.trim())
  const validateEmail = (value) => /\S+@\S+\.\S+/.test(value.trim())
  const validatePhoneNumber = (value) =>
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
      value.trim(),
    )

  return (
    <Card
      variant="outlined"
      style={{ maxWidth: '100%', margin: '2px auto', padding: '20px' }}
    >
      <CardHeader>
        <ElevationTile tileContent={'RRTL - Contact Us'} />
      </CardHeader>
      <CardContent>
        <Grid item>
          {/* <Typography variant="h5" gutterBottom>Contact Us</Typography> */}
          <Typography variant="body2">
            If you have any questions, please feel free to reach out to us! You
            can drop us a message at any time.
          </Typography>
          <Typography variant="body2">
            <strong>Phone:</strong> +1 708-515-0998
          </Typography>
          <Typography variant="body2" mt={2}>
            Alternatively, you can submit the following form.
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                margin="dense"
                variant="outlined"
                {...register('firstName', {
                  required: 'First name is required',
                  validate: validateName,
                })}
                error={!!errors.firstName}
                helperText={
                  errors.firstName?.message ||
                  'Should not contain special characters.'
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                margin="dense"
                variant="outlined"
                {...register('lastName', {
                  required: 'Last name is required',
                  validate: validateName,
                })}
                error={!!errors.lastName}
                helperText={
                  errors.lastName?.message ||
                  'Should not contain special characters.'
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                variant="outlined"
                {...register('phoneNumber', {
                  validate: validatePhoneNumber,
                })}
                error={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber
                    ? 'Enter a valid phone number in the format 708-515-0998 or +1708-515-0998.'
                    : 'Format: 708-515-0998 or +1708-515-0998'
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                margin="dense"
                variant="outlined"
                type="email"
                {...register('email', {
                  required: 'Email is required',
                  validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={
                  errors.email?.message || 'Enter a valid email address.'
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                margin="dense"
                variant="outlined"
                multiline
                rows={4}
                {...register('message')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox {...register('addToWhatsApp')} />}
                label="Can we add your phone number to the Round Rock Tennis League WhatsApp group?"
              />
            </Grid>
            {BasicModal(
              openModel,
              {
                severity: submitButton.severity,
                message: submitButton.message,
              },
              `Thank you for getting in touch! We will respond to your inquiry as soon as possible.`,
              setOpenModel,
            )}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  !isValid || (!watch('email') && !watch('phoneNumber'))
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export default ContactUs
