import {
  Alert, Button, Card,

















  CardActionArea, CardActions, CardContent,

  Checkbox,
  FormControlLabel,





  FormLabel, Grid,





  List, ListItem,
  ListItemText, Radio, RadioGroup, TextField,



  Typography
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { makePostCall } from '../api/client'
import CardHeader from '../components/common/Card/CardHeader'
import ElevationTile from '../components/Tiles/ElevationTile'
import { BasicModal } from '../utils/common.element'


function VolunteerRegistrationForm() {
  const [submitButton, setSubmitButton] = React.useState({
    message: 'Submit Registration',
    severity: null,
  })
  const [openModel, setOpenModel] = React.useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: 'onChange',
  })

  const onSubmit = async (data) => {
    const committeeData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      volunteerFor: data.volunteerFor || 'two-years', // Default value 'two-years' if volunteerFor is undefined
      message: data.message,
      fullTime: data.fullTime || false, // Example new field with default value
      pocConflictResolution: data.pocConflictResolution || false, // Example new field with default value
      rulesResolution: data.rulesResolution || false, // Example new field with default value
      conflictResolutionsOnly: data.conflictResolutionsOnly || false, // Example new field with default value
      finalDinner: data.finalDinner || false, // Example new field with default value
      joiningPreference: data.joiningPreference, // Default value 'general' if joiningPreference is undefined
    }
    console.log('data ', committeeData)
    makePostCall(
      committeeData,
      'challenge',
      null,
      setSubmitButton,
      true,
      'Your request',
    )
    setOpenModel(true)
  }

  const validateName = (value) => /^[a-zA-Z\s]*$/.test(value.trim())
  const validateEmail = (value) => /\S+@\S+\.\S+/.test(value.trim())
  const validatePhoneNumber = (value) =>
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
      value.trim(),
    )

  return (
    <Card
      variant="outlined"
      style={{ maxWidth: '100%', margin: '2px auto', padding: '10px' }}
    >
      <CardHeader>
        <ElevationTile tileContent={'RRTL Committee Volunteer Regist!'} />
      </CardHeader>
      <CardContent>
        <Grid item>
          {/* <Typography variant="h5" gutterBottom>Contact Us</Typography> */}
          <Typography variant="body2">
            If you have any questions, please feel free to reach out to us! You
            can drop us a message at any time.
          </Typography>
          <Typography variant="body2">
            <strong>Phone:</strong> +1 708-515-0998
          </Typography>
          <Typography variant="body2" mt={2}>
            Please submit the following for committee volunteering.
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First Name"
                margin="dense"
                variant="outlined"
                {...register('firstName', {
                  required: 'First name is required',
                  validate: validateName,
                })}
                error={!!errors.firstName}
                helperText={
                  errors.firstName?.message ||
                  'Should not contain special characters.'
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Last Name"
                margin="dense"
                variant="outlined"
                {...register('lastName', {
                  required: 'Last name is required',
                  validate: validateName,
                })}
                error={!!errors.lastName}
                helperText={
                  errors.lastName?.message ||
                  'Should not contain special characters.'
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                variant="outlined"
                {...register('phoneNumber', {
                  validate: validatePhoneNumber,
                })}
                error={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber
                    ? 'Enter a valid phone number in the format 708-515-0998 or +1708-515-0998.'
                    : 'Format: 708-515-0998 or +1708-515-0998'
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                margin="dense"
                variant="outlined"
                type="email"
                {...register('email', {
                  required: 'Email is required',
                  validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={
                  errors.email?.message || 'Enter a valid email address.'
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Volunteering For{' '}
              </FormLabel>
              <RadioGroup
                row
                defaultValue="twoYear"
                {...register('volunteerFor')}
                // {...register('volunteerFor', {
                //   required: 'Please select an option',
                // })}
              >
                <FormControlLabel
                  value="season-9-only"
                  control={<Radio />}
                  label="Season 9 only"
                />
                <FormControlLabel
                  value="oneYear"
                  control={<Radio />}
                  label="1 year"
                />
                <FormControlLabel
                  value="twoYear"
                  control={<Radio />}
                  label="2 years"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ p: 1, border: '1px dashed grey' }}>
                <CardActions>
                  <Typography textAlign="center" variant="h6" gutterBottom>
                    {' '}
                    Preferences{' '}
                  </Typography>
                </CardActions>
                <CardActionArea>
                  <CardContent>
                    <Grid item xs={12} md={6} lg={12} pt="4">
                      <FormControlLabel
                        {...register('fullTime')}
                        control={<Checkbox color="success" defaultChecked />}
                        label="Full-time involvement in all league activities"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} pt="2">
                      <FormControlLabel
                        {...register('pocConflictResolution')}
                        control={<Checkbox color="secondary" />}
                        label="Involvement only for conflicts - when committee members have a conflict of interest."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} pt="2">
                      <FormControlLabel
                        {...register('rulesResolution')}
                        control={<Checkbox color="default" />}
                        label="Involvement in resolving rules-related issues"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} pt="2">
                      <FormControlLabel
                        {...register('conflictResolutionsOnly')}
                        control={<Checkbox color="primary" />}
                        label="Involvement only in conflict resolutions"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} pt="2">
                      <FormControlLabel
                        {...register('finalDinner')}
                        control={<Checkbox color="warning" />}
                        label="Involvement only for the final night dinner! 😋"
                      />
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Alert icon={false} severity="secondary">
                <FormControlLabel
                  {...register('joiningPeference')}
                  control={<Checkbox defaultChecked />}
                  label="I am interested in joining the committee, but only if we do not have enough volunteers."
                />
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comments"
                margin="dense"
                variant="outlined"
                multiline
                rows={4}
                {...register('message')}
              />
            </Grid>
            <Grid item xs={12}>
              <List
                sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}
              >
                <ListItem disablePadding>
                  <ListItemText primary="• This registration is for individuals interested in volunteering for committee roles." />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText primary="• Committee responsibilities include organizing tournaments, planning event schedules, establishing and enforcing rules, managing team activities, and facilitating conflict resolution among participants." />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText primary="• Volunteers have the flexibility to opt-out of their committee responsibilities at any time, respecting their personal commitments and availability." />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText primary="• Committee members have the authority to make on-the-spot decisions regarding match proceedings, clarify rules to participants, and effectively manage any conflicts that may arise during competitive play." />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText primary="• Committee members can only be dismissed or removed from their position in exceptional circumstances or if requested by a majority of ten or more people." />
                </ListItem>
              </List>
            </Grid>
            {BasicModal(
              openModel,
              {
                severity: submitButton.severity,
                message: submitButton.message,
              },
              `Thank you for your interest in joining the committee! We will get back to you with the next steps.`,
              setOpenModel,
            )}
            <Grid item xs={12} lg={12}>
              <Alert severity="warning">
                Submitting this form does not guarantee committee membership.
                Approved members will be announced after the selection process.
              </Alert>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  !isValid || (!watch('email') && !watch('phoneNumber'))
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export default VolunteerRegistrationForm
