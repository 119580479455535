import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import Box from '@mui/material/Box'
import DateRangeIcon from '@mui/icons-material/DateRange'
import AssessmentIcon from '@mui/icons-material/Assessment'
import ViewComfyIcon from '@mui/icons-material/ViewComfy'
import GavelIcon from '@mui/icons-material/Gavel'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { HomeIcon } from '../utils/common.element'

export default function QuickMenu() {
  const path = window.location.pathname

  const getSelectedTab = () => {
    let selectedValue = 0
    switch (path) {
      case '/':
        selectedValue = 0
        break
      case '/result/points-table':
        selectedValue = 1
        break
      case '/schedule/list':
        selectedValue = 2
        break
      case '/result/list':
        selectedValue = 3
        break
      case '/rules':
        selectedValue = 4
        break
      case '/contactus':
        selectedValue = 5
        break
      default:
        selectedValue = 0
        break
    }
    return selectedValue
  }

  return (
    <Box sx={{ width: '100%', borderBottom: 2, borderColor: 'divider' }}>
      <Tabs
        value={getSelectedTab()}
        variant="scrollable"
        indicatorColor="primary"
        textColor="primary"
        allowScrollButtonsMobile
        scrollButtons
        aria-label="quick round table"
      >
        <Tab
          id="home"
          disabled={path === '/'}
          icon={<HomeIcon color={path === '/' ? 'success' : 'secondary'} />}
          label="Home"
          href="/"
        />
        <Tab
          id="points-table"
          disabled={path === '/result/points-table'}
          icon={
            <AssessmentIcon
              color={path === '/result/points-table' ? 'success' : 'secondary'}
            />
          }
          label="Points Table"
          href="/result/points-table"
        />
        <Tab
          id="schedule"
          disabled={path === '/schedule/list'}
          icon={
            <DateRangeIcon
              color={path === '/schedule/list' ? 'success' : 'secondary'}
            />
          }
          label="Schedule"
          href="/schedule/list"
        />

        <Tab
          id="results"
          disabled={path === '/result/list'}
          icon={
            <ViewComfyIcon
              color={path === '/result/list' ? 'success' : 'secondary'}
            />
          }
          label="Results"
          href="/result/list"
        />

        <Tab
          id="points-table"
          disabled={path === '/rules'}
          icon={
            <GavelIcon color={path === '/rules' ? 'success' : 'secondary'} />
          }
          label="Rules"
          href="/rules"
        />
        <Tab
          id="contactus"
          disabled={path === '/contactus'}
          icon={
            <ContactMailIcon color={path === '/contactus' ? 'success' : 'secondary'} />
          }
          label="Contact Us"
          href="/contactus"
        />
      </Tabs>
    </Box>
  )
}
