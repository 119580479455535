import React, { useEffect, useState } from 'react'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  FormHelperText,
  Paper,
  Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Badge, Chip, makeStyles } from '@material-ui/core'

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode !== 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  borderRadius: '6px',
  padding: '2px',
  color: theme.palette.text.secondary,
  maxWidth: 350,
}))

export default function PlayerResultsTile({ players, data }) {
  const [winningTeam, setWinningTeam] = useState({
    teamName: '',
    points: 0,
    avatarUrl: [],
  })

  const [losingTeam, setLosingTeam] = useState({
    teamName: '',
    points: 0,
    avatarUrl: [],
  })

  const {
    teamPlayersIds: hostTeamPlayersIds,
    teamName: hostTeamName,
    setOnePoints: hostTeamSetOnePoints,
    setTwoPoints: hostTeamSetTwoPoints,
    setThreePoints: hostTeamSetThreePoints,
    setOneTieBreakerPoints: hostTeamSetOneTieBreakerPoints,
    setTwoTieBreakerPoints: hostTeamSetTwoTieBreakerPoints,
    setThreeTieBreakerPoints: hostTeamSetThreeTieBreakerPoints,
    teamId: hostTeamId,
  } = data.hostTeam

  const {
    teamPlayersIds: guestTeamPlayersIds,
    teamName: guestTeamName,
    setOnePoints: guestTeamSetOnePoints,
    setTwoPoints: guestTeamSetTwoPoints,
    setThreePoints: guestTeamSetThreePoints,
    setOneTieBreakerPoints: guestTeamSetOneTieBreakerPoints,
    setTwoTieBreakerPoints: guestTeamSetTwoTieBreakerPoints,
    setThreeTieBreakerPoints: guestTeamSetThreeTieBreakerPoints,
    teamId: guestTeamId,
  } = data.guestTeam

  const useStyles = makeStyles((theme) => ({
    badge: {
      fontSize: '11px',
      height: '10px',
      fontFamily: 'light',
      backgroundColor: 'rgb(26 32 39)',
    },
    chip: {
      color: 'red',
      backgroundColor: 'rgb(26 32 39)',
    },
  }))
  const classes = useStyles()

  useEffect(() => {
    const won = hostTeamId === data.winningTeam.teamId ? 'host' : 'guest'

    const hostAvatarUrls = players
      .filter((player) => hostTeamPlayersIds.includes(player.playerId))
      .map((player) => player?.avatarImgUrl)

    const guestAvatarUrls = players
      .filter((player) => guestTeamPlayersIds.includes(player.playerId))
      .map((player) => player?.avatarImgUrl)
    const getTeamData = (won) => {
      const teamData = {
        teamName: won === 'host' ? hostTeamName : guestTeamName,
        avatarUrl: won === 'host' ? hostAvatarUrls : guestAvatarUrls,
        points: won === 'host' ? hostTeamSetOnePoints : guestTeamSetOnePoints,
        setOnePoints:
          won === 'host' ? hostTeamSetOnePoints : guestTeamSetOnePoints,
        setTwoPoints:
          won === 'host' ? hostTeamSetTwoPoints : guestTeamSetTwoPoints,
        setThreePoints:
          won === 'host' ? hostTeamSetThreePoints : guestTeamSetThreePoints,
        setOneTieBreakerPoints:
          won === 'host'
            ? hostTeamSetOneTieBreakerPoints
            : guestTeamSetOneTieBreakerPoints,
        setTwoTieBreakerPoints:
          won === 'host'
            ? hostTeamSetTwoTieBreakerPoints
            : guestTeamSetTwoTieBreakerPoints,
        setThreeTieBreakerPoints:
          won === 'host'
            ? hostTeamSetThreeTieBreakerPoints
            : guestTeamSetThreeTieBreakerPoints,
      }
      return teamData
    }

    const winningTeamData = getTeamData(won)
    const losingTeamData = getTeamData(won === 'host' ? 'guest' : 'host')

    setWinningTeam(winningTeamData)
    setLosingTeam(losingTeamData)
  }, [players, hostTeamPlayersIds, guestTeamPlayersIds, data])

  function renderTeamStack(teamName, avatarUrls) {
    return (
      <Stack>
        <AvatarGroup total={avatarUrls.length}>
          {avatarUrls.map((url, index) => (
            <Avatar
              key={`avatar-${index}`}
              style={{
                borderRadius: '50%',
                boxShadow: '0 0 2px rgba(255, 0, 0, 0.5)',
              }}
              src={url}
            />
          ))}
        </AvatarGroup>
        <FormHelperText
          id={`${teamName.toLowerCase()}-helper-text`}
          type="dense"
          sx={{
            color: '#98ecb7',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: 0,
            margin: 0,
          }}
        >
          {teamName}
        </FormHelperText>
      </Stack>
    )
  }
  // setOnePoints
  const teamPoints = (setPoints) => {
    const tbPoints = {
      setOnePoints: 'setOneTieBreakerPoints',
      setTwoPoints: 'setTwoTieBreakerPoints',
      setThreePoints: 'setThreeTieBreakerPoints',
    }
    const set = [
      {
        pointsUrl: `${winningTeam[setPoints]}.png`,
        tieBreakPoints: winningTeam[tbPoints[setPoints]],
      },
      {
        pointsUrl: `${losingTeam[setPoints]}.png`,
        tieBreakPoints: losingTeam[tbPoints[setPoints]],
      },
    ]
    return set
  }

  const mainStack = (
    <StyledPaper sx={{ my: 1, mx: 'auto', p: 1 }}>
      <Stack spacing={1} direction="row" alignItems="center">
        {renderTeamStack(winningTeam.teamName, winningTeam.avatarUrl)}
        {winningTeam.teamName && (
          <Stack>
            <Stack direction="row" spacing={0}>
              {winningTeam.setOnePoints &&
                teamPoints('setOnePoints').map((imageName, index) => (
                  <>
                    <Badge
                      color="primary"
                      overlap="rectangular"
                      classes={{ badge: classes.badge }}
                      badgeContent={imageName.tieBreakPoints}
                      invisible={!imageName.tieBreakPoints}
                      showZero
                    >
                      <Avatar
                        key={`number-avatar-${index}`}
                        alt={`Avatar ${index + 1}`}
                        sx={{
                          width: 28,
                          height: 30,
                        }}
                        src={require(`./numbers/${imageName.pointsUrl}`)}
                      />
                    </Badge>
                    {index === 0 && (
                      <Chip
                        label={<Box sx={{ color: '#FAFAFA' }}>SET-01</Box>}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  </>
                ))}
            </Stack>

            <Stack direction="row" spacing={0}>
              {winningTeam.setTwoPoints &&
                teamPoints('setTwoPoints').map((imageName, index) => (
                  <>
                    <Badge
                      color="error"
                      classes={{ badge: classes.badge }}
                      invisible={!imageName.tieBreakPoints}
                      badgeContent={imageName.tieBreakPoints}
                      showZero
                    >
                      <Avatar
                        key={`number-avatar-${index}`}
                        alt={`Avatar ${index + 1}`}
                        sx={{
                          width: 28,
                          height: 30,
                        }}
                        src={require(`./numbers/${imageName.pointsUrl}`)}
                      />
                    </Badge>
                    <>
                      {index === 0 && (
                        <Chip
                          label={<Box sx={{ color: '#FAFAFA' }}>SET-02</Box>}
                          size="small"
                          variant="outlined"
                        />
                      )}
                    </>
                  </>
                ))}
            </Stack>
            <Stack direction="row" spacing={0}>
              {winningTeam.setThreePoints &&
                teamPoints('setThreePoints').map((imageName, index) => (
                  <>
                    <Badge
                      color="error"
                      classes={{ badge: classes.badge }}
                      invisible={!imageName.tieBreakPoints}
                      badgeContent={imageName.tieBreakPoints}
                      showZero
                    >
                      <Avatar
                        key={`number-avatar-${index}`}
                        alt={`Avatar ${index + 1}`}
                        sx={{
                          width: 28,
                          height: 30,
                        }}
                        src={require(`./numbers/${imageName.pointsUrl}`)}
                      />
                    </Badge>
                    <>
                      {index === 0 && (
                        <Chip
                          label={
                            <Box sx={{ color: '#FAFAFA' }}>
                              {data.setThreeIsTieBreaker ? 'tie-brk.' : 'SET-03'}
                            </Box>
                          }
                          size="small"
                          variant="outlined"
                        />
                      )}
                    </>
                  </>
                ))}
            </Stack>
          </Stack>
        )}
        {renderTeamStack(losingTeam.teamName, losingTeam.avatarUrl)}
      </Stack>
    </StyledPaper>
  )
  return <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 1 }}>{mainStack}</Box>
}
