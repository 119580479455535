import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../players/player.slice'
import playerReducer from '../players/players.slice'
import teamsReducer from '../teams/teams.slice'
import schedulesReducer from '../matches/schedule/schedule.slice'
import rulesReducer from '../rules/rule.slice'
import resultsReducer from '../matches/results/result.slice'
export default configureStore({
  reducer: {
    counter: counterReducer,
    players: playerReducer,
    teams: teamsReducer,
    schedules: schedulesReducer,
    rules: rulesReducer,
    results: resultsReducer,
  },
})
