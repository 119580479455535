// import * as React from "react";
import React, { useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import FormHelperText from '@mui/material/FormHelperText'

import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import InputAdornment from '@mui/material/InputAdornment'
import { getBaseUrl } from '../utils/utils'

import { selectAllPlayers, fetchPlayers } from '../players/players.slice'
import { Spinner } from '../components/Spinner'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'
import { getNameInitials } from '../utils/utils.js'
import { Copyright, alertMessage } from '../utils/common.element'
import { Autocomplete, TextField } from '@mui/material'
import { TOURNAMENTS } from '../utils/constants'

const theme = createTheme()

export default function EditTeam(props) {
  const navigate = useNavigate()
  const _selectedTeamId = props?.editTeam?.teamId
  const _selectedTeamCaptain = props?.editTeam?.teamCaptainName
  const _selectedTeamName = props?.editTeam?.teamName
  const _selectedTeamPlayers = props?.editTeam?.teamPlayersFullNames
  const _selectedLeagues = props?.editTeam?.leagues || TOURNAMENTS
  const { isAdmin } = props
  const [leaguesSelection, setLeaguesSelection] = React.useState(
    _selectedLeagues,
  )

  const dispatch = useDispatch()
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)
  const error = useSelector((state) => state.players.error)

  // players selection
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 270,
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  }

  const [selectedPlayers, setSelectedPlayers] = React.useState(
    _selectedTeamPlayers || [],
  )

  const [selectedPlayersInitials, setSelectedPlayersInitials] = React.useState(
    [],
  )
  const [captain, setCaptain] = React.useState(_selectedTeamCaptain)
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [isFieldsChanged, setIsFieldsChanged] = React.useState(false)
  const [teamName, setTeamName] = React.useState(_selectedTeamName)
  const [submitButton, setSubmitButton] = React.useState({
    message: 'Submit your team',
    severity: null,
  })
  const handleTeamName = (event) => setTeamName(event.target.value)

  // const handleTeamPlayerSelectionChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event
  //   console.log('event old ', value)
  //   console.log({ selectedPlayers })
  //   setSelectedPlayers(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   )
  // }
  const handleTeamPlayerSelectionChange = (players) => {
    setSelectedPlayers(players)
    setCaptain(null)
  }

  const handleSingleChange = (event) => {
    setCaptain(event.target.value)

    // set initials
    if (selectedPlayers.length === 2) {
      const initials = `${getNameInitials(
        selectedPlayers[0],
      )}-${getNameInitials(selectedPlayers[1])}`
      setSelectedPlayersInitials(initials)
    }
  }

  useEffect(() => {
    console.log('USE effect is been called.....')
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
    if (
      _selectedTeamPlayers &&
      captain === _selectedTeamCaptain &&
      teamName === _selectedTeamName &&
      _selectedTeamPlayers[0] === selectedPlayers[0] &&
      _selectedTeamPlayers[1] === selectedPlayers[1]
    ) {
      setIsFieldsChanged(false)
    } else {
      setIsFieldsChanged(true)
    }
  }, [
    playersStatus,
    dispatch,
    selectedPlayers,
    captain,
    teamName,
    _selectedTeamCaptain,
    _selectedTeamName,
    _selectedTeamPlayers,
  ])

  let multiSelect
  let singleSelect

  if (playersStatus === 'loading') {
    multiSelect = <Spinner text="Loading..." />
  } else if (playersStatus === 'succeeded') {
    multiSelect = (
      <div>
        <FormControl fullWidth sx={{ m: 1 }}>
          {/* <InputLabel id="demo-multiple-checkbox-label">
            Team Players
          </InputLabel> */}
          <Autocomplete
            fullWidth={true}
            size="large"
            multiple
            disabled={allPlayers?.length < 1}
            defaultValue={_selectedTeamPlayers}
            options={allPlayers.map((p) => p.fullName)}
            getOptionLabel={(option) => option || ''}
            getOptionDisabled={(option) =>
              option === selectedPlayers[0] ||
              option === selectedPlayers[1] ||
              selectedPlayers?.length >= 2
            }
            onChange={(e, value) => {
              handleTeamPlayerSelectionChange(value)
            }}
            renderInput={(params) => (
              <TextField
                color={
                  selectedPlayers && selectedPlayers.length === 2
                    ? 'success'
                    : 'error'
                }
                variant="outlined"
                {...params}
                label={'Teams Players'}
              />
            )}
          />
        </FormControl>
      </div>
    )
    singleSelect = (
      <div>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            {`Captain`}
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-fullwidth"
            defaultValue={captain}
            value={captain}
            onChange={handleSingleChange}
            autoWidth
            disabled={selectedPlayers.length !== 2}
            label="Captain"
          >
            {selectedPlayers.map((p) => (
              <MenuItem key={p} value={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  } else if (playersStatus === 'failed') {
    multiSelect = <div>{error}</div>
  }

  const handleLeaguesSelection = (v) => {
    setLeaguesSelection(v)
    setIsFieldsChanged(true)
  }

  const leaguesSelectionInputElement = () => {
    return (
      <Autocomplete
        multiple
        fullWidth
        limitTags={1}
        sx={{ mt: 2 }}
        id="multiple-limit-tags"
        options={TOURNAMENTS}
        getOptionLabel={(option) => option}
        defaultValue={leaguesSelection}
        onChange={(e, v) => handleLeaguesSelection(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label="Interested in joining"
            name="tournamentName"
            placeholder="Tournament Name"
          />
        )}
        // sx={{ width: "500px" }}
      />
    )
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const teamPlayers = allPlayers.filter((p) =>
      selectedPlayers.includes(p.fullName),
    )
    const teamCaptain = allPlayers.find((p) => p.fullName === captain)
    const body = {
      teamName,
      teamPlayers,
      leagues: leaguesSelection,
      teamPlayersIds: teamPlayers.map((p) => p.playerId),
      teamCaptainId: teamCaptain.playerId,
      teamCaptainName: captain,
      createdBy: props?.user?.email,
      createdAt: new Date().toISOString(),
    }
    if (_selectedTeamId) {
      body['teamId'] = _selectedTeamId
      body['updatedBy'] = props?.user?.email
      body['updatedAd'] = new Date().toISOString()
    }

    if (isAdmin) {
      body['isAdminUpdate'] = true
    }
    const baseUrl = getBaseUrl()
    fetch(`${baseUrl}teams`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${props?.token}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        setSubmitButton({
          message: 'Team is successfully updated.',
          severity: 'success',
        })
        setIsSubmitted(true)
      })
      .catch((error) => console.error('Team registration Error:', error))
  }
  const isNameExist =
    teamName.toLocaleLowerCase() === _selectedTeamName.toLocaleLowerCase()
      ? false
      : props.allTeams.some(
          (p) =>
            p.teamName.toLocaleLowerCase() === teamName.toLocaleLowerCase(),
        )
  const title = isNameExist
    ? 'Name exist, use different name.'
    : 'Save team changes'
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Update team details
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                {multiSelect}
              </Grid>
              <Grid item xs={12}>
                {singleSelect}
              </Grid>
            </Grid>
            <Grid>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount" sx={{ m: 1 }}>
                  Type your team name
                </InputLabel>
                <OutlinedInput
                  error={!teamName && captain ? true : false}
                  id={teamName}
                  type="text"
                  color={teamName.length < 4 ? 'secondary' : 'success'}
                  label="Type your team name"
                  disabled={!captain}
                  name={teamName}
                  value={teamName}
                  onChange={handleTeamName}
                  startAdornment={
                    <InputAdornment position="start">
                      {selectedPlayersInitials}
                    </InputAdornment>
                  }
                />
                {leaguesSelectionInputElement()}
                {isFieldsChanged && !isSubmitted && (
                  <>
                    <Button
                      onClick={handleSubmit}
                      fullWidth
                      disabled={!captain || isNameExist}
                      color="error"
                      variant="contained"
                      sx={{
                        mt: 1,
                        mb: 1,
                        backgroundColor: 'green',
                        border: '0.5px solid',
                        fontSize: '14',
                      }}
                    >
                      {title}
                    </Button>
                    <Button
                      fullWidth
                      href="/teams"
                      color="warning"
                      variant="contained"
                      sx={{
                        mt: 1,
                        mb: 1,
                        backgroundColor: 'info',
                        border: '0.5px solid',
                        fontSize: '14',
                      }}
                    >
                      Cancel team changes**
                    </Button>
                  </>
                )}
                {submitButton.severity &&
                  alertMessage(
                    submitButton.severity,
                    submitButton.message,
                    navigate,
                  )}
              </FormControl>
            </Grid>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  )
}
