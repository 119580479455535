import { Box, Divider, SnackbarContent, Tab, Tabs } from '@material-ui/core'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import axios from 'axios'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { WEEK_DAYS } from '../../utils/constants'
import { getBaseUrl } from '../../utils/utils'
import { orderBy } from 'lodash'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function formatDate(dateString) {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  return `${day}-${month}`
}

function generateDateRangesList(obj) {
  return Object.keys(obj)
    .filter((key) => key.startsWith('datesRange'))
    .reduce((acc, key) => {
      const rangeNumber = key.match(/\d+/)[0]
      const startDateKey = `datesRange${rangeNumber}StartOutageDate`
      const endDateKey = `datesRange${rangeNumber}EndOutageDate`

      acc.push(
        `${formatDate(obj[startDateKey])} | ${formatDate(obj[endDateKey])}`,
      )

      return acc
    }, [])
}
export default function PlayerRegistrationTable() {
  const [displayData, setDisplayData] = useState([])
  const [allPlayers, setAllPlayers] = useState([])

  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    if (tabValue === 0) {
      setDisplayData(
        orderBy(
          allPlayers.filter((p) => p.hasPartner === 'Yes'),
          ['hasPartner', 'challengeMatchesId'],
          ['desc', 'asc'],
        ),
      )
    }
    if (tabValue === 1) {
      setDisplayData(
        orderBy(
          allPlayers.filter((p) => p.hasPartner === 'No'),
          ['hasPartner', 'challengeMatchesId'],
          ['desc', 'asc'],
        ),
      )
    }
    if (tabValue === 2) {
      setDisplayData(orderBy(allPlayers, ['challengeMatchesId'], ['asc']))
    }
  }, [tabValue])

  const url = `${getBaseUrl()}challenge`
  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        const filteredTeams = response.data.filter(
          (player) => player.isActive !== false,
        )
        setAllPlayers(filteredTeams)
        setDisplayData(
          orderBy(
            filteredTeams.filter((p) => p.hasPartner === 'Yes'),
            ['hasPartner', 'challengeMatchesId'],
            ['desc', 'asc'],
          ),
        )
      })
      .catch((error) => {
        console.error('Error fetching player data:', error)
      })
  }, [])

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${url}/${id}`)
      const updatedPlayerData = displayData.filter(
        (player) => player.challengeMatchesId !== id,
      )
      setDisplayData(
        orderBy(
          updatedPlayerData,
          ['hasPartner', 'challengeMatchesId'],
          ['desc', 'asc'],
        ),
      )
    } catch (error) {
      console.error('Error deleting player:', error)
    }
  }
  function mapDaysCountPreference(value) {
    switch (value) {
      case 'oneDaysOffWeek':
        return '1 Day'
      case 'twoDaysOffWeek':
        return '3 Days'
      case 'threeDaysOffWeek':
        return '3 Days'
      case 'threePlusDaysOffWeek':
        return '3+ Days'
      default:
        return ''
    }
  }

  function getWeekdaysFromIndices(indices) {
    return indices
      .map((index) => WEEK_DAYS.find((day) => day.id === index)?.name)
      .join(', ')
  }
  const tableHeadRows = (
    <TableRow>
      <StyledTableCell align="center" style={{ width: '2px' }}>
        #ID
      </StyledTableCell>
      <StyledTableCell align="center" style={{ width: '6px' }}>
        Full Name
      </StyledTableCell>

      <StyledTableCell align="center" style={{ width: '2px' }}>
        Partner
      </StyledTableCell>
      <StyledTableCell align="center" style={{ width: '1px' }}>
        Week Days Availability
      </StyledTableCell>

      <StyledTableCell align="center" style={{ width: '2px' }}>
        Favorable Days
      </StyledTableCell>
      <StyledTableCell align="center" style={{ width: '2px' }}>
        Un-Favorable Days
      </StyledTableCell>
      <StyledTableCell align="center">Out Dates Range</StyledTableCell>
      <StyledTableCell align="center" style={{ width: '2px' }}>
        Pairup Change Preference
      </StyledTableCell>
      <StyledTableCell align="center" style={{ width: '2px' }}>
        Volunteer Committee
      </StyledTableCell>
      <StyledTableCell align="center" style={{ width: '2px' }}>
        Volunteer Umpire
      </StyledTableCell>
      <StyledTableCell align="center" style={{ width: '2px' }}>
        Rules Feedback
      </StyledTableCell>
      <StyledTableCell align="center" style={{ width: '2px' }}>
        Schedule Feedback
      </StyledTableCell>
      <StyledTableCell align="center" style={{ width: '2px' }}>
        General Feedback
      </StyledTableCell>
    </TableRow>
  )
  const tableBodyValues = displayData.map((row, index) => (
    <StyledTableRow key={index}>
      <StyledTableCell component="th" scope="row">
        {index + 1}
      </StyledTableCell>
      <StyledTableCell>
        {row?.ownerPlayerInfo?.fullName}
        <RemoveCircleOutlineIcon
          color="warning"
          fontSize="small"
          onClick={() => handleDelete(row.challengeMatchesId)}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        {row?.partnerPlayerInfo?.fullName}
        {/* {row.playerPreference
                  ?.replace(/([A-Z])/g, ' $1')
                  .replace(/^\w/, (c) => c.toUpperCase())
                  .trim()} */}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row && mapDaysCountPreference(row.daysCountPreference)}
        {/* {row.playingPreference
                  ?.replace(/([A-Z])/g, ' $1')
                  .replace(/^\w/, (c) => c.toUpperCase())
                  .trim()} */}
      </StyledTableCell>

      <StyledTableCell align="right">
        {row.favorableDays && getWeekdaysFromIndices(row.favorableDays)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.unFavorableDays && getWeekdaysFromIndices(row.unFavorableDays)}
      </StyledTableCell>
      <StyledTableCell align="right" style={{ color: '#9C27B0' }}>
        {row.datesRange1StartOutageDate &&
          generateDateRangesList(row).map((r, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <Divider />}{' '}
              {/* Add a divider except for the first item */}
              {r}
            </React.Fragment>
          ))}
        {!row.datesRange1StartOutageDate && 'N/A'}
      </StyledTableCell>

      <StyledTableCell align="right">
        {row?.partnerChange && row.partnerChange.toUpperCase()}
        {/* {row.pairupPreference
                  ?.replace(/([A-Z])/g, ' $1')
                  .replace(/^\w/, (c) => c.toUpperCase())
                  .trim()} */}
      </StyledTableCell>
      <StyledTableCell align="right">{row.volunteerCommittee}</StyledTableCell>
      <StyledTableCell align="right">{row.volunteerUmpire}</StyledTableCell>
      <StyledTableCell align="right">{row.rulesComments}</StyledTableCell>
      <StyledTableCell align="right">{row.scheduleComments}</StyledTableCell>
      <StyledTableCell align="right">{row.generalComments}</StyledTableCell>
    </StyledTableRow>
  ))
  return (
    <>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Paired Players" />
          <Tab label="Single Players" />
          <Tab label="All Players" />
        </Tabs>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="Player Registration Preferences">
          {' '}
          <TableHead>{tableHeadRows}</TableHead>
          <TableBody>{tableBodyValues}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
