import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../api/client'
import { getBaseUrl } from '../utils/utils'

const initialState = {
  teamsData: [],
  status: 'idle',
  error: null,
}

const baseUrl = getBaseUrl()

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    async addTeam(state, action) {
      // const { teamTeams, teamCaptain, teamName } = action.payload;
      // const t = {
      //   teamTeams,
      //   teamCaptain,
      //   teamName,
      // };
      // console.log(t);
      const response = await client.post(`${baseUrl}teams`, 't')
      console.log('Response from backend: ', response.data)
      return response.data
    },
  },
  extraReducers(builder) {
    if (!fetchTeams) return
    builder
      .addCase(fetchTeams.pending, (state, action) => {
        console.log(' loading action Payload .....')
        state.status = 'loading'
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.teamsData = state.teamsData.concat(action.payload)
      })
      .addCase(fetchTeams.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const fetchTeams = createAsyncThunk('teams/fetchTeams', async () => {
  const response = await client.get(`${baseUrl}teams`)
  return response.data
})

export default teamsSlice.reducer

export const { addTeam } = teamsSlice.actions

export const allTeams = (state) => state.teams.teamsData
