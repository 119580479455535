import { useAuth0 } from '@auth0/auth0-react'
import { Paper } from '@material-ui/core'
import NavigationIcon from '@mui/icons-material/Navigation'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Autocomplete, Switch, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makePostCall } from '../../api/client'
import { Spinner } from '../../components/Spinner'
import { allTeams, fetchTeams } from '../../teams/teams.slice'
import { BasicModal, wrapBoxItem } from '../../utils/common.element'
import { TOURNAMENTS } from '../../utils/constants'
import {
  capitalize,
  isMatchPlayed,
  mapNumToWord,
  MATCH_STATUSES,
} from '../../utils/utils'

export function ResultSubmit(props) {
  console.log('Edit Submit Props', props.result?.points)
  const {
    isAuthenticated,
    user,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0()
  const [token, setToken] = React.useState(null)
  const dispatch = useDispatch()
  const teamsFetchStatus = useSelector((state) => state?.teams?.status)
  const teams = useSelector(allTeams)

  useEffect(() => {
    async function _setToken() {
      setToken(await getAccessTokenSilently())
    }
    _setToken()
  }, [isAuthenticated])

  useEffect(() => {
    if (teamsFetchStatus === 'idle') {
      dispatch(fetchTeams())
    }
  }, [teamsFetchStatus, dispatch])

  const [matchPointsFields, setMatchPointsFields] = React.useState()
  const [resultDetails, setResultDetails] = React.useState({
    tournamentName: props?.result?.tournamentName || TOURNAMENTS[0],
    resultComments: props?.result?.resultComments || 'none',
    matchTimeAndDate: props?.result?.matchTimeAndDate || new Date(),
    matchStatus: props?.result?.matchStatus || MATCH_STATUSES[0].label,
    matchPoints: props?.result?.matchPoints || 3,
    matchSetsSize: props?.result?.matchSetsSize || 1,
    matchType: props?.result?.matchType || 'Double',
    createdAt: props?.result?.createdAt || new Date().toISOString(),
    createdBy: props?.result?.createdBy || null,
    hostTeam: props?.result?.hostTeam || {},
    guestTeam: props?.result?.guestTeam || {},
    winningTeam: props?.result?.winningTeam || {},
    guestTeamPlayers: props?.result?.guestTeamPlayers || {},
    hostTeamPlayers: props?.result?.hostTeamPlayers || {},
    winningTeamPlayers: props?.result?.winningTeamPlayers || {},
    setOnePoints: props?.result?.setOnePoints || 0,
    setOneIsTieBreaker: props?.result?.setOneIsTieBreaker || false,
    setTwoIsTieBreaker: props?.result?.setTwoIsTieBreaker || false,
    setThreeIsTieBreaker: props?.result?.setThreeIsTieBreaker || false,
    matchResultGames: props?.result?.points || [],
  })

  const xGamesWonHost = resultDetails?.matchResultGames[0]?.gamesWon || 0
  const xGamesLostHost = resultDetails?.matchResultGames[0]?.gamesLost || 0
  const xGamesWonGuest = resultDetails?.matchResultGames[1]?.gamesWon || 0
  const xGamesLostGuest = resultDetails?.matchResultGames[1]?.gamesLost || 0

  useEffect(() => {
    console.log('calling....')
    if (teamsFetchStatus === 'succeeded') {
      getMatchSetPoints(
        resultDetails['matchSetsSize'],
        resultDetails['hostTeam'],
        resultDetails['guestTeam'],
      )
    }
  }, [teamsFetchStatus, resultDetails['hostTeam'], resultDetails['guestTeam']])
  // just for UI displaying, not submitting to back end
  const [selectedTeamPayers, setSelectedTeamPlayers] = React.useState({
    hostTeamPlayers: [],
    guestTeamPlayers: [],
    winningTeamPlayers: [],
  })
  const [submitButton, setSubmitButton] = React.useState({
    message: 'Submit result',
    severity: null,
  })
  const [openModel, setOpenModel] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const handleTeamsForm = (value, name) => {
    value = parseInt(value)
    const selectedTeam = teams.find((t) => t.teamId === value) || {}
    const {
      teamPlayersIds,
      teamName,
      teamPlayersFullNames,
      teamId,
    } = selectedTeam
    const selectedPlayers = selectedTeam?.teamPlayersFullNames || {}
    //   [teamType]: { ...prevState[teamType], ...setPoints },
    setResultDetails((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        ...{ teamPlayersIds, teamName, teamPlayersFullNames, teamId },
      },
      [`${name}Players`]: `${selectedPlayers[0]} - ${selectedPlayers[1]}`,
    }))
    setSelectedTeamPlayers((prevState) => ({
      ...prevState,
      [`${name}Players`]: `${selectedPlayers[0]} - ${selectedPlayers[1]}`,
    }))
  }

  const getMatchSetPoints = (sets, hostTeam, guestTeam) => {
    const hostTeamName = hostTeam?.teamName || null
    const guestTeamName = guestTeam?.teamName || null
    sets = !!sets ? sets : 0
    let setsCount = 1
    const textFields = []
    while (setsCount <= sets) {
      textFields.push(
        <>
          <Box sx={{ flexGrow: 1 }} m={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {' '}
                <TextField
                  fullWidth
                  id={`${hostTeamName || 'host'}_set_points_${setsCount}_id)`}
                  label={
                    <div>
                      {`${hostTeamName || 'Host'} score (set_${setsCount})`}
                      {setsCount >= 2 && (
                        <FormControlLabel
                          control={
                            <Switch
                              sx={{ marginLeft: '10px' }}
                              size="medium"
                              checked={
                                props.result
                                  ? props.result[
                                      `set${mapNumToWord[setsCount]}IsTieBreaker`
                                    ]
                                  : false
                              }
                              onChange={(e) =>
                                setResultDetails((prevState) => ({
                                  ...prevState,
                                  [`set${
                                    mapNumToWord[setsCount - 1]
                                  }IsTieBreaker`]: e.target.checked,
                                }))
                              }
                            />
                          }
                          label="Tie Breaker Set ?"
                        />
                      )}
                    </div>
                  }
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 25 } }}
                  color="primary"
                  helperText={
                    selectedTeamPayers[`hostTeamPlayers`] || 'Host Team'
                  }
                  // props?.result
                  //   ? props?.result["hostTeam"][
                  //       `set${mapNumToWord[setsCount]}Points`
                  //     ]
                  //   :
                  value={
                    resultDetails?.hostTeam[
                      `set${mapNumToWord[setsCount]}Points`
                    ]
                  }
                  name={`set${mapNumToWord[setsCount]}Points`}
                  onChange={(e) => handleSetsPoints(e, 'hostTeam')}
                />
                {/* TieBreakerPoints */}
                {resultDetails?.hostTeam[
                  `set${mapNumToWord[setsCount]}Points`
                ] >= 6 &&
                  resultDetails?.guestTeam[
                    `set${mapNumToWord[setsCount]}Points`
                  ] >= 6 && (
                    <TextField
                      fullWidth
                      id={`${
                        hostTeamName || 'host'
                      }_set_points_${setsCount}_id)-TieBreakerPoints`}
                      label={`${
                        hostTeamName || 'Host'
                      } Tie Breaker Points (set_${setsCount})`}
                      variant="outlined"
                      type="number"
                      InputProps={{ inputProps: { min: 0, max: 25 } }}
                      color="primary"
                      helperText={
                        selectedTeamPayers[`hostTeamPlayers`] || 'Host Team'
                      }
                      value={
                        resultDetails?.hostTeam[
                          `set${mapNumToWord[setsCount]}TieBreakerPoints`
                        ]
                      }
                      name={`set${mapNumToWord[setsCount]}TieBreakerPoints`}
                      onChange={(e) => handleSetsPoints(e, 'hostTeam')}
                    />
                  )}
                {setsCount === 3 && (
                  <TextField
                    fullWidth
                    id={`${
                      hostTeamName || 'host'
                    }_set_points_${setsCount}_id)-TieBreakerPoints`}
                    label={`${
                      hostTeamName || 'Host'
                    } Tie Breaker Points (set_${setsCount})`}
                    variant="outlined"
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 25 } }}
                    color="primary"
                    helperText={
                      selectedTeamPayers[`hostTeamPlayers`] || 'Host Team'
                    }
                    value={
                      resultDetails?.hostTeam[
                        `set${mapNumToWord[setsCount]}TieBreakerPoints`
                      ]
                    }
                    name={`set${mapNumToWord[setsCount]}TieBreakerPoints`}
                    onChange={(e) => handleSetsPoints(e, 'hostTeam')}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  color="secondary"
                  id={`${guestTeamName || 'guest'}_set_points_${setsCount}_id)`}
                  label={`${guestTeamName || 'Guest'} score (set_${setsCount})`}
                  helperText={
                    selectedTeamPayers[`guestTeamPlayers`] || 'Guest Team'
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 25 } }}
                  value={
                    resultDetails?.guestTeam[
                      `set${mapNumToWord[setsCount]}Points`
                    ]
                  }
                  name={`set${mapNumToWord[setsCount]}Points`}
                  onChange={(e) => handleSetsPoints(e, 'guestTeam')}
                />
                {/* TieBreakerPoints */}
                {resultDetails?.hostTeam[
                  `set${mapNumToWord[setsCount]}Points`
                ] >= 6 &&
                  resultDetails?.guestTeam[
                    `set${mapNumToWord[setsCount]}Points`
                  ] >= 6 && (
                    <TextField
                      fullWidth
                      color="secondary"
                      id={`${
                        guestTeamName || 'guest'
                      }_set_points_${setsCount}_id)-TieBreakerPoints`}
                      label={`${
                        guestTeamName || 'Guest'
                      } Tie Breaker Points (set_${setsCount})`}
                      helperText={
                        selectedTeamPayers[`guestTeamPlayers`] || 'Guest Team'
                      }
                      type="number"
                      InputProps={{ inputProps: { min: 0, max: 25 } }}
                      value={
                        resultDetails?.guestTeam[
                          `set${mapNumToWord[setsCount]}TieBreakerPoints`
                        ]
                      }
                      name={`set${mapNumToWord[setsCount]}TieBreakerPoints`}
                      onChange={(e) => handleSetsPoints(e, 'guestTeam')}
                    />
                  )}
                {setsCount === 3 && (
                  <TextField
                    fullWidth
                    color="secondary"
                    id={`${
                      guestTeamName || 'guest'
                    }_set_points_${setsCount}_id)-TieBreakerPoints`}
                    label={`${
                      guestTeamName || 'Guest'
                    } Tie Breaker Points (set_${setsCount})`}
                    helperText={
                      selectedTeamPayers[`guestTeamPlayers`] || 'Guest Team'
                    }
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 25 } }}
                    value={
                      resultDetails?.guestTeam[
                        `set${mapNumToWord[setsCount]}TieBreakerPoints`
                      ]
                    }
                    name={`set${mapNumToWord[setsCount]}TieBreakerPoints`}
                    onChange={(e) => handleSetsPoints(e, 'guestTeam')}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </>,
      )
      setsCount++
    }
    setMatchPointsFields(textFields)
  }

  const handleSetsPoints = (e, teamType) => {
    const points = parseInt(e.target.value)
    const name = e.target.name
    const setPoints = {}
    setPoints[name] = points

    setResultDetails((prevState) => ({
      ...prevState,
      [name]: points || {},
      [teamType]: { ...prevState[teamType], ...setPoints },
    }))
  }
  const getMatchSets = () =>
    wrapBoxItem(
      <>
        <Stack spacing={3}>
          <TextField
            id="numberOfMatchSets"
            label={`# of Sets`}
            variant="outlined"
            InputProps={{ inputProps: { min: 0, max: 5 } }}
            value={resultDetails.matchSetsSize || 0}
            type="number"
            onChange={(e) => {
              getMatchSetPoints(
                e.target.value,
                resultDetails['hostTeam'],
                resultDetails['guestTeam'],
              )
              setResultDetails((prevState) => ({
                ...prevState,
                ['matchSetsSize']: parseInt(e.target.value),
              }))
            }}
          />{' '}
        </Stack>
        <Stack spacing={1}>
          {matchPointsFields &&
            matchPointsFields.map((p, i) => <Paper key={i}>{p}</Paper>)}
        </Stack>
      </>,
    )

  const getTexField = (fieldName, inputType, labelText, childElements) =>
    wrapBoxItem(
      <Stack spacing={3}>
        <TextField
          id={`${fieldName}-id`}
          name={fieldName}
          label={labelText}
          variant="outlined"
          value={resultDetails[fieldName]}
          type={inputType}
          onChange={(e) =>
            setResultDetails((prevState) => ({
              ...prevState,
              [fieldName]: e.target.value,
            }))
          }
        />
        {childElements && getMatchSets()}
      </Stack>,
    )
  const leaguesSelectionInputElement = () => {
    return wrapBoxItem(
      <Stack spacing={3}>
        <Autocomplete
          fullWidth
          sx={{ mt: 2 }}
          id="matchType"
          options={TOURNAMENTS}
          value={resultDetails['tournamentName']}
          getOptionLabel={(option) => option}
          onChange={(e, v) =>
            setResultDetails((prevState) => ({
              ...prevState,
              ['tournamentName']: v,
            }))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Result for"
              name="matchType"
              placeholder="Match Type"
            />
          )}
          // sx={{ width: "500px" }}
        />
      </Stack>,
    )
  }
  const getFormControlField = (fieldName) => {
    const currentTeam = resultDetails[`${fieldName}Team`] || {}
    const currentTeamId = currentTeam['teamId'] || 0
    const disabledTeam =
      fieldName === 'winning'
        ? null
        : fieldName === 'host'
        ? resultDetails?.['guestTeam']?.teamName
        : resultDetails?.['hostTeam']?.teamName

    let _playingTeams = []
    if (fieldName === 'winning') {
      _playingTeams = [resultDetails['hostTeam'], resultDetails['guestTeam']]
    }
    const formControl = (
      <FormControl fullWidth>
        {/* <InputLabel id={`${fieldName}-team-input-label`}>
          {capitalize(fieldName)} Team
        </InputLabel> */}
        <Autocomplete
          fullWidth={true}
          size="large"
          // multiple
          id={`${fieldName}-team-id`}
          disabled={teams?.length < 1}
          defaultValue={teams.find((t) => t.teamId === parseInt(currentTeamId))}
          options={fieldName === 'winning' ? _playingTeams : teams}
          getOptionLabel={(option) => option.teamName || ''}
          getOptionDisabled={(option) => option.teamName === disabledTeam}
          onChange={(e, value) => {
            handleTeamsForm(value.teamId, `${fieldName}Team`)
          }}
          renderInput={(params) => (
            <TextField
              // color={
              //   selectedPlayers && selectedPlayers.length === 2
              //     ? 'success'
              //     : 'error'
              // }
              variant="outlined"
              {...params}
              label={`${capitalize(fieldName)} Team`}
            />
          )}
        />
        {/* <Select
          value={parseInt(currentTeamId)}
          name={`${fieldName}Team`}
          label={`${fieldName}-label`}
          labelId={`${fieldName}-label-id`}
          id={`${fieldName}-team-id`}
          onChange={(e) => handleTeamsForm(e.target.value, e.target.name)}
          autoWidth
        >
          {teams.map((t) => (
            <MenuItem
              key={t.teamId}
              value={t.teamId}
              name={t.teamName}
              {...{
                disabled: getDisabledFlag(resultDetails, fieldName, t.teamId),
              }}
            >
              {t.teamName} [{t.teamPlayersFullNames[0]}-
              {t.teamPlayersFullNames[1]}]
            </MenuItem>
          ))}
        </Select> */}
        <FormHelperText sx={{ color: 'green' }}>
          {selectedTeamPayers[`${fieldName}TeamPlayers`]}
        </FormHelperText>
      </FormControl>
    )
    return wrapBoxItem(formControl)
  }

  const getTeamPoints = (teamType) => {
    let points = 0
    if (
      resultDetails['matchStatus'] === MATCH_STATUSES[0].label ||
      resultDetails['matchStatus'] === MATCH_STATUSES[4].label
    ) {
      points =
        resultDetails[teamType]?.teamName ===
        resultDetails['winningTeam']?.teamName
          ? resultDetails['matchPoints']
          : 0
    } else if (
      resultDetails['matchStatus'] === 'draw' ||
      resultDetails['matchStatus'] === 'rain-out'
    ) {
      points = 1
    }
    return points
  }
  const getTotalGames = (teamType) => {
    const one = resultDetails[teamType]?.setOnePoints || 0
    const two = resultDetails[teamType]?.setTwoPoints || 0
    const three = resultDetails[teamType]?.setThreePoints || 0
    const four = resultDetails[teamType]?.setFourPoints || 0
    const five = resultDetails[teamType]?.setFivePoints || 0
    const sum = one + two + three + four + five
    return sum
  }

  const handleSubmit = () => {
    setSubmitted(true)
    resultDetails['createdBy'] = resultDetails['createdBy'] || user?.email
    // team points
    const hostWon = getTotalGames('hostTeam')
    // teams win/lost info
    let teamResult = null
    const _teams = ['hostTeam', 'guestTeam']
    _teams.forEach((team) => {
      if (['completed', 'forfeited'].includes(resultDetails?.matchStatus)) {
        teamResult =
          resultDetails[team].teamId === resultDetails['winningTeam'].teamId
            ? 'WON'
            : 'LOST'
        resultDetails[team] = {
          ...resultDetails[team],
          ...{ teamResult },
        }
      } else if (resultDetails?.matchStatus === 'draw') {
        teamResult = 'DRAW'
        resultDetails[team] = {
          ...resultDetails[team],
          ...{ teamResult },
        }
      }
    })

    const guestWon = getTotalGames('guestTeam')
    resultDetails['points'] = [
      {
        teamName: resultDetails['hostTeam']?.teamName,
        teamId: resultDetails['hostTeam']?.teamId,
        teamPlayers: resultDetails?.hostTeamPlayers,
        teamPlayersId: resultDetails['hostTeam']?.teamPlayersIds,
        points: getTeamPoints('hostTeam'),
        gamesWon: hostWon,
        gamesLost: guestWon,
        xGamesWon: xGamesWonHost,
        xGamesLost: xGamesLostHost,
        netWinRatio: hostWon - guestWon,
      },
      {
        teamName: resultDetails['guestTeam']?.teamName,
        teamId: resultDetails['guestTeam']?.teamId,
        teamPlayers: resultDetails?.guestTeamPlayers,
        teamPlayersId: resultDetails['guestTeam']?.teamPlayersIds,
        points: getTeamPoints('guestTeam'),
        gamesWon: guestWon,
        gamesLost: hostWon,
        xGamesWon: xGamesWonGuest,
        xGamesLost: xGamesLostGuest,
        netWinRatio: guestWon - hostWon,
      },
    ]
    if (props?.result?.resultId) {
      resultDetails['resultId'] = props?.result?.resultId
    }
    makePostCall(resultDetails, 'result', token, setSubmitButton)
    props.callBackFunc()
    setOpenModel(true)
  }
  const readyToSubmit = () => {
    if (
      (resultDetails.matchStatus === MATCH_STATUSES[0].label ||
        resultDetails.matchStatus === MATCH_STATUSES[4].label) &&
      !resultDetails['winningTeam'].teamId
    ) {
      return false
    }
    return true
  }
  return (
    <>
      {teamsFetchStatus === 'loading' && (
        <>
          <Spinner text="Loading  data..." />
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </>
      )}

      {isAuthenticated && teams && teams?.length < 1 && (
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            mt: 1,
            mb: 1,
            bgcolor: '#149e72',
            color: 'warning.contrastText',
            borderRadius: 1,
          }}
        >
          Teams are not registered yet.
        </Typography>
      )}
      {isAuthenticated ? (
        <>
          {teams.length > 1 && (
            <Button fullWidth variant="contained" color="warning">
              Submit Match Score
            </Button>
          )}
        </>
      ) : (
        <>
          <Button
            sx={{ my: '40px' }}
            fullWidth
            variant="contained"
            color="error"
            onClick={loginWithPopup}
          >
            Without login, score can not be submitted !
            <NavigationIcon sx={{ mr: 1 }} />
          </Button>
        </>
      )}
      {/* {teamsFetchStatus !== "succeeded" && (
        <Paper>
          <LoadingButton
            fullWidth
            loadingIndicator="success"
            loadingPosition="center"
            variant="contained"
            loading
            loadingIndicator="Wait Loading......"
            variant="outlined"
            align="center"
          ></LoadingButton>
        </Paper>
      )} */}
      {isAuthenticated &&
        teamsFetchStatus === 'succeeded' &&
        teams?.length > 1 && (
          <>
            {/* Host Team */}
            {getFormControlField('host')}
            {/* Guest Team */}
            {getFormControlField('guest')}
            {/* Match Status */}
            {wrapBoxItem(
              <>
                <Grid container spacing={1}>
                  <Grid item xs={7.5}>
                    <FormControl fullWidth>
                      <InputLabel id="match-status-label">
                        Match Status
                      </InputLabel>
                      <Select
                        labelId="match-status-label"
                        id="matchStatus"
                        value={resultDetails.matchStatus || ''}
                        name="matchStatus"
                        autoWidth
                        onChange={(e) =>
                          setResultDetails((prevState) => ({
                            ...prevState,
                            ['matchStatus']: e.target.value,
                          }))
                        }
                        label="Match Status"
                      >
                        {MATCH_STATUSES.map((t) => (
                          <MenuItem key={t.id} value={t.label} name={t.label}>
                            {t.title}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <FormHelperText>{guestTeamPlayers}</FormHelperText> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4.5}>
                    <TextField
                      fullWidth
                      // defaultValue={resultDetails.matchStatus === "completed" ? 3 : 0}
                      type="number"
                      InputProps={{ inputProps: { min: 0, max: 5 } }}
                      id="matchPoints"
                      label="Enter Match Points"
                      variant="outlined"
                      value={resultDetails['matchPoints']}
                      onChange={(e) =>
                        setResultDetails((prevState) => ({
                          ...prevState,
                          ['matchPoints']: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                </Grid>
              </>,
            )}
            {/* TODO playingTeams.length > 1 && */}
            {(resultDetails.matchStatus === MATCH_STATUSES[0].label ||
              resultDetails.matchStatus === MATCH_STATUSES[4].label) &&
              getFormControlField('winning')}
            {/* Match SET POINTS */}
            {isMatchPlayed(resultDetails.matchStatus) && getMatchSets()}
            {/* {getSetPoints} */}
            {/* Winning Team */}
            {/* Match time and Date */}
            {wrapBoxItem(
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DatePicker
                    clearable
                    value={resultDetails['matchTimeAndDate']}
                    onChange={(e) =>
                      setResultDetails((prevState) => ({
                        ...prevState,
                        ['matchTimeAndDate']: e,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField {...params} helperText="Enter match date" />
                    )}
                  />
                </Stack>
              </LocalizationProvider>,
            )}
            {/* new Tournament Name */}
            {/* {getTexField("tournamentName", "text", "Tournament  name")} */}
            {leaguesSelectionInputElement()}
            {getTexField('resultComments', 'text', 'Additional comments ...')}
            {/* Tournament Name */}
            {wrapBoxItem(
              <>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={resultDetails['matchType']}
                    onChange={(e) =>
                      setResultDetails((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value,
                      }))
                    }
                  >
                    <FormControlLabel
                      value="Single"
                      name="matchType"
                      control={<Radio />}
                      label="Single Player"
                    />
                    <FormControlLabel
                      value="Double"
                      name="matchType"
                      control={<Radio />}
                      label="Double Players"
                    />
                  </RadioGroup>
                </FormControl>
                {BasicModal(
                  openModel,
                  {
                    severity: submitButton.severity,
                    message: submitButton.message,
                  },
                  'Submit new Score',
                  setOpenModel,
                )}
                <>
                  {!submitButton.severity && (
                    <Button
                      onClick={handleSubmit}
                      fullWidth
                      disabled={
                        submitButton.severity === 'success' ||
                        !readyToSubmit() ||
                        submitted
                      }
                      variant="contained"
                      disableElevation
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Submit Result.. :) {submitButton.severity}
                    </Button>
                  )}
                </>
              </>,
            )}
          </>
        )}
    </>
  )
}
