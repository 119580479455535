import Chip from '@material-ui/core/Chip'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import PublishIcon from '@mui/icons-material/Publish'
// import { Box, Chip, TextField } from '@material-ui/core'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SimpleSnackbar from '../notifications/NotificationMsg'
import { uploadToS3 } from './SubmitFileToS3'
import useFileChange from './UseFileChange'
//  import { storeFileInfo } from '../../redux/data-store.slice'

const FileUploadPage = (props) => {
  //props
  const {
    fieldName,
    resource,
    handleChange,
  } = props
  console.log('### props== ', props)
  const {
    fileError,
    fileName,
    fileContents,
    fileType,
    fileDispatch,
    handleFileChange,
  } = useFileChange()

  const [isSubmitted, setIsSubmitted] = React.useState(false)
  // const fileInfo = useSelector((state) => {
  //   return state?.dataStore?.fileInfo
  // })
  const dispatch = useDispatch()
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
		if (fileType && fileContents) {
        const fileResp = await uploadToS3({
          fieldName,
          fileType,
          fileContents,
          season: resource,
        })
        if (fileResp) {
          setIsSubmitted(true)
          // storing file info
          const _fileInfo = {
            fieldName,
            fileType,
            resource,
            fileName: fileResp.fileName,
            [`${fieldName}Url`]: fileResp?.preSignedGetObj,
          }
          // sending to store main event object
          handleChange(_fileInfo)
          // dispatch(
          // 	storeFileInfo({
          // 		...fileInfo,
          // 		..._fileInfo,
          // 	})
          // )
        }
        fileDispatch({ type: 'RESET_FILE_STATE' })
      } else {
        console.error('missing info', { fileType, fileContents })
      }
    } catch (err) {
      console.log('error is', err)
    }
  }
  return (
    <>
      <div className="w-full">
        <div className="flex flex-col items-center justify-center mt-40">
          {fileError && (
            <h1 className="max-w-3xl text-3xl text-red-600">{fileError}</h1>
          )}
          <div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col items-center mt-2">
                <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 5 }}>
                  <AttachFileIcon
                    sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                  />
                  <TextField
                    id="input-with-sx"
                    variant="standard"
                    onChange={(e) => handleFileChange(e)}
                    type="file"
                  />
                  <Chip
                    label="Save file"
                    color="primary"
                    clickable={fileName ? true : false}
                    disabled={fileName ? false : true}
                    onClick={handleSubmit}
                    onDelete={handleSubmit}
                    deleteIcon={<PublishIcon />}
                  />
                </Box>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isSubmitted && (
        <SimpleSnackbar
          variant="success"
          msg={`file is uploaded.`}
        ></SimpleSnackbar>
      )}
    </>
  )
}

export default FileUploadPage
