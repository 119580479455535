import React, { useState, useRef } from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import IconButton from '@mui/material/IconButton'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { getWindowDimensions } from '../utils/utils'
import { Button, SnackbarContent } from '@mui/material'

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  }
}

export function PhotoGallery() {
  const { width } = getWindowDimensions()
  const [loading, setLoading] = useState(true)
  const counter = useRef(0)

  const imageLoaded = () => {
    counter.current += 1
    if (counter.current >= itemData.length) {
      setLoading(false)
    }
  }

  const getImagesList = () => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
    return (
      <ImageList
        sx={{
          width: { width },
          height: { width },
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: 'translateZ(0)',
        }}
        gap={1}
      >
        {itemData.map((item) => {
          const cols = item.featured ? 2 : 1
          const rows = item.featured ? 2 : 1

          return (
            <ImageListItem key={item.img} cols={cols} rows={rows}>
              <img
                {...srcset(item.img, 250, 200, rows, cols)}
                alt={item.title}
                loading="lazy"
                onLoad={imageLoaded}
              />
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                }}
                title={item.title}
                position="top"
                actionIcon={
                  <IconButton
                    sx={{ color: 'white' }}
                    aria-label={`star ${item.title}`}
                    color="secondary"
                  >
                    <StarBorderIcon color={item.iconColor} />
                  </IconButton>
                }
                actionPosition="left"
              />
            </ImageListItem>
          )
        })}
      </ImageList>
    )
  }

  return (
    <React.Fragment>
      <div style={{ display: loading ? 'block' : 'none' }}>
        <SnackbarContent
          message={'Welcome to Round Rock Tennis'}
          action={
            <Button color="secondary" size="small">
              Play with spirit!
            </Button>
          }
        />
      </div>
      <div style={{ display: loading ? 'none' : 'block' }}>
        {getImagesList()}
      </div>
    </React.Fragment>
  )
}

const itemData = [

  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-final-tennis.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Spring 2023 Trohphies',
    iconColor: 'error',
  },
    {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/fall-2022-champions.jpeg',
    author: 'zulfi',
    title: 'Champions Spring 2023',
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/fall-2023-final-trophies-tennis.jpeg',
    author: 'zulfi',
    title: 'Fall 2022 - Trohphies',
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/fall-2023-runner-up.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Fall 2022 - Runnersup',
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/final-2023-champions.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Champions Spring 2023',
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-champions-tennis.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Champions Asif-Badar Spring 2023',
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-champions.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Champions Asif-Badar Spring 2023',
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-fall-2022-chamipions-runners-up.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Champions-runners-up Fall2022 Spring 2023',
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-final-best-referee.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Spring 2023 -referees',
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-final-tennis.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Spring 2023 Trohphies',
    iconColor: 'error',
  },  {
    img:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-refereers-and-committee.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Spring 2023 -referees',
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/fall-2022/fall-2022-teams.jpg',
    title: '#Fall2022-championship',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/champions-1-summer-2022.jpg',
    title: '#Champions-summer-2022',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/champions-summer-2022.jpg',
    title: 'champions@s-2022',
    author: '@arwinneil',
    iconColor: 'warning',
    featured: false,
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/winners-2-summer-2022.jpg',
    title: 'winners@s-2022',
    author: '@southside_customs',
    iconColor: 'secondary',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/runner-up-summer-2022.jpg',
    title: 'runner-up@summer-2022',
    author: '@southside_customs',
    iconColor: 'warning',
    featured: true,
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/adeel-summer-2022.jpg',
    title: 'runner-up@s-2022',
    iconColor: 'info',
    author: '@helloimnik',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/ali-summer-2022.jpg',
    title: 'runner-up@s-2022',
    iconColor: 'primary',
    author: '@nolanissac',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/abdul-samad-summer-2022.jpg',
    title: 'Abdul Samad -chief-guest',
    author: '@rollelflex_graphy726',
    iconColor: 'secondary',
    featured: true,
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/badar-summer-2022.jpg',
    title: 'winner@S-2022',
    author: '@hjrc33',
    iconColor: 'error',
  },

  //   {
  //     img: "https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/chief-guest-summer-2022.jpg",
  //     title: "Abdul Samad - chief guest",
  //     author: "@tjdragotta",
  //     iconColor: "success",
  //   },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/finalist-summer-2022.jpg',
    title: 'finalist@s-2022',
    author: '@katie_wasserman',
    iconColor: 'warning',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/Tennis-semifinals-v4.jpg',
    title: 'semifinalist@s-2022',
    author: '@silverdalex',
    iconColor: 'secondary',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/rrt-players-2-summer-2022.jpg',
    title: 'final-night',
    author: '@silverdalex',
    iconColor: 'secondary',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/rrt-players-summer-2022.jpg',
    title: 'round rock tennis players',
    author: '@shelleypauls',
    iconColor: 'secondary',
    featured: true,
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/rrtn-players-2-summer-2022.jpg',
    title: 'final-evening@s-2022',
    author: '@peterlaster',
    iconColor: 'secondary',
  },

  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/winners-summer-2022.jpg',
    title: 'winner-summer-2022',
    author: '@southside_customs',
    iconColor: 'info',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/final-summer-2022-v2.jpg',
    title: 'final-summer-2022',
    author: '@southside_customs',
    iconColor: 'success',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/summer-2022-trophy.jpeg',
    title: 'trophy-s-2022',
    author: '@southside_customs',
    iconColor: 'success',
  },
  {
    img:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/summer-2022-semifinal-evening.jpeg',
    title: 'semifinal-evening',
    author: '@southside_customs',
    iconColor: 'info',
    featured: true,
  },
]
