// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
import axios from 'axios'
import { getBaseUrl } from '../utils/utils'
export async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = { 'Content-Type': 'application/json' }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let data
  try {
    const response = await window.fetch(endpoint, config)
    data = await response.json()
    if (response.ok) {
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data)
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' })
}

client.put = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'PUT' })
}

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body })
}

export const makePostCall = async (
  submitResourceDetails,
  resourceName,
  token,
  callBackFunc,
  validName = false,
  successMsg = null,
) => {
  const url = validName
    ? `${getBaseUrl()}${resourceName}`
    : `${getBaseUrl()}${resourceName}s`

  try {
    await axios.post(url, submitResourceDetails, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    callBackFunc({
      message: `${
        successMsg ? successMsg : resourceName
      } is successfully submitted.`,
      severity: 'success',
    })
  } catch (error) {
    console.error('Data submission Error:', error)

    callBackFunc({
      message: 'oops ... something went wrong, please try later.',
      severity: 'error',
    })
  }
}

export const makePatchCall = (submitResourceDetails, resourceName, token) => {
  const url = `${getBaseUrl()}${resourceName}s`
  console.log('POST URL:', url)

  fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(submitResourceDetails),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      console.log('Response from backend: ', response)
    })
    .catch((error) => {
      console.error('Data update Error:', error)
    })
}

export const makeDeleteCall = (
  resourceId,
  resourceName,
  token,
  callBackFunc,
) => {
  const url = `${getBaseUrl()}${resourceName}s/${resourceId}`
  fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      console.log('Response from backend: ', response)
      callBackFunc({
        message: `${resourceName} is successfully submitted.`,
        severity: 'success',
      })
    })
    .catch((error) => {
      console.error('Data delete Error:', error)
      callBackFunc({
        message: 'oops ... something went wrong, please try later.',
        severity: 'error',
      })
    })
}
