import Filter7OutlinedIcon from '@mui/icons-material/Filter7Outlined'
import Filter8OutlinedIcon from '@mui/icons-material/Filter8Outlined'
import Filter9OutlinedIcon from '@mui/icons-material/Filter9Outlined'
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined'
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined'
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined'
import Looks5OutlinedIcon from '@mui/icons-material/Looks5Outlined'
import Looks6OutlinedIcon from '@mui/icons-material/Looks6Outlined'
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined'
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined'
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import React, { useState } from 'react'
import ReactCardFlip from 'react-card-flip'
import Ticker from 'react-ticker'
// import { getDispDate } from '../utils/utils'

const pointsIcon = (points) => {
  switch (points) {
    case 0:
      return <SentimentDissatisfiedOutlinedIcon color="warning" size="small" />
    case 1:
      return <LooksOneOutlinedIcon color="warning" size="small" />
    case 2:
      return <LooksTwoOutlinedIcon color="warning" size="small" />
    case 3:
      return <Looks3OutlinedIcon color="warning" size="small" />
    case 4:
      return <Looks4OutlinedIcon color="warning" size="small" />
    case 5:
      return <Looks5OutlinedIcon color="warning" size="small" />
    case 6:
      return <Looks6OutlinedIcon color="success" size="small" />
    case 7:
      return <Filter7OutlinedIcon color="warning" size="small" />
    case 8:
      return <Filter8OutlinedIcon color="warning" size="small" />
    case 9:
      return <Filter9OutlinedIcon color="success" size="small" />
    default:
      return <LocalCafeOutlinedIcon color="warning" size="small" />
  }
}

export default function MathesResults({ mappedResult, _onNext }) {
  const [fabColor, setFabColor] = useState('info')
  const [isFlipped, setIsFlipped] = useState(true)
  const handleFinish = () => {
    setFabColor(fabColor === 'info' ? 'secondary' : 'info')
    setIsFlipped(!isFlipped)
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIsFlipped(!isFlipped)
  //   }, 5000)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [isFlipped])

  return (
    <Ticker mode="smooth" speed={5} onNext={_onNext} onFinish={handleFinish}>
      {() => (
        <>
          <Box
            sx={{
              '& > :not(style)': { m: 0 },
              boxShadow: 30,
              // border: 1,
              // borderLeft: 0,
              // borderRight: 0,
              // borderTop: 0,
              // borderColor: 'red',
            }}
          >
            {/* {getDispDate(matchTimeAndDate)} */}
            <Fab
              variant="extended"
              color={fabColor}
              size="small"
              sx={{
                border: 1,
                borderRight: 0,
                borderTop: 0,
                borderColor: 'red',
              }}
            >
              <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
                <h5>{mappedResult?.hostName}</h5>
                <h5>{mappedResult?.hostPlayers}</h5>
              </ReactCardFlip>
            </Fab>
            <Fab
              aria-label="like"
              size="small"
              sx={{
                ml: 0.5,
                border: 1,
                borderLeft: 0,
                borderRight: 0,

                borderColor: 'red',
              }}
            >
              {pointsIcon(mappedResult?.hostPoints)}
            </Fab>
            <Fab
              aria-label="like"
              size="small"
              sx={{
                mr: 0.5,
                border: 1,
                borderLeft: 0,
                borderRight: 0,

                borderColor: 'red',
              }}
            >
              {pointsIcon(mappedResult?.guestPoints)}
            </Fab>
            <Fab
              variant="extended"
              color={fabColor}
              size="small"
              sx={{
                border: 1,
                borderLeft: 0,
                borderTop: 0,
                borderColor: 'red',
              }}
            >
              <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
                <h5>{mappedResult?.guestName}</h5>
                <h5>{mappedResult?.guestPlayers}</h5>
              </ReactCardFlip>
            </Fab>
          </Box>
        </>
      )}
    </Ticker>
  )
}
