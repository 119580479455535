import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Link, Typography } from '@mui/material'
import ButtonBase from '@mui/material/ButtonBase'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { orderBy } from 'lodash'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import { useDispatch, useSelector } from 'react-redux'
import { calculatePoints } from '../utils/utils'
import { fetchPlayers, selectAllPlayers } from './players.slice'
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '50%',
  border: '2px solid white',
})

const CenteredGridItem = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export default function ComplexGrid() {
  const [imageLoaded, setImageLoaded] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const [players, setPlayers] = React.useState(null)
  const playersStatus = useSelector((state) => state.players.status)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
  }, [playersStatus, dispatch])

  useEffect(() => {
    if (playersStatus === 'succeeded') {
      setLoading(false)
    }
  }, [playersStatus])

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  useEffect(() => {
    if (loading) return
    const filteredPlayers = allPlayers.filter((p) => p?.matchesWon > 0)
    const orderPlayers = orderBy(filteredPlayers, ['matchesWon'], ['desc'])
    setPlayers(orderPlayers)
  }, [loading])

  if (loading) {
    return <div>Loading...</div>
  }

  const getPlayerProfile = (playerId) => {
    navigate(`/players/${playerId}`)
  }

  return (
    <Carousel
      indicators={false}
      animation="fade"
      interval={2500}
      sx={{ height: '283px' }}
    >
      {players &&
        players.map((player, index) => {
          return (
            <Grid container width="550px" spacing={2} key={`grid-key-${index}`}>
              <CenteredGridItem item xs={6} sm={6} md={4}>
                <ButtonBase sx={{ width: '100%' }}>
                  <Img
                    alt="complex"
                    src={
                      player?.avatarImgUrl ||
                      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/avatars/general.jpeg'
                    }
                    className={imageLoaded ? 'loaded' : ''}
                    onLoad={handleImageLoad}
                  />
                </ButtonBase>
                <Link
                  variant="caption"
                  sx={{ fontWeight: 'bold', textAlign: 'center' }}
                  onClick={(e) => getPlayerProfile(player.playerId)}
                >
                  {player?.fullName}
                </Link>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  {player?.teamName}
                </Typography>
              </CenteredGridItem>
              <Grid item xs={6} sm={6} md={8} container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    {OppositeContentTimeline(player)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
    </Carousel>
  )
}

function OppositeContentTimeline(player) {
  const calculatedInfo = calculatePoints(player) || 0
  const points = Math.floor(calculatedInfo?.totalPoints)
  return (
    <>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            Points
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>{points}</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {player?.matchesWon || '0'}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="success" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Won</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            Lost
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="warning" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent> {player?.matchesLost || '0'}</TimelineContent>
        </TimelineItem>
      </Timeline>
    </>
  )
}
