import { client } from '../../../api/client'
import { getBaseUrl } from '../../../utils/utils'
// import { client, getBaseUrl } from '../../api/client'

export async function uploadToS3({
  fileType,
  fieldName,
  season,
  fileContents,
}) {
  const fileExtension = fileType.substr(fileType.lastIndexOf('/') + 1)
  const dStr = new Date().valueOf()
  const _fieldName = fieldName.trim().replace(/\s+/g, '-').toLowerCase()
  const _season = season.trim().replace(/\s+/g, '-').toLowerCase()
  const fileName = `${dStr}-${_fieldName}.${fileExtension}`.replace(
    /\s/g,
    '',
  )
  const getSelfSignedUrl = `${getBaseUrl()}common/self-signed-url/${_season}/${fileName}`
  const body = [
    { key: 'name', value: 'zulfi' },
    { key: 'service', value: '2023' },
  ]
  const { data } = await client.post(getSelfSignedUrl, body)
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', fileType)
  const putSelfSignedUrl = data?.preSignedPutObj
  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: fileContents,
    redirect: 'follow',
  }
  fetch(putSelfSignedUrl, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log('error', error))
  data['fileName'] = fileName
  return data
}
