import { getDispDateYear2, getPlayerFirstNames } from './utils'

export const mockPlayers = [
  {
    firstName: 'zulfi',
    lastName: 'Ahmad',
    email: 'email',
    phone: '12',
    playerId: 1,
    fullName: 'zulfi-Ahmad',
  },
  {
    firstName: 'Malik',
    lastName: 'ABC',
    email: 'email',
    phone: '10',
    playerId: 2,
    fullName: 'Malik-ABC',
  },
  {
    firstName: 'DDC',
    lastName: 'ABC',
    email: 'jang',
    phone: '10',
    playerId: 3,
    fullName: 'DDC-ABC',
  },
]
const Players = () => {
  return mockPlayers
}
export default Players

const getResultObj = (result, matchResult, isHost) => {
  return {
    matchResult,
    id: result.resultId,
    matchStatus: result.matchStatus,
    matchDate: getDispDateYear2(result.matchTimeAndDate),
    teams: `${result.hostTeam.teamName} VS ${result.guestTeam.teamName}`,
    matchType: result.matchType,
    matchSetSize: result.matchSetsSize,
    resultComments: result.resultComments,
    playingWithPlayers: isHost
      ? getPlayerFirstNames(result.hostTeamPlayers)
      : getPlayerFirstNames(result.guestTeamPlayers),
    playingAgainstPlayers: !isHost
      ? getPlayerFirstNames(result.hostTeamPlayers)
      : getPlayerFirstNames(result.guestTeamPlayers),
    playingWithTeam: isHost
      ? result.hostTeam.teamName
      : result.guestTeam.teamName,
    playingAgainstTeam: !isHost
      ? result.hostTeam.teamName
      : result.guestTeam.teamName,
    gamesWon: isHost ? result.points[0].gamesWon : result.points[1].gamesWon,
    gamesLost: !isHost ? result.points[0].gamesWon : result.points[1].gamesWon,
    winningTeam: result.winningTeam.teamName,
    winningTeamPlayer: result.winningTeamPlayers,
  }
}
export const getResultsByPlayers = (results) => {
  const resultsByPlayer = {}
  results.forEach((result) => {
    // if (result.matchStatus === 'completed') {
    const houstPlayer1 = result.hostTeam.teamPlayersIds[0]
    resultsByPlayer[houstPlayer1] = resultsByPlayer[houstPlayer1] || []
    const houstPlayer2 = result.hostTeam.teamPlayersIds[1]
    resultsByPlayer[houstPlayer2] = resultsByPlayer[houstPlayer2] || []
    const hostWon =
      result.hostTeam.teamName === result.winningTeam.teamName ? 'Won' : 'Lost'
    const houstResult = getResultObj(result, hostWon, true)
    const guestPlayer1 = result.guestTeam.teamPlayersIds[0]
    resultsByPlayer[guestPlayer1] = resultsByPlayer[guestPlayer1] || []
    const guestPlayer2 = result.guestTeam.teamPlayersIds[1]
    resultsByPlayer[guestPlayer2] = resultsByPlayer[guestPlayer2] || []
    const guestWon =
      result.guestTeam.teamName === result.winningTeam.teamName ? 'Won' : 'Lost'
    const guestResult = getResultObj(result, guestWon, false)
    resultsByPlayer[houstPlayer1].push(houstResult)
    resultsByPlayer[houstPlayer2].push(houstResult)
    resultsByPlayer[guestPlayer1].push(guestResult)
    resultsByPlayer[guestPlayer2].push(guestResult)
    // }
  })
  return resultsByPlayer
}
