import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Chip, Divider, Typography } from '@mui/material'
import GrainIcon from '@mui/icons-material/Grain'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { sortBy } from 'lodash'
import { allResults, fetchResult } from '../results/result.slice'
import {
  getPlayerFirstNames,
  getWindowDimensions,
  MATCH_STATUSES,
} from '../../utils/utils'
import QuickMenu from '../../components/quick-menu'
import { MATCHES_TYPES } from '../../utils/constants'

export default function DenseTable() {
  const { width } = getWindowDimensions()
  const dispatch = useDispatch()
  const [points, setPoints] = useState([])
  const resultsFetchStatus = useSelector((state) => {
    return state?.results?.status
  })
  const results = useSelector(allResults)

  const [matchRadioSelection, setMatchRadioSelection] = React.useState(0)

  useEffect(() => {
    if (resultsFetchStatus === 'idle') {
      dispatch(fetchResult())
    }
  }, [resultsFetchStatus, dispatch])

  useEffect(() => {
    if (resultsFetchStatus === 'succeeded') {
      let matchPoints = []
      // const filteredMatches = results.filter(
      //   (s) => s.tournamentName === MATCHES_TYPES[matchRadioSelection].value,
      // )

      const filteredMatches = results.filter(
        (s) => s.tournamentName === MATCHES_TYPES[matchRadioSelection].value,
      )
      const currentDate = new Date() // Get the current date
      const last60Days = currentDate.setDate(currentDate.getDate() - 365) //

      const dataFilteredByDate = filteredMatches.filter((match) => {
        const matchDate = new Date(match.matchTimeAndDate)
        return matchDate >= last60Days
      })

      dataFilteredByDate.forEach((element) => {
        if (
          element.matchStatus === MATCH_STATUSES[0].label ||
          element.matchStatus === MATCH_STATUSES[2].label ||
          element.matchStatus === MATCH_STATUSES[4].label ||
          element.matchStatus === MATCH_STATUSES[8].label
        ) {
          matchPoints = [...matchPoints, ...element.points]
        }
      })
      const _calculatedPoints = Object.values(
        matchPoints.reduce(
          (
            r,
            { teamName, points, teamPlayers, gamesWon, gamesLost, netWinRatio },
          ) => (
            (r[teamName] = {
              teamName,
              teamPlayers,
              gamesWon: (r[teamName]?.gamesWon || 0) + +gamesWon,
              gamesLost: (r[teamName]?.gamesLost || 0) + +gamesLost,
              netWinRatio: (r[teamName]?.netWinRatio || 0) + +netWinRatio,
              teamMatches: (r[teamName]?.teamMatches || 0) + +1,
              teamPoints: (r[teamName]?.teamPoints || 0) + +points,
            }),
            r
          ),
          {},
        ),
      )
      const calculatedPoints = _calculatedPoints.map((p) => {
        const r = p.gamesWon / p.gamesLost
        return { ...p, ...{ percentRatio: r } }
      })
      console.log('calculatedPoints ', calculatedPoints)
      const sortedPoints = sortBy(calculatedPoints, [
        'teamPoints',
        'percentRatio',
      ])
      setPoints(sortedPoints.reverse())
    }
  }, [resultsFetchStatus, matchRadioSelection])

  const handleChange = (event) => {
    setMatchRadioSelection(event.target.value)
  }

  const getRadioSelection = () => {
    return (
      <>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={matchRadioSelection}
            onChange={handleChange}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label={MATCHES_TYPES[0].value}
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={MATCHES_TYPES[1].value}
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={MATCHES_TYPES[3].value}
            />
            {/* <FormControlLabel
              value={MATCHES_TYPES[2].key}
              control={<Radio />}
              label={MATCHES_TYPES[2].value}
            /> */}
          </RadioGroup>
        </FormControl>
      </>
    )
  }

  return (
    <>
      <Box sx={{ width: width }}>
        <QuickMenu></QuickMenu>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            m: 1,
            bgcolor: '#252222',
            color: 'warning.contrastText',
            borderRadius: 1,
          }}
        >
          Teams <GrainIcon color="secondary"></GrainIcon>Standing
        </Typography>
        <Paper align="center">
          <Divider>
            <Typography variant="h6" component="h4" color="#b113b1">
              SEASON 09 - <span style={{ color:"#e5470f" }}>RRTL: Fall Tournament</span>
            </Typography>
          </Divider>
        </Paper>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            // p: 1,
            // m: 1,
            bgcolor: '#e1e9df',
            color: 'warning.contrastText',
            borderRadius: 1,
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              m: 1,
              justifyContent: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow
                  sx={{
                    'td, th': {
                      border: 1,
                      color: 'white',
                      fontWeight: 'bold',
                      backgroundColor: '#2d6ac1',
                    },
                    th: {
                      border: 1,
                      fontSize: '9px',
                    },
                  }}
                >
                  <TableCell align="left">Team</TableCell>
                  <TableCell align="center" width="5%">
                    Matches
                  </TableCell>
                  <TableCell align="center" width="3%">
                    Points
                  </TableCell>
                  <TableCell align="center" width="8%">
                    Win Ratio
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {points
                  // .sort((a, b) => a.teamPoints - b.teamPoints)
                  // .reverse()
                  .map((row) => (
                    <TableRow
                      key={row.teamName}
                      sx={{
                        'td, th': { border: 1, borderColor: 'grey' },
                        '&:nth-of-type(even)': {
                          backgroundColor: '#b2b2b2',
                        },
                      }}
                    >
                      {/* <TableCell component="th" scope="row">
                {row.teamName}
              </TableCell> */}
                      <TableCell align="left">
                        {row.teamName}
                        <Chip
                          sx={{
                            '& .MuiChip-label': {
                              color: 'blue',
                              fontWeight: 'small',
                            },
                          }}
                          label={`${getPlayerFirstNames(row.teamPlayers)}`}
                        />
                        {/* {row.teamName}-{row.teamPlayers} */}
                      </TableCell>
                      <TableCell align="center">{row.teamMatches}</TableCell>
                      <TableCell align="center">{row.teamPoints}</TableCell>
                      <TableCell align="center">
                        {Math.round(row.percentRatio * 10000) / 10000}{' '}
                        <Divider></Divider>
                        {Number.isNaN(row.gamesWon) ? 0 : row.gamesWon}/
                        {Number.isNaN(row.gamesLost) ? 0 : row.gamesLost}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
      </Box>
    </>
  )
}
