import { useAuth0 } from '@auth0/auth0-react'
import { TryRounded } from '@mui/icons-material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import EditIcon from '@mui/icons-material/Edit'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PublishIcon from '@mui/icons-material/Publish'
import {
  Autocomplete,
  Badge,
  Box,
  LinearProgress,
  Modal,
  Stack,
  Switch,
  TextField,
} from '@mui/material'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makePatchCall } from '../../api/client'
import { AddNewButton } from '../../components/add-new-button'
import QuickMenu from '../../components/quick-menu'
import ScheduleMonthView from '../../components/SchedulesMonthView'
import { fetchPlayers, selectAllPlayers } from '../../players/players.slice'
import { allTeams, fetchTeams } from '../../teams/teams.slice'
import { MATCHES_TYPES } from '../../utils/constants'
import { getDispDateTime, getWindowDimensions } from '../../utils/utils'
import { ResultSubmit } from '../results/results.submit'
import ScheduleAvatarTile from './schedule.avatar.tile'
import OutlinedCard from './schedule.details.model'
import { allSchedules, fetchSchedule } from './schedule.slice'
import { ScheduleSubmit } from './schedule.submit'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'left',
    padding: '10px',
    paddingLeft: '0px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const ScheduleList = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0()
  const [token, setToken] = React.useState(null)
  const dispatch = useDispatch()
  const [edit, setEdit] = React.useState(false)
  const [isSubmitScore, setIsSubmitScore] = React.useState(false)
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [editableSchedule, setEditableSchedule] = React.useState({})
  const [modalSchedule, setModalSchedule] = React.useState({})
  const [submitScore, setSubmitScore] = React.useState({})
  const [selectedMatches, setSelectedMatches] = React.useState([])
  const [matchRadioSelection, setMatchRadioSelection] = React.useState(0)
  const [matchPeriod, setMatchPeriod] = React.useState('future')
  const [filteredTeams, setFilteredTeams] = React.useState('all')
  const [isMonthView, setIsMonthView] = React.useState(false)
  const [submitSchdId, setSubmitSchdId] = React.useState(null)

  const [defaultTeams, setDefaultTeams] = React.useState([])

  const schedulesFetchStatus = useSelector((state) => {
    return state?.schedules?.status
  })
  const schedules = useSelector(allSchedules)

  useEffect(() => {
    if (schedulesFetchStatus === 'idle') {
      dispatch(fetchSchedule())
    }
  }, [schedulesFetchStatus, dispatch])

  const { playersData: allPlayers } = useSelector(selectAllPlayers)
  const playersStatus = useSelector((state) => state.players.status)

  useEffect(() => {
    if (schedulesFetchStatus === 'succeeded') {
      const selection = MATCHES_TYPES[matchRadioSelection]?.value

      const _matchByLeaugeSelection = schedules.map((m, i) => {
        return { ...m, matchNo: i + 1 }
      })

      const filteredMatches = _matchByLeaugeSelection.filter((s) => {
        const diff =
          moment(s.matchTimeAndDate).format('yyyyMMDD') -
          moment().format('yyyyMMDD')

        if (matchPeriod === 'future') {
          return selection === 'All' ? diff >= 0 : diff >= 0
        }
        if (matchPeriod === 'past') {
          return selection === 'All' ? diff < 0 : diff < 0
        }
      })
      if (filteredTeams === 'all') {
        setSelectedMatches(filteredMatches)
      } else {
        const filteredMatchesByTeam = filteredMatches.filter((s) => {
          return (
            filteredTeams.includes(s.hostTeamName) ||
            filteredTeams.includes(s.guestTeamName)
          )
        })
        const _filteredMatchesByTeam = filteredMatchesByTeam.map((m, i) => {
          return { ...m, matchNo: i + 1 }
        })
        setSelectedMatches(_filteredMatchesByTeam)
      }
    }
  }, [schedulesFetchStatus, matchRadioSelection, matchPeriod])

  useEffect(() => {
    if (schedulesFetchStatus === 'succeeded') {
      if (filteredTeams.length < 1) {
        const _matchByLeaugeSelection = schedules.map((m, i) => {
          return { ...m, matchNo: i + 1 }
        })
        setSelectedMatches(_matchByLeaugeSelection)
      } else {
        const filteredMatches = schedules.filter((s) => {
          return (
            filteredTeams.includes(s.hostTeamName) ||
            filteredTeams.includes(s.guestTeamName)
          )
        })
        const _filteredMatches = filteredMatches.map((m, i) => {
          return { ...m, matchNo: i + 1 }
        })
        setSelectedMatches(_filteredMatches)
      }
    }
  }, [filteredTeams])

  useEffect(() => {
    if (playersStatus === 'idle') {
      dispatch(fetchPlayers())
    }
    if (playersStatus === 'succeeded' && isAuthenticated) {
      const findP = allPlayers.find((p) => user?.email === p.playerId)
      setIsAdmin(findP?.admin ? true : false)
    }
  }, [playersStatus, dispatch])

  const teamsFetchStatus = useSelector((state) => state?.teams?.status)
  const teams = useSelector(allTeams)

  useEffect(() => {
    if (teamsFetchStatus === 'idle') {
      dispatch(fetchTeams())
    }
  }, [teamsFetchStatus, dispatch])

  useEffect(() => {
    if (teamsFetchStatus === 'succeeded') {
    }
  }, [teamsFetchStatus])

  useEffect(() => {
    async function _setToken() {
      setToken(await getAccessTokenSilently())
    }
    _setToken()
  }, [isAuthenticated])

  const handleEditSchedule = (row) => {
    setEditableSchedule(row)
    setEdit(true)
  }
  const handleSubmitScore = (row) => {
    const results = {
      tournamentName: row.tournamentName,
      matchTimeAndDate: row.matchTimeAndDate,
      guestTeamPlayers: row.guestTeamPlayers,
      hostTeamPlayers: row.hostTeamPlayers,
      hostTeam: teams.find((ele) => row.hostTeamId === ele.teamId),
      guestTeam: teams.find((ele) => row.guestTeamId === ele.teamId),
    }
    setSubmitScore(results)
    setSubmitSchdId(row.scheduleId)
    setIsSubmitScore(true)
  }

  const handleUpdateSchedule = () => {
    const scheduleDetails = {
      scheduleId: submitSchdId,
      isScoreSubmitted: true,
      updatedBy: user?.email,
      updatedAt: new Date().toISOString(),
    }
    makePatchCall(scheduleDetails, 'schedule', token, null)
  }
  const handleModalClick = (row) => {
    setModalSchedule(row)
    setOpen(true)
  }
  const isOwner = (schedule) => {
    if (isAuthenticated) {
      if (isAdmin) {
        return true
      } else if (
        schedule?.createdBy === user?.email ||
        schedule?.updatedBy === user?.email
      ) {
        return true
      }
    }
    return false
  }

  const isTeamPlayer = (schedule) =>
    [...schedule.hostTeamPlayersId, ...schedule.guestTeamPlayersId].includes(
      user?.email,
    )

  const isSubmitAllowed = (schedule) => {
    if (isOwner(schedule)) {
      return true
    } else if (isTeamPlayer(schedule)) {
      return true
    } else {
      return false
    }
  }

  const getPastOrFutureMatch = () => {
    return (
      <>
        <FormControl>
          <RadioGroup
            sx={{
              color: 'purple',
              borderRadius: '3%',
            }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={matchPeriod}
            onChange={(e) => setMatchPeriod(e.target.value)}
          >
            {/* <Switch
              color="warning"
              disabled={!schedules}
              onChange={() => {
                setIsMonthView(!isMonthView)
              }}
            /> */}
            Coming Matches
          </RadioGroup>
        </FormControl>
      </>
    )
  }

  const handleFilteredTeams = (e, value) => {
    const names = value.map((team) => team.teamName)
    setFilteredTeams(names)
    setDefaultTeams(value)
  }
  const filterByTeams = () => {
    const tournamentTeams = teams.filter((t) => {
      return t.activeTournament?.includes(MATCHES_TYPES[0].value);
    })
    return (
      <Autocomplete
        multiple
        id="tags-standard"
        options={tournamentTeams}
        value={defaultTeams}
        getOptionLabel={(team) => team?.teamName}
        onChange={handleFilteredTeams}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            color="success"
            label="Filter matches by teams"
            placeholder="Pick teams"
          />
        )}
      />
    )
  }
  const getRadioSelection = () => {
    return (
      <>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="schedule-matches-selections"
            name="match-type-selection"
            value={matchRadioSelection}
            onChange={(e) => setMatchRadioSelection(e.target.value)}
            sx={{
              '& .MuiTypography-root': {
                fontWeight: 600,
                color: '#cf2bd3',
              },
            }}
          >
            {MATCHES_TYPES.map((x, i) => (
              <FormControlLabel
                key={`match-option-${x.value}`}
                value={i}
                control={<Radio />}
                label={x.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    )
  }

  const handleTeamOnClick = (teamName) => {
    const findTeamByName = teams.find((t) => t?.teamName === teamName)
    if (findTeamByName) {
      setFilteredTeams([findTeamByName.teamName])
      setDefaultTeams([findTeamByName])
    }
  }
  const schedulesView = () => {
    const { width } = getWindowDimensions()
    return (
      <Paper sx={{ width }}>
        <QuickMenu></QuickMenu>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            mt: 1,
            mb: 1,
            bgcolor: 'rgba(25, 118, 210, 0.12)',

            borderInlineColor: 'red',
            borderRadius: 3,
            borderBottomColor: 'red',
            borderWidth: '10px',
          }}
        >
          {/* {getPastOrFutureMatch()} */}

          {isAdmin && (
            <AddNewButton title="Add new schedule" href="/schedule/submit" />
          )}
          <Stack spacing={0.3} alignItems="center" direction="row">
            <Typography> Coming Matches</Typography>
            <FormControlLabel
              onChange={() =>
                setMatchPeriod(matchPeriod !== 'past' ? 'past' : 'future')
              }
              control={<Switch defaultChecked={false} />}
              label="Past"
            />
            <Chip
              label="Month View"
              sx={{ ml: '5px' }}
              color="warning"
              variant="outlined"
              onClick={() => {
                setIsMonthView(!isMonthView)
              }}
            />
          </Stack>
        </Typography>
        <Paper align="center" sx={{ marginLeft: '2px' }}>
          {/* {getRadioSelection()} */}
          {teams.length > 0 && filterByTeams(teams)}
          {/* {filterByTeams(MATCHES_TYPES)} */}
        </Paper>
        {schedulesFetchStatus === 'idle' && (
          <Paper>
            <Stack spacing={1}>
              <LinearProgress color="secondary" />
              .........loading data!
              <LinearProgress color="success" />
            </Stack>
          </Paper>
        )}
        {schedulesFetchStatus === 'failed' && (
          <Paper>
            <Stack spacing={2}>
              some thing went wrong. check later.
              <LinearProgress color="warning" />
            </Stack>
          </Paper>
        )}
        {schedulesFetchStatus === 'loading' && (
          <Typography variant="h5">
            <Stack spacing={1} sx={{ textAlign: 'center' }}>
              ...loading matches
            </Stack>
          </Typography>
        )}
        {schedulesFetchStatus === 'succeeded' && schedules?.length < 1 && (
          <Paper variant="outlined">
            <Stack spacing={1} sx={{ textAlign: 'center' }}>
              No match is scheduled yet.
            </Stack>
          </Paper>
        )}
        {schedulesFetchStatus === 'succeeded' && schedules?.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell align="left">Host Team</StyledTableCell>
                <StyledTableCell align="left">Guest Team</StyledTableCell> */}
                  <StyledTableCell align="left">Teams</StyledTableCell>
                  <StyledTableCell sx={{ minWidth: 130 }} justify="flex-end">
                    Time
                  </StyledTableCell>
                  <StyledTableCell align="left">Court</StyledTableCell>
                  <StyledTableCell align="left">Tournament</StyledTableCell>
                  {width > 600 && (
                    <StyledTableCell align="left">Comments</StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedMatches.map((row, i) => {
                  // if (row.tournamentName !== TOURNAMENTS[0]) {
                  //   return;
                  // }
                  const diff =
                    moment(row.matchTimeAndDate).format('yyyyMMDD') -
                    moment().format('yyyyMMDD')
                  if (matchPeriod === 'future' ? diff >= 0 : diff < 0) {
                    return (
                      <StyledTableRow key={i}>
                        <StyledTableCell align="right">
                          <ScheduleAvatarTile
                            data={row}
                            hostTeamPlayersId={row.hostTeamPlayersId}
                            guestTeamPlayersId={row.guestTeamPlayersId}
                            hostTeamName={row.hostTeamName}
                            guestTeamName={row.guestTeamName}
                            players={allPlayers}
                            matchDate={getDispDateTime(row.matchTimeAndDate)}
                          ></ScheduleAvatarTile>
                          {/* <Chip
                            onClick={() => handleTeamOnClick(row?.hostTeamName)}
                            sx={{
                              '& .MuiChip-label': {
                                color: 'blue',
                                fontWeight: 'small',
                              },
                            }}
                            label={`${row.hostTeamName} (${getPlayerFirstNames(
                              row.hostTeamPlayers,
                            )})`}
                          />
                          <Chip
                            onClick={() =>
                              handleTeamOnClick(row?.guestTeamName)
                            }
                            sx={{
                              '& .MuiChip-label': {
                                color: 'blue',
                                fontWeight: 'small',
                              },
                            }}
                            label={`${row.guestTeamName} (${getPlayerFirstNames(
                              row.guestTeamPlayers,
                            )})`}
                          /> */}
                        </StyledTableCell>
                        {/* <StyledTableCell align="right">
                    {row.guestTeamName}
                  </StyledTableCell> */}
                        <StyledTableCell component="th" scope="row">
                          <Typography variant="overline" sx={{ color: 'red' }}>
                            {row.matchNo}
                          </Typography>
                          {getDispDateTime(row.matchTimeAndDate)}
                          <>
                            {isAdmin && !row.isScoreSubmitted && (
                              <Chip
                                sx={{
                                  '& .MuiChip-label': {
                                    color: 'blue',
                                    fontWeight: 'small',
                                  },
                                }}
                                variant="outlined"
                                color="warning"
                                size="small"
                                icon={<EditIcon />}
                                label="Edit Schedule"
                                disabled={row.isScoreSubmitted}
                                onClick={() => handleEditSchedule(row)}
                              />
                            )}
                            {isAdmin && (
                              <Chip
                                sx={{
                                  '& .MuiChip-label': {
                                    color: 'blue',
                                    fontWeight: 'small',
                                  },
                                }}
                                variant="outlined"
                                color="error"
                                size="small"
                                icon={<PublishIcon />}
                                label={
                                  row.isScoreSubmitted
                                    ? 'Score submitted'
                                    : 'Submit Score'
                                }
                                disabled={row.isScoreSubmitted}
                                onClick={() => handleSubmitScore(row)}
                              />
                            )}
                          </>
                        </StyledTableCell>

                        <StyledTableCell align="right" label={row.gameCourt}>
                          {width > '500'
                            ? row.gameCourt
                            : row.gameCourt.replace('Old Settlers Park', 'OSP')}

                          <Chip
                            label="details"
                            sx={{ paddingLeft: '0px', paddingRight: '0px' }}
                            size="small"
                            onClick={() => handleModalClick(row)}
                            icon={<MoreHorizIcon />}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.tournamentName}
                        </StyledTableCell>

                        {width > 600 && (
                          <StyledTableCell align="right">
                            {row.additionalInfo}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    )
  }
  const editView = () => {
    return <ScheduleSubmit schedule={editableSchedule}></ScheduleSubmit>
  }
  const submitScoreView = () => {
    return (
      <ResultSubmit
        result={submitScore}
        callBackFunc={handleUpdateSchedule}
      ></ResultSubmit>
    )
  }
  const activeView = () => {
    if (isMonthView) {
      return (
        <ScheduleMonthView
          schedules={schedules}
          setIsMonthView={setIsMonthView}
        ></ScheduleMonthView>
      )
    } else if (isSubmitScore) {
      return submitScoreView()
    } else if (edit) {
      return editView()
    } else {
      return schedulesView()
    }
  }
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Badge
            badgeContent={<b>{<CloseOutlinedIcon color="warning" />}</b>}
            color="default"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClick={() => setOpen(false)}
          >
            <OutlinedCard
              schedule={modalSchedule}
              setOpen={setOpen}
            ></OutlinedCard>
          </Badge>
        </Box>
      </Modal>
      {activeView()}
    </>
  )
}
