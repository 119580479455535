import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { styled } from '@mui/material/styles'

import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))
export default function PlayerHomeProfile(props) {
  const { playerInfo } = props
  let fieldName = playerInfo.profileImgUrl
    ? playerInfo.profileImgUrl
    : 'default'
  const s3Url = `https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/players/${fieldName}.jpeg`
  return (
    <Card>
      <CardActionArea>
        {/* <CardMedia
          component="img"
          height="100%"
          image={require(`../../public/images/players/${url}.jpeg`)} // require image
          alt={playerInfo.teamName}
        /> */}

        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          variant="dot"
        >
          <CardHeader
            title={playerInfo.fullName}
            subheader={`${playerInfo?.matchesWon || 0}/${
              playerInfo?.matchesLost || 0
            }(W/L)`}
          />
        </StyledBadge>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="standard"
            >
              <Avatar alt={playerInfo.fullName} src={s3Url} />
            </StyledBadge>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {playerInfo.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
