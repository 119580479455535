import { useAuth0 } from '@auth0/auth0-react'
import {
  FormControlLabel,
  FormGroup,
  Paper,
  Snackbar,
  Switch,
} from '@material-ui/core'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import { Alert } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import { createTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { makePostCall } from '../api/client'

// const defaultTheme = createTheme() // Remove this line

export default function LeagueSubmit() {
  const {
    isAuthenticated,
    user,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0()
  const [isActive, setIsActive] = React.useState(false)
  const [token, setToken] = React.useState(null)
  const [toastMessageInfo, setToastMessageInfo] = React.useState({
    message: null,
    severity: null,
  })
  const [toastOpen, setToastOpen] = React.useState(false)

  const [submitButton, setSubmitButton] = React.useState({
    message: null,
    severity: null,
  })

  React.useEffect(() => {
    if (submitButton.message) {
      setToastMessageInfo({
        message: submitButton.message,
        severity: submitButton.severity,
      })
      setToastOpen(true)
    }
  }, [submitButton])

  React.useEffect(() => {
    async function _setToken() {
      setToken(await getAccessTokenSilently())
    }
    _setToken()
  }, [isAuthenticated])

  const handleChange = (event) => {
    setIsActive(event.target.checked)
  }
  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    // setToastMessageInfo({ message: null, severity: null })
    setToastOpen(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    console.log({
      leagueName: data.get('leagueName'),
      leagueDescription: data.get('leagueDescription'),
      leagueSeason: data.get('leagueSeason'),
      isActive,
    })
    makePostCall({ ...data, isActive }, 'leagues', token, setSubmitButton, true)
  }

  return (
    // Remove <ThemeProvider> wrapper
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <DashboardCustomizeIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          League Sign up
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2} mb="16px">
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="League Name"
                name="leagueName"
                required
                fullWidth
                id="leagueName"
                label="Leauge Name"
                autoFocus
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Switch onChange={handleChange} defaultChecked />}
                name="isActive"
                id="isActive"
                label="Active"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="leagueDescription"
                label="League Description"
                name="leagueDescription"
                autoComplete="League Description"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="leagueSeason"
                label="League Season"
                name="leagueSeason"
                autoComplete="League Season"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            disabled={!isAuthenticated}
            variant="contained"
            sx={{ mt: 16, mb: 2 }}
          >
            {isAuthenticated ? 'Sign Up' : 'Log in first, please'}
          </Button>
          <Snackbar
            open={toastOpen}
            autoHideDuration={5000}
            onClose={handleToastClose}
          >
            <Alert
              onClose={handleToastClose}
              severity={toastMessageInfo.severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {toastMessageInfo.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Container>
  )
}
