import { Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import SignUpTeamm from '../../teams/team.signup'

export default function TeamByPlayers({
  fieldName,
  playersData,
  teamData,
  handleTeamsSelection,
}) {
  const [selectedPlayer1, setSelectedPlayer1] = useState(null)
  const [selectedPlayer2, setSelectedPlayer2] = useState(null)
  const [teamName, setTeamName] = useState('')

  const handlePlayer1Change = (event, value) => {
    setSelectedPlayer1(value)
    findTeamByPlayers(value, selectedPlayer2)
  }

  const handlePlayer2Change = (event, value) => {
    setSelectedPlayer2(value)
    findTeamByPlayers(selectedPlayer1, value)
  }

  const findTeamByPlayers = (player1, player2) => {
    if (player1 && player2) {
      const team = teamData.find(
        (team) =>
          team.teamPlayersIds.includes(player1.playerId) &&
          team.teamPlayersIds.includes(player2.playerId),
      )
      setTeamName(team ? team.teamName : `${fieldName}-na`)
      handleTeamsSelection(fieldName, team?.teamId)
    } else {
      setTeamName('')
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        {playersData && (
          <Grid item xs={12}>
            <Autocomplete
              id="player1"
              options={playersData}
              getOptionLabel={(player) => player?.playerName || ''}
              onChange={handlePlayer1Change}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${fieldName} Player 1`}
                  variant="outlined"
                />
              )}
              key="autocomplete-player1" // Assign a unique key prop
            />
          </Grid>
        )}
        {playersData && (
          <Grid item xs={12}>
            <Autocomplete
              id="player2"
              options={playersData}
              getOptionLabel={(player) => player?.playerName || ''}
              onChange={handlePlayer2Change}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${fieldName} Player 2`}
                  variant="outlined"
                />
              )}
              key="autocomplete-player2"
            />
          </Grid>
        )}
      </Grid>
      {teamName === `${fieldName}-na` && (
        <SignUpTeamm
          firstPlayer={selectedPlayer1}
          secondPlayer={selectedPlayer2}
        ></SignUpTeamm>
      )}
      {/* <ScheduleSubmit></ScheduleSubmit> */}
    </>
  )
}
