const photoDataList = [
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-final-tennis.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-champions-tennis.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/fall-2022-champions.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/fall-2023-final-trophies-tennis.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/fall-2023-runner-up.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/final-2023-champions.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },

  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-champions.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-fall-2022-chamipions-runners-up.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-final-best-referee.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },

  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-refereers-and-committee.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/fall-2022/TENNIS+POSTER+-+Made+with+PosterMyWall+(3).jpg',
    title: '#Fall2022-championship-final',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/fall-2022/fall-2022-teams.jpg',
    title: '#Fall2022-championship',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/champions-1-summer-2022.jpg',
    title: '#Champions-summer-2022',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/champions-summer-2022.jpg',
    title: 'champions@s-2022',
    author: '@arwinneil',
    iconColor: 'warning',
    featured: false,
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/winners-2-summer-2022.jpg',
    title: 'winners@s-2022',
    author: '@southside_customs',
    iconColor: 'secondary',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/runner-up-summer-2022.jpg',
    title: 'runner-up@summer-2022',
    author: '@southside_customs',
    iconColor: 'warning',
    featured: true,
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/adeel-summer-2022.jpg',
    title: 'runner-up@s-2022',
    iconColor: 'info',
    author: '@helloimnik',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/ali-summer-2022.jpg',
    title: 'runner-up@s-2022',
    iconColor: 'primary',
    author: '@nolanissac',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/abdul-samad-summer-2022.jpg',
    title: 'Abdul Samad -chief-guest',
    author: '@rollelflex_graphy726',
    iconColor: 'secondary',
    featured: true,
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/badar-summer-2022.jpg',
    title: 'winner@S-2022',
    author: '@hjrc33',
    iconColor: 'error',
  },

  //   {
  //     imgUrl: "https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/chief-guest-summer-2022.jpg",
  //     title: "Abdul Samad - chief guest",
  //     author: "@tjdragotta",
  //     iconColor: "success",
  //   },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/finalist-summer-2022.jpg',
    title: 'finalist@s-2022',
    author: '@katie_wasserman',
    iconColor: 'warning',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/Tennis-semifinals-v4.jpg',
    title: 'semifinalist@s-2022',
    author: '@silverdalex',
    iconColor: 'secondary',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/rrt-players-2-summer-2022.jpg',
    title: 'final-night',
    author: '@silverdalex',
    iconColor: 'secondary',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/rrt-players-summer-2022.jpg',
    title: 'round rock tennis players',
    author: '@shelleypauls',
    iconColor: 'secondary',
    featured: true,
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/rrtn-players-2-summer-2022.jpg',
    title: 'final-evening@s-2022',
    author: '@peterlaster',
    iconColor: 'secondary',
  },

  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2022/summer-2022-final/winners-summer-2022.jpg',
    title: 'winner-summer-2022',
    author: '@southside_customs',
    iconColor: 'info',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/final-summer-2022-v2.jpg',
    title: 'final-summer-2022',
    author: '@southside_customs',
    iconColor: 'success',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/summer-2022-trophy.jpeg',
    title: 'trophy-s-2022',
    author: '@southside_customs',
    iconColor: 'success',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/summer-2022-semifinal-evening.jpeg',
    title: 'semifinal-evening',
    author: '@southside_customs',
    iconColor: 'info',
    featured: true,
  },
]
const photoDataList2 = [
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-champions-tennis.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Champions Asif-Badar Spring 2023',
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-final-dinner.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Spring 2023 - dinner',
    iconColor: 'error',
  },

  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/final-2023-champions.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Champions Spring 2023',
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-fall-2022-chamipions-runners-up.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Champions-runners-up Fall2022 Spring 2023',
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/spring-2023-final-tennis.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Spring 2023 Trohphies',
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-prod-asset-bucket.s3.amazonaws.com/images/spring-2023/fall-2023-runner-up.jpeg',
    author: 'zulfi',
    featured: true,
    title: 'Fall 2022 - Runnersup',
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/ut-zu-trouphy-01.jpeg',
    title: 'Winning Trophy',
    featured: true,
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/group-dinner-02.jpeg',
    title: '#Final night',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/asif-trophy-03.jpeg',
    title: 'Asif - Runner up',
  },

  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/rr-players.jpeg',
    title: 'The Pioneers',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/common-gallery/2022-02-18-halalbro-04.jpeg',
    title: 'Halal Bro',
  },
]
const summer2023Photos = [
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-champions.jpeg',
    title: 'summer-2023-champions.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'warning',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-final-1..jpeg',
    title: 'summer-2023-final-1..jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'secondary',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-final-night-ali-taseer.jpeg',
    title: 'summer-2023-final-night-ali-taseer.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'success',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-final-night-comittee.jpeg',
    title: 'summer-2023-final-night-comittee.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-final-night-umair.jpeg',
    title: 'summer-2023-final-night-umair.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-final-night1.jpeg',
    title: 'summer-2023-final-night1.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-final-runner-up.jpeg',
    title: 'summer-2023-final-runner-up.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'secondary',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-runner-up.jpeg',
    title: 'summer-2023-runner-up.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'secondary',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-sam-sheikh-usman-qureshi.jpeg',
    title: 'summer-2023-sam-sheikh-usman-qureshi.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'warning',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-sam-sheikh.jpeg',
    title: 'summer-2023-sam-sheikh.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'primary',
  },
  {
    imgUrl:
      'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/summer-2023-trophies.jpeg',
    title: 'summer-2023-trophies.jpeg',
    author: 'zulfi',
    featured: true,
    iconColor: 'success',
  },
]
const summer2023DinnerPhotos = [
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-1.jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'secondary',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-10.jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'primary',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-12.jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'secondary',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-3.jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-4.pg.jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'warning',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-5.jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'warning',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-6..jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-7.jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'error',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-8.jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'warning',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/summer-2023/home-dinner-summer-2023-dinner-9.jpg',
    title: 'Final Night Dinner | Summer-2023',
    author: 'zulfi',
    featured: true,
    iconColor: 'warning',
  },
];

const spring2024Photos = [
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/spring-2024-group-photo.jpeg',
    title: '🎊🎉 Final Night | Season 8 - Spring 2024',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: true,
    iconColor: 'warning',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/spring-2024-champions.jpeg',
    title: '🏆 Champions | Season 8 - Spring 2024',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'warning',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/runner-up-spring-2024-2.jpeg',
    title: '🥈🥈 Runner-up | Season 8 - Spring 2024',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'success',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/champions-man-of-the-match.jpeg',
    title: '🥇 Final Match: Man Of The Match',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'secondary',
  },
    {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/play-of-the-tournament-spring-2024.jpeg',
    title: '🏅 Sameer Sharma: Player Of The Tournament',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'primary',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/badar-champions.spring-2024.jpeg',
    title: 'Badar Usmani : Final Match Winner',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'primary',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/champions-spring-2024.jpeg',
    title: '🏆🏆 Badar, Asif: Final Match Winners',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'error',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/final-group-srping-2024.jpeg',
    title: 'RRTL Stars |  Season 8 - Spring 2024',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'primary',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/finan-man-of-the-match.jpeg',
    title: 'Asif Hasan - Final Man of the Match',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'error',
  },

  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/runner-up-spring-2024-1.jpeg',
    title: 'Sameer Sharma - Player Of The Tournament | Season 8',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'secondary',
  },
  {
    imgUrl: 'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/spring-2024-season-8/spring-2024-final-poster.jpeg',
    title: 'Final Match Posters | Spring 2024',
    subTitle: 'Season 8 | Spring 2024',
    author: 'zulfi',
    featured: false,
    iconColor: 'primary',
  },
];


export const IMAGES_URL_1 = photoDataList
export const IMAGES_URL_2 = photoDataList2
export const SUMMER_2023_URLS = summer2023Photos
export const SUMMER_2023_DINNER_URLS = summer2023DinnerPhotos
export const SPRING_2024_URLS = spring2024Photos