import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiGrid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import { Avatar, AvatarGroup } from '@mui/material'

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}))
const baseUrl =
  'https://round-rock-tennis-nonprod-asset-bucket.s3.amazonaws.com/images/avatars'
export default function VerticalDividerText({ champs }) {
  const playerOneUrl = `${baseUrl}/${champs.winnerPlayerOneId.replace(
    '@',
    '%40',
  )}.jpeg`

  const playerTwoUrl = `${baseUrl}/${champs.winnerPlayerTwoId.replace(
    '@',
    '%40',
  )}.jpeg`
  //   console.log(' champs ', champs)

  return (
    <>
      <Divider
        textAlign="center"
        sx={{
          fontSize: '12px',
        }}
      >
        <strong style={{ color: 'black' }}>{champs?.championshipName}</strong>
      </Divider>
      <Grid container>
        <AvatarGroup max={2}>
          <Avatar sx={{ width: 24, height: 24 }} src={playerOneUrl} />
          <Avatar sx={{ width: 24, height: 24 }} src={playerTwoUrl} />
        </AvatarGroup>
        <Grid item xs>
          <Divider
            textAlign="right"
            sx={{
              fontSize: '10px',
            }}
          >
            {champs?.winnerPlayerOne}
          </Divider>
          <Divider
            textAlign="right"
            sx={{
              fontSize: '10px',
            }}
          >
            {champs?.winnerPlayerTwo}
          </Divider>
        </Grid>
      </Grid>
    </>
  )
}
